import { useMemo } from "react";

import { Typography } from "../../library";
import { truncateAroundTargetWord } from "../../utils/format";

import type { ContractHit } from "../../generated";
import { isFeatureEnabled } from "../../utils/split";
import { matchesForContractHit, styleSearchResponseText } from "./utils";
// TODO(Kwasi): Deprecate this file along with SearchCard if proSerpPromotion A/B test passes.
const flexibleMatchCopyByKey = {
  // Match copy for the flexible card system.
  contractBrands: "Brand on contract: ",
  contractNumber: "Contract number: ",
  contractOfferings: "Confirmed in contract scope: ",
  autoExtractedOfferingsList: "Confirmed in contract document: ",
  semanticOffering: "Identified by AI in contract: ",
  psBrand: "Brand in contract scope: ",
  psItemName: "Product on contract: ",
  psSummary: "Product on contract: ",
  psIdsFromSource: "Product on contract: ",
  isOCR: "Found in contract documents: ",
};

export type FlexibleMatchKey = keyof typeof flexibleMatchCopyByKey;
const maxWords = 8;
const maxCharacters = 200;

export function FlexibleCardMatch({
  hit,
  showingProducts,
}: {
  hit: ContractHit;
  showingProducts?: boolean;
}) {
  const hasRedesignedSerpCards = isFeatureEnabled("redesignedSERPCards");
  const allMatches = useMemo(() => {
    // Filter out match kinds not present in our flexibleMatchCopyByKey
    let matches = matchesForContractHit(hit).filter(
      (match) =>
        Object.keys(flexibleMatchCopyByKey).includes(
          match.matchKind as FlexibleMatchKey
        ) || match.isOCR
    );

    if (showingProducts) {
      matches = matches.filter(
        (match) =>
          match.matchKind !== "psItemName" &&
          match.matchKind !== "psSummary" &&
          match.matchKind !== "psIdsFromSource" &&
          match.matchKind !== "contractOfferings" &&
          match.matchKind !== "autoExtractedOfferingsList" &&
          match.matchKind !== "semanticOffering"
      );
    }

    if (matches.length > 1)
      return matches.filter(({ matchLevel }) => matchLevel !== "semantic");
    return matches;
  }, [hit, showingProducts]);

  // Early break for when we have no match information.
  if (!allMatches.length) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-1 max-w-xl">
      {allMatches.slice(0, 2).map((match, ix) => {
        let styledValueText = <span key={match.value}>{match.value}</span>;
        if (match.matchLevel !== "semantic") {
          const value = truncateAroundTargetWord(
            match.value,
            "<em>",
            maxWords,
            maxCharacters
          );
          styledValueText = styleSearchResponseText(value, {
            highlightClassName: hasRedesignedSerpCards ? "font-semibold" : "",
          });
        }

        const copyText = match.isOCR
          ? flexibleMatchCopyByKey.isOCR
          : // @ts-ignore
            flexibleMatchCopyByKey[match.matchKind];

        return (
          <Typography
            size="sm"
            color="subtler"
            // biome-ignore lint/suspicious/noArrayIndexKey: These are static, so an index is ok.
            key={ix}
            className="sm:truncate"
          >
            {copyText} {styledValueText}
          </Typography>
        );
      })}
    </div>
  );
}
