import type {
  ContractData,
  ContractFileTypeEnum,
  ContractHit,
  MembershipTypeEnum,
  UserSupplierContact,
} from "../generated";

export interface SelectComponentOption {
  value: string;
  label: string;
}

export interface SupplierProfilePasswordDetails {
  needPasswordChange: boolean;
}
export interface BuyerProfileDetails {
  verified: boolean;
}

export interface User {
  firstName?: string;
  lastName?: string;
  email: string;
}

export interface GovernmentAgency {
  id: string;
  name: string;
  entityType: string;
  url?: string;
  numContracts: number;
  isCoop?: boolean;
  isAgency?: boolean;
  stateCode?: string;
  agencyType?: string;
  agencyTypeLabel?: string;
  zipCode?: string;
  approvedSources?: string[];
  approvedStates?: string[];
  appendStateForDisplay?: boolean;
  showOnlyApprovedSources?: boolean;
  membershipType?: Maybe<MembershipTypeEnum>;
}

export enum ContentTypes {
  SEARCH_RESULTS = "search_results",
  CONTRACT = "contract",
  SUPPLIER = "supplier",
  SUPPLIER_ENTITY_RECOMMENDATIONS = "supplier_entity_recommendations",
}

export interface Bookmark {
  id: string;
  bookmark_type: string;
  page_number: number;
}
export interface BasicContractFile {
  name: string;
  type: ContractFileTypeEnum;
}

export interface ContractFile extends BasicContractFile {
  bookmarks: Bookmark[];
  id: string;
  url: string;
  has_access?: boolean;
  buyer_verification_gated?: boolean;
}

export type DedupedSupplierContact = UserSupplierContact & {
  duplicatePhoneIds: number[];
};

export type ContractSearchLimitedData = Pick<
  ContractData,
  "numShowingResults" | "numAllResults" | "numStrongResults"
> & { results: ContractHit[] };
