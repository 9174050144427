import clsx from "clsx";
import type { ChangeEventHandler } from "react";

import LabeledInput, {
  LabeledInputLabelStyles,
  LabeledInputVariants,
  LabeledInputWithIcon,
  type InputSizes,
  type TextInputTypes,
} from "../Input/LabeledInput";

import type { CustomFormProps } from "./types";

export interface LabeledInputFieldProps<T = string> {
  field: {
    name: string;
    value: T;
    onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onBlur: (e: unknown) => void;
  };
  editable: boolean;
  label: string;
  sublabel?: string;
  labelStyle?: LabeledInputLabelStyles;
  form: CustomFormProps;
  placeholder?: string;
  size: InputSizes;
  type?: TextInputTypes;
  className?: string;
  dataTestId?: string;
  validationMessage?: string;
  validationIcons?: boolean;
  component?: "input" | "textarea";
  onChange?: () => void;
  message?: string;
}

export default function LabeledInputField({
  field,
  form: { touched, errors },
  placeholder,
  label,
  sublabel = "",
  size = "md",
  editable,
  type = "text",
  className,
  dataTestId,
  validationMessage,
  onChange,
  component,
  validationIcons,
  ...rest
}: LabeledInputFieldProps) {
  if (!editable) {
    return <div className="flex-grow py-2.5">{field.value || "(none)"}</div>;
  }

  let initialVariant = LabeledInputVariants.DEFAULT;
  let message = "";

  if (touched[field.name] && errors[field.name]) {
    initialVariant = LabeledInputVariants.ERROR;
    message = errors[field.name] as string;
  } else if (validationMessage) {
    initialVariant = LabeledInputVariants.SUCCESS;
    message = validationMessage;
  }

  const Component = validationIcons ? LabeledInputWithIcon : LabeledInput;

  return (
    <Component
      type={type}
      component={component}
      className={clsx(className, "flex-grow")}
      size={size}
      name={field.name}
      placeholder={placeholder}
      onChange={(e) => {
        if (onChange) {
          onChange();
        }
        field.onChange(e);
      }}
      onBlur={(e) => {
        field.onBlur(e);
      }}
      value={field.value || ""}
      initialVariant={initialVariant}
      message={message}
      label={label}
      sublabel={sublabel}
      dataTestId={dataTestId}
      {...rest}
    />
  );
}

/**
 * For use with InlineFormWrapper, since FormFieldProps does not include labelStyle
 */
export function LabeledInputFloatingLabelField({
  message,
  ...props
}: Omit<LabeledInputFieldProps, "component">) {
  return (
    <LabeledInputField
      labelStyle={LabeledInputLabelStyles.FLOATING}
      {...props}
    />
  );
}
