import _get from "lodash/get";
import { createRoot } from "react-dom/client";

import SupplierProfile from "../components/supplier/SupplierProfile/SupplierProfile";
import type {
  ProQualifications,
  SupplierCompliance,
  SupplierServiceAreaData,
  UserSupplierContact,
} from "../generated";
import type { ContractWithTags } from "../shared/ContractBase/types";
import { getDOMAnchorById } from "../utils";
import { PageType } from "../utils/enums";
import Page, { type AuthPageProps } from "./Page/Page";

export interface SupplierContactExtended extends UserSupplierContact {
  addressLine1?: string;
  addressLine2?: string;
  addressCity: string;
  addressStateCode: string;
  addressZip: string;
  catalogUrl?: string;
  website?: string;
}

export interface SupplierProfileData {
  id: number;
  name: string;
  claimed: boolean;
  about: string;
  offerings_list: string[];
  contracts: ContractWithTags[];
  contracts_count: number;
  num_active_contracts: number;
  supplier_contact: SupplierContactExtended;
  supplier_phone_contacts: SupplierContactExtended[];
  supplier_offerings: string[];
  has_confirmed_contact: boolean;
  handle: string;
  supplier_compliance: SupplierCompliance;
  closed: boolean;
  enrollment_status: number;
  active_agreements: string[];
  service_area_data: SupplierServiceAreaData;
  saved_project_id?: string;
  logo_url: string;
  sat_url: string;
  has_products: boolean;
  pro_qualifications: Maybe<ProQualifications>;
}
interface SupplierProps extends AuthPageProps {
  supplier: SupplierProfileData;
  hasClaimedSupplier: boolean;
  ownsSupplier: boolean;
}

export default function Supplier({
  supplier,
  hasClaimedSupplier,
  ownsSupplier,
  is_authenticated,
  profile_type,
  active_agreements,
}: SupplierProps) {
  return (
    <Page
      pageType={PageType.SUPPLIER}
      searchSource="supplier-header-search-bar"
      isAuthenticated={is_authenticated}
      profileType={profile_type}
      activeAgreements={active_agreements}
    >
      <SupplierProfile
        supplier={supplier}
        hasClaimedSupplier={hasClaimedSupplier}
        ownsSupplier={ownsSupplier}
      />
    </Page>
  );
}

const container = getDOMAnchorById("supplier") as HTMLElement;
if (_get(container, "dataset.supplier")) {
  const supplier = JSON.parse(container.dataset.supplier || "");
  const hasClaimedSupplier = container.dataset.hasClaimedSupplier === "True";
  const ownsSupplier = container.dataset.ownsSupplier === "True";
  const root = createRoot(container);
  root.render(
    <Supplier
      supplier={supplier}
      hasClaimedSupplier={hasClaimedSupplier}
      ownsSupplier={ownsSupplier}
    />
  );
}
