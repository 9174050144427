/*
Implementation for supplier cards on the contract search results page.
*/
import clsx from "clsx";
import type { CSSProperties, ReactNode } from "react";

import GenericCard from "../../library/GenericCard";
import type { LoginWallTriggers } from "../../utils/enums";
import SimilarOfferings from "../SimilarOfferings";

import type { SupplierCardType } from "../../generated";
import { hasRedesignedSERPCardsM2 } from "../../utils/tags";
import SimilarPublicEntities from "./SimilarPublicEntities";
import SupplierCardHeader from "./SupplierCardHeader";
import SupplierTagSubsection from "./SupplierTagSubsection";
import { getFormattedTagsForSupplier } from "./utils";

export interface SupplierCardProps {
  className?: string;
  supplier: SupplierCardType;
  onClick: () => void;
  trackSerpClick?: () => void;
  cta?: ReactNode;
  size?: "sm" | "md";
  style?: CSSProperties;
  selected?: boolean;
  loginWallTrigger?: LoginWallTriggers;
  loginWallTriggerId?: string;
  dataTestId?: string;
}

export default function SupplierCard({
  className,
  supplier,
  onClick,
  trackSerpClick,
  size = "md",
  selected = false,
  cta,
  style,
  loginWallTrigger,
  loginWallTriggerId,
  dataTestId,
}: SupplierCardProps) {
  const formattedTags = getFormattedTagsForSupplier({
    compliance: supplier.compliance,
    serviceArea: supplier.serviceAreaData,
    activeAgreements: supplier.supplierAgreement.activeAgreements,
    noActiveShareableContracts:
      supplier.supplierDisplay.numActiveContracts === 0,
  });

  const hasTags = !!formattedTags.length;
  const hasSimilarPublicEntities =
    !hasRedesignedSERPCardsM2 &&
    !!supplier.compliance.publicEntitiesServed?.length;
  const hasSimilarOfferings = !!supplier.similarOfferings?.length;

  return (
    <GenericCard
      className={clsx(className, { "border-2": selected })}
      borderColor={selected ? "brand.bold.enabled" : "neutral.subtle.enabled"}
      onClick={onClick}
      style={style}
      loginWallTrigger={loginWallTrigger}
      loginWallTriggerId={loginWallTriggerId}
      trackSerpClick={trackSerpClick}
      dataTestId={dataTestId}
      responsive
    >
      <SupplierCardHeader supplier={supplier} cta={cta} />
      {(hasTags || hasSimilarPublicEntities || hasSimilarOfferings) && (
        <div className="grid gap-2">
          {hasTags && <SupplierTagSubsection tags={formattedTags} />}
          {hasSimilarPublicEntities && (
            <SimilarPublicEntities
              entities={supplier.compliance.publicEntitiesServed}
              className={clsx({
                "line-clamp-1": size === "sm",
                "line-clamp-2": size === "md",
              })}
            />
          )}
          {hasSimilarOfferings && (
            <SimilarOfferings offerings={supplier.similarOfferings || []} />
          )}
        </div>
      )}
    </GenericCard>
  );
}
