import { useState } from "react";
import {
  AgencyTypeEnum,
  ApiService,
  type BuyerLeadAgency,
  type StateCodeEnum,
} from "../generated";
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  DropdownPicker,
  LabeledInput,
} from "../library";
import { LabeledInputLabelStyles } from "../library/Input/LabeledInput";
import Modal, { modalSizes } from "../shared/Modal/Modal";
import { stateOptions } from "../utils/constants";
import { handleError } from "../utils/generatedApi";

interface CreateUnverifiedBLAModalProps {
  hideModal: () => void;
  initialName: string;
  onAgencyCreated: (agency: Maybe<BuyerLeadAgency>) => void;
}

export default function CreateUnverifiedBLAModal({
  hideModal,
  initialName,
  onAgencyCreated,
}: CreateUnverifiedBLAModalProps) {
  const [agencyName, setAgencyName] = useState(initialName);
  const [stateCode, setStateCode] = useState("");
  async function createUnverifiedBLA(agencyName: string) {
    try {
      const agency = await ApiService.apiV1BuyerLeadAgenciesCreate({
        agencyType: AgencyTypeEnum.PENDING_REVIEW,
        name: agencyName,
        stateCode: stateCode as StateCodeEnum,
      });
      onAgencyCreated(agency);
    } catch (err) {
      handleError(err);
      onAgencyCreated(null);
    }
  }

  return (
    <Modal
      hideModal={hideModal}
      title="Add public entity"
      contentClassName="overflow-visible !pt-0 flex flex-col gap-6"
      className="analytics-create-unverified-bla-modal"
      modalSize={modalSizes.SMALL}
    >
      <LabeledInput
        name="searchZip"
        onChange={(e) => setAgencyName(e.target.value)}
        value={agencyName}
        label="Entity Name"
        labelStyle={LabeledInputLabelStyles.FLOATING_SMALL}
        type="text"
        placeholder="Enter entity name"
        size="md"
      />
      <DropdownPicker
        placeholder="Select entity state"
        initialValue=""
        options={stateOptions.map(({ value, label }) => {
          return { key: value, value: value, label: label };
        })}
        label="Entity State"
        onChange={(value) => {
          setStateCode(value);
        }}
      />
      <div className="flex gap-3 pt-3 w-full">
        <Button
          className="analytics-create-unverified-bla-cancel-button w-full"
          size={ButtonSizes.LARGE}
          theme={ButtonThemes.SECONDARY_DARK}
          onClick={hideModal}
        >
          Cancel
        </Button>
        <Button
          className="analytics-create-unverified-bla-submit-button w-full"
          size={ButtonSizes.LARGE}
          theme={ButtonThemes.PRIMARY_DARK}
          onClick={() => {
            createUnverifiedBLA(agencyName);
            hideModal();
          }}
          disabled={!stateCode}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
}
