import type { ElementType, ReactNode } from "react";

import AccountBalanceOutlined from "@mui/icons-material/AccountBalanceOutlined";
import AutoAwesomeOutlined from "@mui/icons-material/AutoAwesomeOutlined";
import AutoAwesomeRounded from "@mui/icons-material/AutoAwesomeRounded";
import BlockRounded from "@mui/icons-material/BlockRounded";
import BoltRounded from "@mui/icons-material/BoltRounded";
import BookmarkRounded from "@mui/icons-material/BookmarkRounded";
import ConstructionRounded from "@mui/icons-material/ConstructionRounded";
import Diversity1Rounded from "@mui/icons-material/Diversity1Rounded";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";
import EqualizerOutlined from "@mui/icons-material/EqualizerOutlined";
import ForumRounded from "@mui/icons-material/ForumRounded";
import GppGoodRounded from "@mui/icons-material/GppGoodRounded";
import HomeRounded from "@mui/icons-material/HomeRounded";
import HourglassEmptyRounded from "@mui/icons-material/HourglassEmptyRounded";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import LocalShippingRounded from "@mui/icons-material/LocalShippingRounded";
import LocationOffRounded from "@mui/icons-material/LocationOffRounded";
import LocationOn from "@mui/icons-material/LocationOn";
import ReportRounded from "@mui/icons-material/ReportRounded";
import RequestQuoteRounded from "@mui/icons-material/RequestQuoteRounded";
import StorefrontRounded from "@mui/icons-material/StorefrontRounded";
import VerifiedIcon from "@mui/icons-material/Verified";
import WarningAmberRounded from "@mui/icons-material/WarningAmberRounded";
import WorkspacePremiumOutlined from "@mui/icons-material/WorkspacePremiumOutlined";
import WorkspacePremiumRounded from "@mui/icons-material/WorkspacePremiumRounded";
import { Badge, Typography } from "../../library";
import { withTooltip } from "../../library/Tooltip";
import { textColorClass } from "../../utils/colors";
import { TagVariants } from "../Tag";

const VARIANTS: Partial<
  Record<TagVariants, { Icon: ElementType; iconColor: string }>
> = {
  [TagVariants.DIVERSITY]: {
    Icon: Diversity1Rounded,
    iconColor: "text-cp-lapis-200",
  },
  [TagVariants.OUT_OF_AREA]: {
    Icon: LocationOffRounded,
    iconColor: "text-cp-red-palette-400",
  },
  [TagVariants.LOCATION]: {
    Icon: StorefrontRounded,
    iconColor: "text-cp-lapis-300",
  },
  [TagVariants.EXPIRED]: {
    Icon: HourglassEmptyRounded,
    iconColor: "text-cp-red-palette-400",
  },
  [TagVariants.WARNING]: {
    Icon: WarningAmberRounded,
    iconColor: "text-cp-persimmon-300",
  },
  [TagVariants.UNSUPPORTED]: {
    Icon: ReportRounded,
    iconColor: "text-cp-red-palette-400",
  },
  [TagVariants.NON_COOPERATIVE]: {
    Icon: BlockRounded,
    iconColor: "text-cp-persimmon-300",
  },
  [TagVariants.VERIFIED]: {
    Icon: GppGoodRounded,
    iconColor: "text-cp-leaf-300",
  },
  [TagVariants.PRICING_DOC]: {
    Icon: RequestQuoteRounded,
    iconColor: "text-cp-lapis-400",
  },
  [TagVariants.PRO_SUPPLIER]: {
    Icon: BoltRounded,
    iconColor: "text-cp-limeade-700",
  },
  [TagVariants.YOUR_CONTRACT]: {
    Icon: BookmarkRounded,
    iconColor: "text-cp-leaf-200",
  },
  [TagVariants.DEBUG_MATCH]: {
    Icon: WorkspacePremiumOutlined,
    iconColor: textColorClass.neutral.bold.enabled,
  },
  [TagVariants.DEBUG_BLA]: {
    Icon: AccountBalanceOutlined,
    iconColor: textColorClass.neutral.bold.enabled,
  },
  [TagVariants.DEBUG_PRO]: {
    Icon: AutoAwesomeOutlined,
    iconColor: textColorClass.neutral.bold.enabled,
  },
  [TagVariants.DEBUG_INFO]: {
    Icon: EqualizerOutlined,
    iconColor: textColorClass.neutral.bold.enabled,
  },
  [TagVariants.NEW_VIEWS]: {
    Icon: AutoAwesomeRounded,
    iconColor: "text-cp-tertiary-yellow",
  },
  [TagVariants.VERIFIED_CONTACT]: {
    Icon: GppGoodRounded,
    iconColor: "text-cp-leaf-300",
  },
  [TagVariants.LOCAL_SUPPLIER]: {
    Icon: StorefrontRounded,
    iconColor: "text-cp-lapis-200",
  },
  [TagVariants.YEARS_IN_BUSINESS]: {
    Icon: StorefrontRounded,
    iconColor: "text-cp-lapis-200",
  },
  [TagVariants.MAX_DELIVERY_TIME]: {
    Icon: LocalShippingRounded,
    iconColor: "text-cp-leaf-300",
  },
  [TagVariants.SERVICE_PROVIDED]: {
    Icon: ConstructionRounded,
    iconColor: "text-cp-leaf-200",
  },
  [TagVariants.MADE_IN_USA]: {
    Icon: HomeRounded,
    iconColor: "text-cp-red-palette-200",
  },
  [TagVariants.BEST_PRICE_GUARANTEE]: {
    Icon: WorkspacePremiumRounded,
    iconColor: "text-cp-limeade-600",
  },
  [TagVariants.FREE_CONSULTATION]: {
    Icon: ForumRounded,
    iconColor: "text-cp-lapis-200",
  },
  [TagVariants.FAMILY_OWNED]: {
    Icon: Diversity1Rounded,
    iconColor: "text-cp-persimmon-200",
  },
  [TagVariants.GOVERNMENT_DISCOUNT]: {
    Icon: LocalOfferRoundedIcon,
    iconColor: "text-cp-limeade-600",
  },
  [TagVariants.SOCIAL_PROOF]: {
    Icon: VerifiedIcon,
    iconColor: "text-cp-limeade-600",
  },
  [TagVariants.LOCAL_TO_CITY]: {
    Icon: LocationOn,
    iconColor: "text-cp-leaf-200",
  },
  [TagVariants.RANKED_FIRST_ON_SOLICITATION]: {
    Icon: EmojiEventsRoundedIcon,
    iconColor: "text-cp-lapis-200",
  },
  [TagVariants.NOT_PRIVATE_COOP_MEMBER]: {
    Icon: WarningAmberRounded,
    iconColor: "text-cp-persimmon-300",
  },
};

function TransparentTag({
  variant,
  size,
  children,
}: { variant: TagVariants; size: "sm" | "md"; children: ReactNode }) {
  const icon = VARIANTS[variant];
  return (
    <Badge
      key={variant}
      as={Typography}
      size={size === "sm" ? "sm-md" : size}
      linkProps={{
        variant: "meta",
        size,
        color: "neutral.bolder.enabled",
      }}
      Icon={icon?.Icon}
      iconClass={icon?.iconColor}
    >
      {children}
    </Badge>
  );
}

export default withTooltip(TransparentTag);
