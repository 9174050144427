/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `NOT_STARTED` - Not Started
 * * `OFFERED` - Offered
 * * `ACCEPTED` - Accepted
 * * `OPEN` - Open to Expansion
 * * `COMPLETED` - Completed Expansion
 * * `DECLINED_SUPPLIER_RESPONDED` - Declined – Supplier Responded
 * * `DECLINED_WAITING` - Declined – Waiting
 */
export enum ExpansionStatusEnum {
    NOT_STARTED = 'NOT_STARTED',
    OFFERED = 'OFFERED',
    ACCEPTED = 'ACCEPTED',
    OPEN = 'OPEN',
    COMPLETED = 'COMPLETED',
    DECLINED_SUPPLIER_RESPONDED = 'DECLINED_SUPPLIER_RESPONDED',
    DECLINED_WAITING = 'DECLINED_WAITING',
}
