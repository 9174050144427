import { useAtomValue } from "jotai";

import favicon from "../../../img/meta/favicon-32x32.png";
import {
  goToLoginPage,
  goToSignupPage,
} from "../../components/Account/helpers";
import useSupplierEditUrl from "../../hooks/useSupplierEditUrl";
import {
  buyerProfileState,
  isAuthenticatedState,
  profileTypeState,
  userInitializedState,
  userState,
} from "../../jotai/user";
import { Link } from "../../library";
import { COMPANY_NAME } from "../../utils/constants";
import {
  ProfileType,
  SearchBarIntentVariants,
  SearchSource,
  SignupOrigin,
  modals,
} from "../../utils/enums";
import { logoPavilion } from "../../utils/img";
import {
  ClickSource,
  trackClickLogin,
  trackClickSignup,
} from "../../utils/tracking";
import MobileSearchButton from "../MobileSearchButton";
import SearchBar, { SearchBarSizes } from "../SearchBar";

import { ApprovalStatusEnum } from "../../generated";
import useShowModal from "../../hooks/useShowModal";
import AuthenticationMenu from "./AuthenticationMenu";
import HamburgerMenu from "./HamburgerMenu";
import HeaderLinkList from "./HeaderLinkList";
import ProfileMenu from "./ProfileMenu";
import { HEADER_LINKS } from "./constants";

interface HeaderProps {
  data?: object;
  searchSource?: SearchSource;
  isSSR?: boolean;
  searchInNewTab?: boolean;
  disableAutocomplete?: boolean;
}

export default function Header({
  searchInNewTab = false,
  disableAutocomplete = false,
  searchSource = SearchSource.DEFAULT,
  isSSR = false,
}: HeaderProps) {
  const showSearchModal = useShowModal(modals.SEARCH);
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const profileType = useAtomValue(profileTypeState);
  const supplierEditUrl = useSupplierEditUrl();
  const { emailVerified, supplier } = useAtomValue(userState);
  const isInitialized = useAtomValue(userInitializedState);
  const { governmentAgency } = useAtomValue(buyerProfileState);

  function onEmptyQuery() {
    showSearchModal({
      source: searchSource,
    });
  }

  function signUp(source: ClickSource) {
    trackClickSignup({ triggerType: source });
    goToSignupPage(SignupOrigin.SIGNUP_BUTTON);
  }

  function login(source: ClickSource) {
    trackClickLogin({ triggerType: source });
    goToLoginPage();
  }

  let links = HEADER_LINKS.default.full();
  if (profileType === ProfileType.BUYER && governmentAgency?.id)
    links = HEADER_LINKS.authenticatedWithBLA();
  else if (profileType === ProfileType.BUYER)
    links = HEADER_LINKS.authenticated();
  else if (profileType === ProfileType.SUPPLIER)
    links = HEADER_LINKS.supplier(
      supplierEditUrl || "",
      isInitialized &&
        !!emailVerified &&
        supplier?.approvalStatus === ApprovalStatusEnum.APPROVED
    );

  return (
    <header className="z-3 w-full sticky bg-cp-violet-200 h-20 border-solid border-b border-neutral-120 flex flex-row items-center justify-between top-0 gap-4">
      <div className="flex ml-6 lg:ml-8 justify-start items-center w-full">
        {!isAuthenticated && (
          <HamburgerMenu
            onClickSignUp={signUp}
            onClickLogin={login}
            items={HEADER_LINKS.default.mobile()}
          />
        )}
        <div className="analytics-logo-link shrink-0">
          <Link
            href={isAuthenticated ? "/welcome" : "/"}
            target="_parent"
            newWindow={false}
          >
            <img
              className="hidden xs:block w-36"
              src={logoPavilion}
              alt={`${COMPANY_NAME} logo`}
            />
            {/*logo height should match the mobile search button height*/}
            <img
              className="block xs:hidden h-[33.5px] rounded-xl"
              src={favicon}
              alt={`${COMPANY_NAME} logo`}
            />
          </Link>
        </div>
        <div className="my-3 ml-4 hidden sm:block w-full">
          <SearchBar
            searchSource={searchSource}
            cbOnEmptyQuery={onEmptyQuery}
            isSSR={isSSR}
            size={SearchBarSizes.RESPONSIVE}
            disableAutocomplete={disableAutocomplete}
            searchInNewTab={searchInNewTab}
            intentVariant={SearchBarIntentVariants.DROPDOWN}
            disambiguate
            syncIntent
          />
        </div>
      </div>

      <div className="mr-4 z-3 w-fit-content">
        <div className="flex flex-row items-center pr-8 mr-4 sm:pr-0 sm:mr-0">
          <MobileSearchButton
            className="sm:hidden analytics-homepage-searchButton mr-4"
            searchSource={searchSource}
          />
          <HeaderLinkList links={links} />
          {isAuthenticated ? (
            <ProfileMenu />
          ) : (
            <AuthenticationMenu
              onClickSignUp={() => signUp(ClickSource.HEADER)}
              onClickLogin={() => login(ClickSource.HEADER)}
            />
          )}
        </div>
      </div>
    </header>
  );
}
