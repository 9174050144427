import clsx from "clsx";
import type { FormikHelpers, FormikValues } from "formik";
import { useAtomValue } from "jotai";

import { ApiService } from "../../generated";
import useShowModal from "../../hooks/useShowModal";
import { userDetailsState } from "../../jotai/user";
import { Typography } from "../../library";
import SupplierSignupFormWrapper from "../../library/form/SupplierSignupFormWrapper";
import PrivacyPolicyNotice from "../../shared/PrivacyPolicyNotice";
import { getParams } from "../../utils";
import {
  LoginType,
  accountModals,
  loginSignupAccountTypes,
  loginSignupSteps,
} from "../../utils/enums";
import { handleError } from "../../utils/generatedApi";
import {
  trackSignupFlowFailure,
  trackSupplierNameConfirmation,
} from "../../utils/tracking";
import { SUPPLIER_FIELDS } from "./constants";
import type { InferredBLA, InferredSupplier } from "./types";
import { WindowType } from "./types";
import useSubmitSupplierInfo from "./useSubmitSupplierInfo";

interface CompleteSupplierAccountFormProps {
  hideModal: () => void;
  onComplete: (redirectUrl: string) => void;
  isBlocking?: boolean;
  goBack?: () => void;
  goToContractPreferencesModalOrPage?: (inferredBLA: InferredBLA) => void;
  inferredBLA?: Maybe<InferredBLA>;
  inferredSupplier?: Maybe<InferredSupplier>;
  supplierName?: string;
  handle?: string;
  parentWindow: WindowType;
}

interface SupplierInviteParams {
  supplierInvite?: string;
  supplierHandle?: string;
  supplierName?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
}

function getInitialSupplierValues(
  urlParams: SupplierInviteParams,
  emailFromState?: string,
  supplierName?: string,
  handle?: string,
  inferredSupplier?: Maybe<InferredSupplier>
): FormikValues {
  const email = urlParams.email || emailFromState;

  const initialFirstName = urlParams.firstName || "";
  const initialLastName = urlParams.lastName || "";

  const initialSupplierName =
    urlParams.supplierName ||
    supplierName ||
    inferredSupplier?.display_name ||
    "";

  const initialSupplierHandle =
    urlParams.supplierHandle || handle || inferredSupplier?.handle || null;
  return {
    password: "",
    email,
    supplier: {
      supplier: {
        displayName: initialSupplierName,
        handle: initialSupplierHandle,
      },
    },
    firstName: initialFirstName,
    lastName: initialLastName,
    phone: null,
    phoneExtension: null,
  };
}

export default function CompleteSupplierAccountForm({
  onComplete,
  supplierName = "",
  handle = "",
  inferredSupplier = null,
  parentWindow,
}: CompleteSupplierAccountFormProps) {
  const { email: emailFromState } = useAtomValue(userDetailsState);
  const showSupplierNameConfirmationModal = useShowModal(
    accountModals.SUPPLIER_NAME_CONFIRMATION
  );

  const urlParams: SupplierInviteParams = getParams();
  const isSupplierInvite =
    !!urlParams.supplierInvite &&
    !!urlParams.supplierHandle &&
    !!urlParams.supplierName &&
    !!urlParams.email;

  const initialSupplierValues: FormikValues = getInitialSupplierValues(
    urlParams,
    emailFromState,
    supplierName,
    handle,
    inferredSupplier
  );
  const isFromSupplierClaimButton = !!initialSupplierValues.supplier.handle;

  const preValidatedFields: string[] = [
    ...(initialSupplierValues.firstName ? ["firstName"] : []),
    ...(initialSupplierValues.lastName ? ["lastName"] : []),
  ];
  const lockedFields: string[] =
    isSupplierInvite || isFromSupplierClaimButton ? ["supplier"] : [];
  const [loading, formErrorMessage, submitSupplierInfo] = useSubmitSupplierInfo(
    {
      email: initialSupplierValues.email,
      onComplete,
      parentWindow,
      isSupplierInvite,
    }
  );

  const onConfirmSupplier = (
    displayName: string,
    handle: Maybe<string>,
    values: FormikValues,
    helpers: FormikHelpers<FormikValues>
  ) => {
    const supplier = {
      supplier: { handle, displayName },
    };

    submitSupplierInfo(
      {
        ...values,
        supplier,
      },
      helpers
    );
    trackSupplierNameConfirmation({
      handle: handle,
      email: values.email,
      displayName: displayName,
      createNew: !handle,
    });
  };

  const onSubmitSupplier = async (
    values: FormikValues,
    helpers: FormikHelpers<FormikValues>
  ) => {
    if (values?.supplier?.supplier.handle) {
      submitSupplierInfo(values, helpers);
      return;
    }
    try {
      const suppliers = await ApiService.apiV1SuppliersList(
        values?.supplier?.supplier.displayName
      );
      if (suppliers.length) {
        showSupplierNameConfirmationModal({
          suppliers,
          proposedSupplierName: values?.supplier?.supplier.displayName,
          onComplete: (displayName: string, handle: Maybe<string>) =>
            onConfirmSupplier(displayName, handle, values, helpers),
        });
      } else {
        submitSupplierInfo(values, helpers);
        return;
      }
    } catch (err) {
      handleError(err);
    }
  };

  function trackInvalidForm(error: Record<string, string>) {
    trackSignupFlowFailure({
      emailEntered: initialSupplierValues.email,
      accountType: loginSignupAccountTypes.SUPPLIER,
      loginType: LoginType.PAVILION,
      signupStep: loginSignupSteps.SIGNUP,
      error: JSON.stringify(error),
      loginExperience: parentWindow,
    });
  }

  return (
    <>
      <SupplierSignupFormWrapper
        fields={SUPPLIER_FIELDS}
        initialValues={initialSupplierValues}
        preValidatedFields={preValidatedFields}
        lockedFields={lockedFields}
        submitCta="Create your account"
        submitClassName="analytics-supplier-signup-modal-cta"
        onSubmit={onSubmitSupplier}
        disabled={loading}
        trackInvalidForm={trackInvalidForm}
      />
      {formErrorMessage && (
        <Typography size="sm" color="destructive.default.primary.enabled">
          {formErrorMessage}
        </Typography>
      )}
      <PrivacyPolicyNotice
        className={clsx({ "mt-10": parentWindow === WindowType.Modal })}
        size="sm"
      />
    </>
  );
}
