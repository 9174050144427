import AddRoundedIcon from "@mui/icons-material/AddRounded";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import saveAs from "file-saver";
import { useState } from "react";
import {
  ApiService,
  ContractFileTypeEnum,
  SupplierEditRequestTypeEnum,
} from "../../../generated";
import useShowModal from "../../../hooks/useShowModal";
import { Badge, Button, ButtonThemes, Typography } from "../../../library";
import type { UploadContractDetails } from "../../../modals/UploadDocumentsModal";
import {
  AnimatedPopupTypes,
  ErrorPopup,
  SuccessPopup,
} from "../../../popups/AnimatedPopup";
import type { BasicContractFile } from "../../../shared/types";
import { modals } from "../../../utils/enums";
import { handleError } from "../../../utils/generatedApi";
import type { UploadDocumentValues } from "../../UploadContractsPage/UploadDocumentsForm";
import EditableFileItem from "./EditableFileItem";
import { CONTRACT_FILE_EXAMPLE_URLS, getEditableContractFiles } from "./utils";

function fileTypeToHeading(fileType: ContractFileTypeEnum) {
  return `${fileType.charAt(0)}${fileType.slice(1).toLowerCase()} documents`;
}

function FileDeletionSuccessPopup({
  showDeleteSuccessPopup,
  setShowDeleteSuccessPopup,
}: {
  showDeleteSuccessPopup: boolean;
  setShowDeleteSuccessPopup: (val: boolean) => void;
}) {
  return (
    <SuccessPopup
      durationSeconds={5}
      show={showDeleteSuccessPopup}
      setShow={setShowDeleteSuccessPopup}
      onClose={() => setShowDeleteSuccessPopup(false)}
    >
      <Typography color="neutral.subtlest.enabled" size="sm">
        We've received your file deletion request. This change will not be
        visible to buyers until review.
      </Typography>
    </SuccessPopup>
  );
}

interface UploadFileTypeSectionProps {
  fileType: ContractFileTypeEnum;
  files: BasicContractFile[];
  supplierId: number;
  uploadContractDetails: UploadContractDetails;
  setCompletedStatus: (completed: boolean) => void;
}

export default function UploadFileTypeSection({
  fileType,
  files,
  supplierId,
  uploadContractDetails,
  setCompletedStatus,
}: UploadFileTypeSectionProps) {
  const showUploadDocumentsModal = useShowModal(modals.UPLOAD_DOCUMENTS);

  const [sectionFiles, setSectionFiles] = useState(files);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  const lowercaseFileType = fileType.toLowerCase();
  const exampleFileUrl = CONTRACT_FILE_EXAMPLE_URLS[fileType];

  function handleClick() {
    showUploadDocumentsModal({
      supplierId,
      contractDetails: uploadContractDetails,
      documentType: fileType,
      title: `Upload ${lowercaseFileType} documents`,
      onComplete: async (values: UploadDocumentValues) => {
        try {
          const supplierEditRequests =
            await ApiService.apiV1SuppliersEditRequestCreate(supplierId, {
              supplierEditRequestType:
                SupplierEditRequestTypeEnum.CONTRACT_FILE_UPLOAD,
              data: values.contractFiles.map(({ name }) => name),
              contractId: uploadContractDetails.docid,
              contractFileType: fileType,
            });
          const newSectionFiles = getEditableContractFiles(
            sectionFiles,
            supplierEditRequests
          );
          setSectionFiles(newSectionFiles);

          // Set completion status to true if a contract file exists
          if (fileType === ContractFileTypeEnum.CONTRACT) {
            setCompletedStatus(!!newSectionFiles.length);
          }
        } catch (error) {
          handleError(error);
        }
      },
    });
  }

  async function onDeleteFile(fileName: string) {
    try {
      await ApiService.apiV1SuppliersEditRequestCreate(supplierId, {
        supplierEditRequestType:
          SupplierEditRequestTypeEnum.CONTRACT_FILE_DELETE,
        data: [fileName],
        contractFileType: fileType,
        contractId: uploadContractDetails.docid,
      });
      const newSectionFiles = sectionFiles.filter(
        ({ name, type }) => name !== fileName || type !== fileType
      );
      setSectionFiles(newSectionFiles);

      // Set completion status to true if a contract file exists
      if (fileType === ContractFileTypeEnum.CONTRACT) {
        setCompletedStatus(!!newSectionFiles.length);
      }

      setShowDeleteSuccessPopup(true);
    } catch (error) {
      handleError(error);
    }
  }

  function onDownloadFile() {
    if (!exampleFileUrl) return;
    try {
      saveAs(exampleFileUrl, `Example ${lowercaseFileType} document`);
    } catch (error) {
      setErrorMessage(
        "An error occurred while downloading. Please try again later."
      );
      setShowErrorPopup(true);
      handleError(error);
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-4">
        <Typography
          component="label"
          variant="headline"
          size="xs"
          emphasis
          color="neutral.boldest.enabled"
        >
          {fileTypeToHeading(fileType)}
          {` (${sectionFiles.length})`}
        </Typography>
        {exampleFileUrl && (
          <Badge
            size="sm"
            Icon={VisibilityOutlinedIcon}
            onClick={onDownloadFile}
            className="text-cp-lapis-500 cursor-pointer"
          >
            <Typography size="sm" variant="body" color="brand.bold.enabled">
              Download example
            </Typography>
          </Badge>
        )}
      </div>
      {!!sectionFiles.length && (
        <div className="flex flex-col gap-1">
          {sectionFiles.map((f, i) => (
            <EditableFileItem
              key={`${i}-${f.name}`}
              file={f}
              onDelete={onDeleteFile}
            />
          ))}
        </div>
      )}
      <div>
        <Button
          theme={ButtonThemes.SECONDARY_DARK}
          size={Button.sizes.SMALL}
          badgeProps={{
            Icon: AddRoundedIcon,
          }}
          onClick={handleClick}
        >
          Add {lowercaseFileType} document
        </Button>
      </div>
      <FileDeletionSuccessPopup
        showDeleteSuccessPopup={showDeleteSuccessPopup}
        setShowDeleteSuccessPopup={setShowDeleteSuccessPopup}
      />
      <ErrorPopup
        type={AnimatedPopupTypes.TIMED}
        durationSeconds={3}
        show={showErrorPopup}
        setShow={setShowErrorPopup}
      >
        {errorMessage}
      </ErrorPopup>
    </div>
  );
}
