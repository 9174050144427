import _debounce from "lodash/debounce";
import pluralize from "pluralize";
import { useState } from "react";

import { ApiService, type SupplierListResponse } from "../../generated";
import { AutocompleteInput, Typography } from "../../library";
import type { InputSizes } from "../../library/Input/LabeledInput";
import { handleError } from "../../utils/generatedApi";

export interface SupplierItem {
  id: string | null;
  label: string;
  Component: typeof SupplierOption;
  data: SupplierListResponse | null;
}

export function SupplierOption(props: SupplierListResponse) {
  let parsedContractCount = props.supplierDisplay.numActiveContracts
    ? props.supplierDisplay.numActiveContracts
    : 0;
  if (Number.isNaN(parsedContractCount)) {
    parsedContractCount = 0;
  }
  return (
    <>
      <Typography>{props?.supplier.displayName}</Typography>
      <div className="flex">
        {(props?.supplier.addressCity || props?.supplier.addressStateCode) && (
          <Typography size="sm" color="subtler">
            {[props.supplier.addressCity, props.supplier.addressStateCode]
              .filter(Boolean)
              .join(", ")}
            ・
          </Typography>
        )}
        <Typography size="sm" color="subtler">
          {parsedContractCount} active{" "}
          {pluralize("contract", parsedContractCount)}
        </Typography>
      </div>
    </>
  );
}

const debouncedGetSuppliersList = _debounce(
  async (
    supplierName: string,
    setResultsList: (items: SupplierItem[]) => void
  ) => {
    if (supplierName.length < 3) {
      setResultsList([]);
      return;
    }
    try {
      const suppliers = await ApiService.apiV1SuppliersList(supplierName);
      setResultsList(
        suppliers.map((s) => ({
          id: s.supplier.handle,
          label: s.supplier.displayName,
          Component: SupplierOption,
          data: s,
        }))
      );
    } catch (err) {
      handleError(err);
    }
  },
  200,
  { leading: false, trailing: true, maxWait: 200 }
);

interface SupplierSearchInputProps {
  value?: SupplierListResponse;
  onChange: (value: string, supplier: SupplierListResponse | null) => void;
  modalSource: Maybe<string>;
  hasLabel?: boolean;
  placeholder?: string;
  errorMessage?: string;
  size?: InputSizes;
  dataTestId?: string;
}

export default function SupplierSearchInput({
  value,
  onChange,
  modalSource,
  hasLabel = true,
  placeholder = "ex. Home Depot",
  errorMessage,
  dataTestId,
  size = "md",
}: SupplierSearchInputProps) {
  const [resultsList, setResultsList] = useState<SupplierItem[]>([]);

  return (
    <div className="relative w-full text-left">
      <AutocompleteInput
        name="supplierName"
        analyticsClass={`analytics-${modalSource}-supplierName`}
        initialValue={value?.supplier?.displayName}
        initialSelectedId={value?.supplier?.handle || null}
        onChange={(value) => debouncedGetSuppliersList(value, setResultsList)}
        onSelect={onChange}
        options={resultsList}
        label={hasLabel ? "Business name" : ""}
        placeholder={placeholder}
        errorMessage={errorMessage}
        dataTestId={dataTestId}
        freeSoloLabel="Create a new supplier"
        size={size}
      />
    </div>
  );
}
