import {
  type DisambiguationSuggestion,
  SuggestionTypeEnum,
} from "../generated";
import type { OnCompleteFn } from "../hooks/useSearchDisambiguationSurvey";
import { Button, ButtonSizes, ButtonThemes } from "../library";
import Modal, { modalSizes } from "../shared/Modal/Modal";
import { getSentenceCase } from "../utils";
import { trackDisambiguationModalClick } from "../utils/tracking";

export default function SearchDisambiguationModal({
  hideModal,
  originalQuery,
  suggestionType,
  supplierDisplayName,
  searchOptions,
  onComplete,
}: {
  hideModal: () => void;
  originalQuery: string;
  suggestionType: Maybe<SuggestionTypeEnum>;
  supplierDisplayName: Maybe<string>;
  searchOptions: DisambiguationSuggestion[];
  onComplete: OnCompleteFn;
}) {
  function onSelect(newQuery: string, selectedOption: string) {
    trackDisambiguationModalClick({
      originalQuery,
      selectedOption,
      newQuery: newQuery,
    });

    onComplete({ newQuery, selectedOption });
    hideModal();
  }

  let modalTitle = `What kind of ${originalQuery} are you looking for?`;
  if (suggestionType === SuggestionTypeEnum.SUPPLIER) {
    modalTitle = `Choose the category that best matches your needs from ${supplierDisplayName}`;
  }

  return (
    <Modal
      hideModal={() => onSelect(originalQuery, originalQuery)}
      className="analytics-disambiguation-modal"
      title={modalTitle}
      titleSize="sm"
      modalSize={modalSizes.SMALL}
      paddingBottom="5"
    >
      <div className="w-full space-y-3">
        {searchOptions.map((option) => {
          return (
            <Button
              key={option.query}
              className="analytics-disambiguation-modal-option w-full text-center"
              theme={ButtonThemes.TERTIARY_DARK}
              size={ButtonSizes.SMALL}
              onClick={() =>
                onSelect(option.redirectQuery || option.query, option.query)
              }
            >
              {getSentenceCase(option.query)}
            </Button>
          );
        })}
      </div>
      <div className="w-full h-px my-3 bg-neutral-200" />
      <Button
        className="analytics-disambiguation-modal-continue w-full"
        theme={ButtonThemes.TERTIARY_DARK}
        size={ButtonSizes.SMALL}
        onClick={() => onSelect(originalQuery, originalQuery)}
      >
        Continue to search for "{originalQuery}"
      </Button>
    </Modal>
  );
}
