import type { ContractOptimizationStatus } from "../../shared/ContractBase/types";
import SupplierTasks from "../supplier/SupplierEditForm/SupplierTasks";

interface EditSolicitationOptimizationTasksProps {
  fullWidth?: boolean;
  optimizationStatus: ContractOptimizationStatus;
  handleScopeTaskClick: () => void;
  handleSummaryTaskClick: () => void;
  handleBrandsTaskClick: () => void;
  handleDocumentsTaskClick: () => void;
}

export default function EditSolicitationOptimizationTasks({
  fullWidth = false,
  optimizationStatus,
  handleScopeTaskClick,
  handleSummaryTaskClick,
  handleBrandsTaskClick,
  handleDocumentsTaskClick,
}: EditSolicitationOptimizationTasksProps) {
  return (
    <SupplierTasks
      fullWidth={fullWidth}
      tasks={[
        {
          label: "Confirm what can be sold under this contract",
          completed: optimizationStatus.hasContractScope,
          buttons: [
            {
              label: "Jump to contract scope",
              onClick: handleScopeTaskClick,
            },
          ],
        },
        {
          label: "Add contract summary",
          completed: optimizationStatus.hasSummary,
          buttons: [
            {
              label: "Jump to summary",
              onClick: handleSummaryTaskClick,
            },
          ],
        },
        {
          label: "Add brands you sell",
          completed: optimizationStatus.hasBrands,
          buttons: [
            {
              label: "Jump to brands",
              onClick: handleBrandsTaskClick,
            },
          ],
        },
        {
          label: "Upload contract documents",
          completed: optimizationStatus.hasContractDoc,
          buttons: [
            {
              label: "Jump to documents",
              onClick: handleDocumentsTaskClick,
            },
          ],
        },
      ]}
    />
  );
}
