import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import clsx from "clsx";
import { useAtomValue } from "jotai";
import { Fragment, useMemo } from "react";
import type { ContractSupplierHit, RelevantContract } from "../../generated";
import { useContractMatchesFilters } from "../../hooks/search/useContractMatchesFilters";
import { useMatchingSource } from "../../hooks/search/useMatchingSource";
import useExactSupplierMatchAlias from "../../hooks/useExactSupplierMatchAlias";
import useIsDebug from "../../hooks/useIsDebug";
import useLoginWall from "../../hooks/useLoginWall";
import { useScopeMatchElements } from "../../hooks/useScopeMatchElements";
import useTrackContractClick from "../../hooks/useTrackContractClick";
import useTrackSerpClick from "../../hooks/useTrackSerpClick";
import useTrackSupplierClick from "../../hooks/useTrackSupplierClick";
import {
  requestIDState,
  useAddRecentSuccessfulSearch,
} from "../../jotai/search";
import {
  contractSourceFilterState,
  expirationFilterState,
} from "../../jotai/searchFilters";
import { buyerProfileState, userZipState } from "../../jotai/user";
import {
  Avatar,
  AvatarSizes,
  AvatarVariant,
  Button,
  ButtonSizes,
  ButtonThemes,
  ClampedText,
  GenericCard,
  Link,
  LogoSizes,
  Tooltip,
  Typography,
} from "../../library";
import { GenericCardTheme } from "../../library/GenericCard";
import {
  BorderedCardSection,
  getContractUrl,
  getTags,
} from "../../shared/ContractBase";
import ContractCTASection from "../../shared/ContractBase/ContractCTASection";
import Tag, { TagVariants } from "../../shared/Tag";
import { goToURL } from "../../utils";
import { borderColorClass, iconColorClass } from "../../utils/colors";
import { parseDate } from "../../utils/date";
import {
  LoginWallTriggers,
  ViewContractRankCTA,
  type pageNavigationSourceTypes,
  resultTypes,
} from "../../utils/enums";
import { getMessageSupplierUrl, getSupplierUrlPath } from "../../utils/format";
import { isFeatureEnabled } from "../../utils/split";
import { trackSerpClick } from "../../utils/tracking";
import { MessageSupplierSource } from "../MessageSupplierPage/types";
import { SupplierProfileAnchorLink } from "../supplier/SupplierProfile/SupplierProfile";
import ProductSection from "./ProductSection";
import { matchTiers, matchesForKey, styleSearchResponseText } from "./utils";

type TrackingFnData = Pick<
  RelevantContract,
  | "docid"
  | "RankingInfo"
  | "matchTier"
  | "buyerLeadAgencyId"
  | "buyerLeadAgency"
  | "cooperativeAffiliation"
> & {
  tagCopyList: string[];
  tagVariantList: string[];
  ctaType: ViewContractRankCTA;
};

function ContractResult({
  result,
  query,
  pageNavigationSource,
  trackContractClick,
  trackSerpClick,
}: {
  result: RelevantContract;
  query: string;
  pageNavigationSource: Maybe<keyof typeof pageNavigationSourceTypes>;
  trackContractClick: (data: TrackingFnData) => void;
  trackSerpClick: (data: TrackingFnData) => void;
}) {
  const buyerProfile = useAtomValue(buyerProfileState);
  const userZip = useAtomValue(userZipState);
  const isDebug = useIsDebug();
  const expirationDate = parseDate(result.expirationTimestamp);
  const scopeMatchText =
    useScopeMatchElements(result, 3, "neutral.bold.enabled") || [];
  const addRecentSuccessfulSearch = useAddRecentSuccessfulSearch();
  const expirationFilter = useAtomValue(expirationFilterState);
  const sourcesFilter = useAtomValue(contractSourceFilterState);
  const matchingSource = useMatchingSource();
  const requestID = useAtomValue(requestIDState);
  const hasRedesignedSerpCards = isFeatureEnabled("redesignedSERPCards");

  const { contractTagElements, tagVariantList, tagCopyList } = getTags({
    contractTagData: result.contractTags,
    expiration_ts: result.expirationTimestamp,
    expiration_date: expirationDate,
    isCooperative: result.cooperativeLanguage ?? false,
    matchTier: result.matchTier,
    blaState: result.buyerLeadAgencyState,
    blaType: result.buyerLeadAgencyType,
    blaRank: result.buyerLeadAgencyRank,
    filterScore: result.RankingInfo.filters,
    semanticScore: result.RankingInfo.semanticScore,
    proBoost: result.RankingInfo.proBoost,
    geoBoost: result.RankingInfo.geoBoost,
    scaledBoost: result.RankingInfo.scaledBoost,
    productBoost: result.RankingInfo.productBoost,
    contractQualityBoost: result.RankingInfo.contractQualityBoost,
    bm25Score: result.RankingInfo.bm25Score,
    isOwnBla:
      !!buyerProfile.governmentAgency &&
      result.buyerLeadAgencyId === buyerProfile.governmentAgency.id,
    isDebug,
    transparent: true,
    size: hasRedesignedSerpCards ? "md" : "sm",
  });

  const data: TrackingFnData = {
    ...result,
    tagVariantList,
    tagCopyList,
    ctaType: ViewContractRankCTA.VIEW_CONTRACT,
  };

  function handleClick() {
    const offeringMatches = matchesForKey(
      result.HighlightResult,
      "contractOfferings"
    );
    const autoExtractedOfferingsMatches = matchesForKey(
      result.HighlightResult,
      "autoExtractedOfferingsList"
    );

    addRecentSuccessfulSearch(query);
    trackContractClick(data);
    goToURL(
      getContractUrl(
        result.solicitationId,
        result.docid,
        query,
        userZip,
        autoExtractedOfferingsMatches.length > 0,
        offeringMatches.length > 0,
        expirationFilter,
        sourcesFilter,
        pageNavigationSource as string,
        requestID
      )
    );
  }

  const source = matchingSource(result);
  let title = (
    <Typography
      size="sm"
      color="neutral.boldest.enabled"
      emphasis
      className="line-clamp-1"
      title={result.title}
    >
      {result.title}
    </Typography>
  );

  if (isDebug && result.RankingInfo.semanticContext) {
    title = (
      <Tooltip className="max-w-88" info={result.RankingInfo.semanticContext}>
        {title}
      </Tooltip>
    );
  }

  return (
    <GenericCard
      loginWallTriggerId={result.docid}
      loginWallTrigger={LoginWallTriggers.CONTRACT_SEARCH_CONTRACT_CLICK}
      trackSerpClick={() => trackSerpClick(data)}
      // GenericCard handles login wall triggering for us.
      onClick={handleClick}
      dataTestId="serp-supplier-result-contract-card"
      className={clsx({
        "!p-3": !hasRedesignedSerpCards,
      })}
      theme={
        hasRedesignedSerpCards
          ? GenericCardTheme.BORDERLESS
          : GenericCardTheme.WITH_BORDER
      }
    >
      <div className="flex flex-row">
        <div
          className={clsx("grid grow", {
            "gap-2": hasRedesignedSerpCards,
            "gap-3": !hasRedesignedSerpCards,
          })}
        >
          <div className="grid gap-1">
            {title}
            {hasRedesignedSerpCards ? (
              <div className="flex flex-wrap items-center gap-x-2 empty:hidden">
                <Typography
                  size="sm"
                  color="neutral.bolder.enabled"
                  className="flex-none"
                >
                  {source}
                </Typography>
                {contractTagElements.length > 0 && (
                  <>
                    <Typography
                      component="span"
                      size="sm"
                      color="neutral.bolder.enabled"
                    >
                      ·
                    </Typography>
                    <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                      {contractTagElements}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <Typography
                component="div"
                size="sm"
                color="neutral.boldest.enabled"
              >
                {source}
              </Typography>
            )}
          </div>
          {!!scopeMatchText.length && (
            <Typography
              variant="meta"
              color="neutral.bold.enabled"
              className="line-clamp-1"
            >
              Confirmed in contract:{" "}
              {scopeMatchText.map((e, ix) => (
                // biome-ignore lint/suspicious/noArrayIndexKey: Index is fine here as these are static.
                <Fragment key={ix}>
                  {e}
                  {ix < scopeMatchText.length - 1 ? " · " : ""}
                </Fragment>
              ))}
            </Typography>
          )}
          {!hasRedesignedSerpCards && (
            <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
              {contractTagElements}
            </div>
          )}
        </div>
        <ContractCTASection
          loginWallTriggerId={result.docid}
          loginWallTrigger={LoginWallTriggers.CONTRACT_SEARCH_CONTRACT_CLICK}
          ctas={[
            {
              styling: "tertiary",
              text: "View contract",
              ctaAnalyticsClass: "analytics-serp-view-contract",
              trackSerpClick: () => trackSerpClick(data),
              onClick: handleClick,
            },
          ]}
        />
      </div>
    </GenericCard>
  );
}

export default function SearchCardSupplierResult({
  hit,
  diversityPreferences = [],
  query,
  pageNavigationSource,
  visibleRank,
  primary,
  requestID,
}: {
  hit: ContractSupplierHit;
  diversityPreferences?: Maybe<string>[];
  query: string;
  pageNavigationSource: Maybe<keyof typeof pageNavigationSourceTypes>;
  visibleRank: number;
  primary: boolean;
  requestID: Maybe<string>;
}) {
  const trackContractClick = useTrackContractClick();
  const trackSupplierClick = useTrackSupplierClick();
  const trackSerpContractClick = useTrackSerpClick();
  const userZip = useAtomValue(userZipState);
  const requireLogin = useLoginWall();
  const contractMatchesFilters = useContractMatchesFilters();
  const hasRedesignedSerpCards = isFeatureEnabled("redesignedSERPCards");
  const hasStandardizedOfferings = isFeatureEnabled(
    "standardizedOfferingsSearch"
  );
  const supplierHasDiversityOverlap =
    hit.supplierDiversityCertificationIds?.some((diversityId) =>
      diversityPreferences.includes(diversityId)
    );
  const exactSupplierMatchAlias = useExactSupplierMatchAlias({
    supplierId: hit.supplierId,
    supplierName: hit.supplierDisplayName,
  });

  const { supplierTagElements, tagVariantList, tagCopyList } = getTags({
    supplierTagData: hit.supplierTags || [],
    matchesDiversity: supplierHasDiversityOverlap,
    transparent: true,
    size: hasRedesignedSerpCards ? "md" : "sm",
  });

  const getTrackingData = (data: TrackingFnData) => ({
    contractId: data.docid,
    supplierId: hit.supplierId,
    supplierHandle: hit.supplierHandle,
    semanticScore: data.RankingInfo.semanticScore,
    absoluteDepth: hit.rank,
    relativeDepth: visibleRank,
    matchTier: data.matchTier,
    buyerLeadAgencyId: data.buyerLeadAgencyId,
    buyerLeadAgency: data.buyerLeadAgency,
    cooperativeAffiliation: data.cooperativeAffiliation,
    filterScore: data.RankingInfo.filters,
    groupingType: hit.groupingType,
    requestID,
    searchQuery: query,
    displayTag: data.tagVariantList,
    displayTagCopy: data.tagCopyList,
    ctaType: data.ctaType,
  });

  const onSupplierClick = (hash?: Maybe<string>) => {
    const data = {
      ...getTrackingData({
        ...hit.relevantSuppliers[0],
        tagVariantList,
        tagCopyList,
        ctaType: ViewContractRankCTA.SUPPLIER_PROFILE,
      }),
      absoluteDepth: hit.rank,
      relativeDepth: visibleRank,
      matchTier: "",
      resultType: resultTypes.SUPPLIER_RESULT,
      hasSimilarEntities: false,
      numEntitiesInState: 0,
      numEntitiesInCategory: 0,
    };
    trackSerpClick(data);
    requireLogin({
      triggerId: hit.supplierHandle,
      triggerType: LoginWallTriggers.CONTRACT_SEARCH_SUPPLIER_CLICK,
      onComplete: () => {
        trackSupplierClick(data);
        goToURL(
          getSupplierUrlPath(
            hit.supplierHandle,
            query,
            userZip,
            pageNavigationSource,
            requestID,
            null,
            hash
          )
        );
      },
    });
  };

  const onContactSupplierClick = (handle: string) => {
    const data = {
      ...getTrackingData({
        ...hit.relevantSuppliers[0],
        tagVariantList,
        tagCopyList,
        ctaType: ViewContractRankCTA.MESSAGE_SUPPLIER,
      }),
      absoluteDepth: hit.rank,
      relativeDepth: visibleRank,
      resultType: resultTypes.SUPPLIER_RESULT,
    };
    trackSerpClick(data);
    requireLogin({
      triggerId: handle,
      triggerType: LoginWallTriggers.CONTRACT_SEARCH_CONTRACT_CLICK,
      onComplete: () => {
        trackContractClick(data);
        goToURL(
          getMessageSupplierUrl({
            handle,
            query,
            zip: userZip,
            requestID: requestID || "",
            messageSupplierSource: MessageSupplierSource.SUPPLIER_SERP,
          })
        );
      },
    });
  };

  const keywords = useMemo(() => {
    if (!hit.relevantSuppliers[0].HighlightResult?.supplierKeywords) {
      return (hit.supplierKeywords || []).map((value) => ({
        value,
      }));
    }
    // Sort full keyword matches to the start of the list.
    return (
      hit.relevantSuppliers[0].HighlightResult?.supplierKeywords || []
    ).sort((a, b) => matchTiers[b.matchLevel] - matchTiers[a.matchLevel]);
  }, [hit]);

  if (hasRedesignedSerpCards) {
    return (
      <div
        className={clsx(
          "rounded-6 border border-solid p-4 grid gap-4 max-w-[49.5rem] bg-white",
          borderColorClass.neutral.subtle.enabled
        )}
      >
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-row gap-3">
            {hit.supplierLogoUrl ? (
              <Avatar
                size={AvatarSizes.LARGE}
                logoImageSrc={hit.supplierLogoUrl}
                logoSize={LogoSizes.XS}
              />
            ) : (
              <Avatar
                size={AvatarSizes.LARGE}
                variant={AvatarVariant.CIRCLE}
                IconComponent={StorefrontOutlinedIcon}
                textColor="brand.subtle.enabled"
                iconClassName={iconColorClass.brand.subtle.enabled}
                bgColor="brand.subtlest.enabled"
              />
            )}
            <div className="grid gap-1">
              <div className="flex flex-wrap gap-x-2 items-center">
                <Link
                  onClick={() => onSupplierClick()}
                  variant="headline"
                  size="sm"
                  emphasis
                  color="brand.boldest.enabled"
                  underline={false}
                  className="hover:underline"
                >
                  {hit.supplierDisplayName}
                </Link>
                {exactSupplierMatchAlias && (
                  <Typography size="sm" color="subtler">
                    Also known as {exactSupplierMatchAlias}
                  </Typography>
                )}
              </div>
              <Link
                onClick={() =>
                  onSupplierClick(SupplierProfileAnchorLink.CONTRACT_LIST)
                }
                size="sm"
                color="neutral.bold.enabled"
                className="hover:underline"
                underline={false}
                emphasis={false}
              >
                {hit.activeShareableContractCount} active contracts
              </Link>
            </div>
          </div>

          <Button
            className="h-fit flex-none analytics-serp-contact-supplier"
            theme={ButtonThemes.SECONDARY_DARK}
            size={ButtonSizes.SMALL}
            onClick={() => {
              onContactSupplierClick(hit.supplierHandle);
            }}
          >
            Request quote
          </Button>
        </div>
        <div className="flex flex-row flex-wrap gap-y-2 gap-x-4 empty:hidden">
          {supplierTagElements}
        </div>
        <div className="grid gap-2 empty:hidden">
          {(hit.supplierSummary || hit.supplierAbout) &&
            (hit.supplierSummary ? (
              <Typography size="sm" color="neutral.boldest.enabled">
                {hit.supplierSummary}
              </Typography>
            ) : (
              <ClampedText
                size="sm"
                color="neutral.boldest.enabled"
                clampColor="neutral.boldest.enabled"
                inline
              >
                {hit.supplierAbout}
              </ClampedText>
            ))}
          {keywords.length > 0 && (
            <div className="flex flex-wrap gap-2 max-h-[26px] overflow-hidden empty:hidden">
              {keywords.slice(0, 3).map(({ value }, ix) => (
                // biome-ignore lint/suspicious/noArrayIndexKey: This list is static so index is ok.
                <Tag key={ix} variant={TagVariants.SUBTLE} size="sm">
                  <p>
                    {styleSearchResponseText(value, {
                      sentenceCase: hasStandardizedOfferings,
                      highlightClassName: "font-semibold",
                    })}
                  </p>
                </Tag>
              ))}
            </div>
          )}
          <ProductSection products={hit.productHits || []} />
          <BorderedCardSection title="Contract matches" sectionClass="p-2">
            {hit.relevantSuppliers
              .filter((result) => !primary || contractMatchesFilters(result))
              .map((result) => (
                <ContractResult
                  key={result.docid}
                  result={result}
                  query={query}
                  trackSerpClick={(data) =>
                    trackSerpContractClick(getTrackingData(data))
                  }
                  trackContractClick={(data) =>
                    trackContractClick(getTrackingData(data))
                  }
                  pageNavigationSource={pageNavigationSource}
                />
              ))}
          </BorderedCardSection>
        </div>
      </div>
    );
  }

  return (
    <div className="grid gap-5 max-w-[49.5rem]">
      <div className="grid gap-5">
        <div className="flex items-center gap-3 justify-between">
          <div className="flex flex-row gap-3">
            {hit.supplierLogoUrl && (
              <Avatar
                size={AvatarSizes.LARGE}
                logoImageSrc={hit.supplierLogoUrl}
                logoSize={LogoSizes.XS}
              />
            )}
            <div className="grid gap-1">
              <div className="flex flex-wrap gap-x-2 items-center">
                <Typography
                  variant="headline"
                  size="sm"
                  emphasis
                  color="brand.boldest.enabled"
                >
                  {hit.supplierDisplayName}
                </Typography>
                {exactSupplierMatchAlias && (
                  <Typography size="sm" color="subtler">
                    Also known as {exactSupplierMatchAlias}
                  </Typography>
                )}
              </div>
              <div className="flex flex-row flex-wrap gap-y-4 gap-x-4 empty:hidden">
                {supplierTagElements}
              </div>
            </div>
          </div>
          <Button
            className="h-fit analytics-serp-contact-supplier"
            theme={ButtonThemes.SECONDARY_DARK}
            size={ButtonSizes.SMALL}
            onClick={() => {
              onContactSupplierClick(hit.supplierHandle);
            }}
          >
            Request quote
          </Button>
        </div>
        {(hit.supplierSummary || hit.supplierAbout) &&
          (hit.supplierSummary ? (
            <Typography size="sm" color="neutral.boldest.enabled">
              {hit.supplierSummary}
            </Typography>
          ) : (
            <ClampedText
              size="sm"
              color="neutral.boldest.enabled"
              inline
              underline={false}
            >
              {hit.supplierAbout}
            </ClampedText>
          ))}
        <div className="flex flex-wrap gap-2 max-h-[23px] overflow-hidden empty:hidden">
          {keywords.slice(0, 5).map(({ value }, ix) => (
            // biome-ignore lint/suspicious/noArrayIndexKey: This list is static so index is ok.
            <Tag key={ix} variant={TagVariants.SUBTLE}>
              <p>
                {styleSearchResponseText(value, {
                  sentenceCase: hasStandardizedOfferings,
                })}
              </p>
            </Tag>
          ))}
        </div>
        <ProductSection products={hit.productHits || []} />
      </div>
      <div className="grid gap-3">
        {hit.relevantSuppliers
          .filter((result) => !primary || contractMatchesFilters(result))
          .map((result) => (
            <ContractResult
              key={result.docid}
              result={result}
              query={query}
              trackSerpClick={(data) =>
                trackSerpContractClick(getTrackingData(data))
              }
              trackContractClick={(data) =>
                trackContractClick(getTrackingData(data))
              }
              pageNavigationSource={pageNavigationSource}
            />
          ))}
        <Link
          emphasis={false}
          color="neutral.bolder.enabled"
          size="sm"
          onClick={() => onSupplierClick()}
          className="analytics-serp-view-supplier"
        >
          View all {hit.supplierDisplayName} contracts
        </Link>
      </div>
    </div>
  );
}
