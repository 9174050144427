import { useAtomValue } from "jotai";
import { titleCase } from "title-case";
import useSearchContractWithParams from "../../../hooks/useSearchContractWithParams";
import {
  contractSearchResponseDataState,
  requestIDState,
  searchResultTypeState,
} from "../../../jotai/search";
import { PopoverMenu, RadioButtonGroup } from "../../../library";
import { getParam } from "../../../utils";
import { SearchIntent } from "../../../utils/enums";
import { trackToggleSearchResultType } from "../../../utils/tracking";

const searchResultTypeOptions = [
  {
    key: "supplier",
    label: "Show results by supplier",
  },
  {
    key: "contract",
    label: "Show results by contract",
  },
];

export default function CompactSearchResultTypeToggle() {
  const contractResponseData = useAtomValue(contractSearchResponseDataState);
  const numAllResults = contractResponseData?.contractData?.numAllResults;
  const searchResultType = useAtomValue(searchResultTypeState);
  const searchContractWithParams = useSearchContractWithParams();
  const requestID = useAtomValue(requestIDState);
  const query = getParam("query");

  // Don't show the toggle when there are no results to collapse,
  // or if we're in RFP research mode.
  if (numAllResults === 0 || getParam("intent") === SearchIntent.RESEARCHING) {
    return null;
  }

  const onChange = (type: string) => {
    if (type === searchResultType) return;
    trackToggleSearchResultType({
      query,
      collapseBySupplier: type === "supplier",
      requestID,
    });
    searchContractWithParams({
      newParams: { collapseBySupplier: type === "supplier" },
    });
  };

  return (
    <div
      aria-label="search-result-type-toggle"
      className="border-r border-solid border-cp-neutral-palette-200"
    >
      <PopoverMenu
        text={`${titleCase(searchResultType)}s`}
        variant="primary"
        typographyVariant="cta"
      >
        <div className="min-w-[240px] flex flex-col gap-4 my-2">
          <RadioButtonGroup
            value={searchResultType}
            options={searchResultTypeOptions}
            onChange={onChange}
          />
        </div>
      </PopoverMenu>
    </div>
  );
}
