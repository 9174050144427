import clsx from "clsx";
import pluralize from "pluralize";
import { Fragment, type ReactNode } from "react";

import _get from "lodash/get";
import { useBenchmarkingCtas } from "../../hooks/useBenchmarkingCtas";
import useIsDebug from "../../hooks/useIsDebug";
import { Tooltip, Typography } from "../../library";

import { useAddRecentSuccessfulSearch } from "../../jotai/search";
import {
  contractSourceFilterState,
  expirationFilterState,
} from "../../jotai/searchFilters";
import { buyerProfileState, userZipState } from "../../jotai/user";
import {
  ContractLeadSubtitle,
  getContractUrl,
} from "../../shared/ContractBase";
import BaseCard from "../../shared/ContractBase/BaseCard";
import BorderedCardSection from "../../shared/ContractBase/BorderedCardSection";
import SupplierDetailComponent from "../../shared/ContractBase/SupplierDetailComponent";
import getTags from "../../shared/ContractBase/getTags";
import type { CTA } from "../../shared/ContractBase/types";
import { getParam, goToURL } from "../../utils";
import { CONTRACT_CARDS_MAX_SUPPLIERS_TO_SHOW } from "../../utils/constants";
import { parseDate } from "../../utils/date";
import { LoginWallTriggers, ViewContractRankCTA } from "../../utils/enums";

import { useAtomValue } from "jotai";
import type { ContractHit, MatchResult } from "../../generated";
import { type BgColor, bgColorClass } from "../../utils/colors";
import {
  getMessageSupplierUrl,
  truncateAroundTargetWord,
} from "../../utils/format";
import { isFeatureEnabled } from "../../utils/split";
import { MessageSupplierSource } from "../MessageSupplierPage/types";
import { FlexibleCardMatch } from "./FlexibleCardMatch";
import type {
  TrackContractCardClickFn,
  TrackContractCardClickFnData,
} from "./types";
import { matchesForKey, styleSearchResponseText } from "./utils";

function SupplierCount({ numSuppliers }: { numSuppliers: number }) {
  return (
    <Typography variant="meta" size="sm" color="subtler" className="text-right">
      {numSuppliers} {pluralize("supplier", numSuppliers)} on contract
    </Typography>
  );
}

interface SingleSupplierCardProps {
  hit: ContractHit;
  subtitle?: ReactNode;
  analyticsClass: string;
  ctas: CTA[];
  diversityPreferences: Maybe<string>[];
  contractTags: ReactNode[];
  supplierTags: ReactNode[];
  onClick: () => void;
  trackSerpClick: () => void;
  bgColor?: BgColor;
}

function SingleSupplierCard({
  hit,
  subtitle,
  analyticsClass,
  ctas,
  contractTags,
  supplierTags,
  onClick,
  trackSerpClick,
  bgColor = "neutral.subtlest.enabled",
}: SingleSupplierCardProps) {
  const hasRedesignedSerpCards = isFeatureEnabled("redesignedSERPCards");
  const benchmarkingCtas: CTA[] = useBenchmarkingCtas({
    solicitationId: hit.solicitationId,
    rank: hit.rank,
  });

  return (
    <BaseCard
      title={hit.title}
      subtitle={subtitle}
      className={clsx(analyticsClass, _get(bgColorClass, bgColor))}
      onClick={onClick}
      ctas={[...benchmarkingCtas, ...ctas]}
      tags={contractTags}
      loginWallTrigger={LoginWallTriggers.CONTRACT_SEARCH_CONTRACT_CLICK}
      loginWallTriggerId={hit.docid}
      trackSerpClick={trackSerpClick}
    >
      <BorderedCardSection
        title={
          hasRedesignedSerpCards
            ? `Suppliers (1 of ${hit.numSuppliers || 1})`
            : "Supplier details"
        }
      >
        <SupplierDetailComponent
          supplierId={hit.supplierId}
          supplierName={hit.supplierDisplayName}
          supplierKeywords={hit.supplierKeywords}
          matchTier={hit.matchTier}
          highlightResult={hit.HighlightResult}
          tags={supplierTags}
          supplierLogoUrl={hit.supplierLogoUrl}
          productHits={hit.relevantSuppliers[0].productHits}
        />
      </BorderedCardSection>
      {!hasRedesignedSerpCards && (
        <SupplierCount numSuppliers={hit.numSuppliers || 1} />
      )}
      <FlexibleCardMatch
        hit={hit}
        showingProducts={hit.relevantSuppliers[0].productHits?.length > 0}
      />
    </BaseCard>
  );
}

interface SearchCardProps {
  hit: ContractHit;
  cardAnalyticsClass: string;
  ctaAnalyticsClass: string;
  query: string;
  trackContractClick: TrackContractCardClickFn;
  trackSerpClick: TrackContractCardClickFn;
  diversityPreferences?: Maybe<string>[];
  pageNavigationSource?: string;
  requestID: Maybe<string>;
  bgColor?: BgColor;
}

export function SearchCard({
  hit,
  cardAnalyticsClass,
  ctaAnalyticsClass,
  query,
  trackContractClick,
  trackSerpClick,
  diversityPreferences = [],
  pageNavigationSource,
  requestID,
  bgColor = "neutral.subtlest.enabled",
}: SearchCardProps) {
  const hasRedesignedSerpCards = isFeatureEnabled("redesignedSERPCards");
  const expirationFilter = useAtomValue(expirationFilterState);
  const sourcesFilter = useAtomValue(contractSourceFilterState);
  const userZip = useAtomValue(userZipState);
  const buyerProfile = useAtomValue(buyerProfileState);
  const addRecentSuccessfulSearch = useAddRecentSuccessfulSearch();
  const expirationDate = parseDate(hit.expirationTimestamp);
  const isDebug = useIsDebug();
  const benchmarkingCtas = useBenchmarkingCtas({
    solicitationId: hit.solicitationId,
    rank: hit.rank,
  });
  const result = hit.HighlightResult;
  const offeringMatches = matchesForKey(result, "contractOfferings");
  const autoExtractedOfferingsMatches = matchesForKey(
    result,
    "autoExtractedOfferingsList"
  );
  const searchIntent = getParam("intent", "");

  const { contractTagElements } = getTags({
    contractTagData: hit.contractTags,
    expiration_ts: hit.expirationTimestamp,
    expiration_date: expirationDate,
    matchTier: hit.matchTier,
    blaState: hit.buyerLeadAgencyState,
    blaType: hit.buyerLeadAgencyType,
    blaRank: hit.buyerLeadAgencyRank,
    filterScore: hit.RankingInfo.filters,
    semanticScore: hit.RankingInfo.semanticScore,
    proBoost: hit.RankingInfo.proBoost,
    productBoost: hit.RankingInfo.productBoost,
    geoBoost: hit.RankingInfo.geoBoost,
    scaledBoost: hit.RankingInfo.scaledBoost,
    contractQualityBoost: hit.RankingInfo.contractQualityBoost,
    bm25Score: hit.RankingInfo.bm25Score,
    isCooperative: hit.cooperativeLanguage ?? false,
    isOwnBla:
      !!buyerProfile.governmentAgency &&
      hit.buyerLeadAgencyId === buyerProfile.governmentAgency.id,
    isDebug,
    transparent: hasRedesignedSerpCards,
    size: "md",
  });

  let subtitle = (
    <ContractLeadSubtitle
      coop={hit.cooperativeAffiliation}
      bla={hit.buyerLeadAgency}
      isCooperative={hit.cooperativeLanguage}
    />
  );
  if (isDebug && hit.RankingInfo.semanticContext) {
    subtitle = (
      <Tooltip
        className="max-w-88"
        info={hit.RankingInfo.semanticContext.replaceAll(", ", "\n")}
        placement="top"
      >
        {subtitle}
      </Tooltip>
    );
  }

  function handleContractClick(data: {
    docid: string;
    supplierId: number;
    supplierHandle: string;
    semanticScore: number;
    displayTag?: string[];
    displayTagCopy?: string[];
  }) {
    addRecentSuccessfulSearch(query);
    trackContractClick({ ...data, ctaType: ViewContractRankCTA.VIEW_CONTRACT });
    goToURL(
      getContractUrl(
        hit.solicitationId,
        data.docid,
        query,
        userZip,
        autoExtractedOfferingsMatches.length > 0,
        offeringMatches.length > 0,
        expirationFilter,
        sourcesFilter,
        pageNavigationSource,
        requestID,
        searchIntent
      )
    );
  }

  const handleContactSupplierClick = (data: {
    docid: string;
    supplierId: number;
    supplierHandle: string;
    semanticScore: number;
    displayTag?: string[];
    displayTagCopy?: string[];
  }) => {
    trackContractClick({
      ...data,
      ctaType: ViewContractRankCTA.MESSAGE_SUPPLIER,
    });
    goToURL(
      getMessageSupplierUrl({
        handle: data.supplierHandle,
        query,
        zip: userZip,
        requestID: requestID || "",
        messageSupplierSource: MessageSupplierSource.CONTRACT_SERP,
      })
    );
  };

  function handleSerpClick(
    data: Omit<TrackContractCardClickFnData, "ctaType">,
    ctaType: ViewContractRankCTA
  ) {
    trackSerpClick({ ...data, ctaType });
  }

  function generateCTAs(data: {
    docid: string;
    supplierId: number;
    supplierHandle: string;
    semanticScore: number;
    displayTag?: string[];
    displayTagCopy?: string[];
  }): CTA[] {
    return [
      {
        styling: "tertiary",
        text: "Request quote",
        ctaAnalyticsClass: "analytics-serp-contact-supplier",
        onClick: () => handleContactSupplierClick(data),
        trackSerpClick: () =>
          handleSerpClick(data, ViewContractRankCTA.MESSAGE_SUPPLIER),
      },
      {
        styling: "secondary",
        text: "View contract",
        ctaAnalyticsClass,
        onClick: () => handleContractClick(data),
        trackSerpClick: () =>
          handleSerpClick(data, ViewContractRankCTA.VIEW_CONTRACT),
      },
    ];
  }

  if (hit.relevantSuppliers?.length === 1) {
    const supplierHasDiversityOverlap =
      hit.relevantSuppliers[0].supplierDiversityCertificationIds?.some(
        (diversityId) => diversityPreferences.includes(diversityId)
      );

    const { supplierTagElements, tagVariantList, tagCopyList } = getTags({
      contractTagData: hit.contractTags,
      supplierTagData: hit.relevantSuppliers?.[0]?.supplierTags || [],
      matchesDiversity: supplierHasDiversityOverlap,
      expiration_ts: hit.expirationTimestamp,
      expiration_date: expirationDate,
      isCooperative: hit.cooperativeLanguage,
      transparent: hasRedesignedSerpCards,
      size: "md",
    });

    const data = {
      docid: hit.docid,
      supplierId: hit.supplierId,
      supplierHandle: hit.supplierHandle,
      semanticScore: hit.RankingInfo.semanticScore,
      displayTag: tagVariantList,
      displayTagCopy: tagCopyList,
    };

    return (
      <SingleSupplierCard
        hit={hit}
        subtitle={subtitle}
        bgColor={bgColor}
        analyticsClass={clsx(cardAnalyticsClass, ctaAnalyticsClass)}
        ctas={generateCTAs(data)}
        onClick={() => handleContractClick(data)}
        trackSerpClick={() =>
          handleSerpClick(data, ViewContractRankCTA.VIEW_CONTRACT)
        }
        diversityPreferences={diversityPreferences}
        contractTags={contractTagElements}
        supplierTags={supplierTagElements}
      />
    );
  }

  const { tagVariantList, tagCopyList } = getTags({
    contractTagData: hit.contractTags,
    supplierTagData: hit.relevantSuppliers?.[0]?.supplierTags || [],
    expiration_ts: hit.expirationTimestamp,
    expiration_date: expirationDate,
    isCooperative: hit.cooperativeLanguage,
  });

  const data = {
    docid: hit.docid,
    supplierId: hit.supplierId,
    supplierHandle: hit.supplierHandle,
    semanticScore: hit.RankingInfo.semanticScore,
    displayTag: tagVariantList,
    displayTagCopy: tagCopyList,
  };

  const titleMatches = matchesForKey(hit.HighlightResult, "contractTitle");

  const titleText =
    titleMatches.length > 0 ? getTitleText(titleMatches) : hit.title;

  return (
    <BaseCard
      title={titleText}
      subtitle={subtitle}
      className={clsx(
        cardAnalyticsClass,
        ctaAnalyticsClass,
        _get(bgColorClass, bgColor)
      )}
      loginWallTrigger={LoginWallTriggers.CONTRACT_SEARCH_CONTRACT_CLICK}
      loginWallTriggerId={hit.docid}
      onClick={() => handleContractClick(data)}
      trackSerpClick={() =>
        handleSerpClick(data, ViewContractRankCTA.VIEW_CONTRACT)
      }
      ctas={benchmarkingCtas}
      tags={contractTagElements}
    >
      <BorderedCardSection
        title={
          hasRedesignedSerpCards
            ? `Suppliers (${hit.relevantSuppliers.length} of ${hit.numSuppliers})`
            : "Supplier details"
        }
      >
        {hit.relevantSuppliers
          ?.slice(0, CONTRACT_CARDS_MAX_SUPPLIERS_TO_SHOW)
          .map((supplierDetails) => {
            const hasDiversityOverlap =
              supplierDetails.supplierDiversityCertificationIds?.some(
                (diversityId) => diversityPreferences.includes(diversityId)
              );
            const {
              supplierTagElements,
              tagVariantList: supplierDisplayTag,
              tagCopyList: supplierDisplayTagCopy,
            } = getTags({
              contractTagData: hit.contractTags,
              supplierTagData: supplierDetails.supplierTags,
              matchesDiversity: hasDiversityOverlap,
              expiration_ts: hit.expirationTimestamp,
              expiration_date: expirationDate,
              isCooperative: hit.cooperativeLanguage,
              transparent: hasRedesignedSerpCards,
              size: "md",
            });

            // Only show products that are associated with this supplier
            const products = supplierDetails.productHits.filter((product) => {
              return product.supplierIds.includes(supplierDetails.supplierId);
            });

            const supplierId = supplierDetails.supplierId;
            return (
              <SupplierDetailComponent
                key={supplierId}
                supplierId={supplierId}
                supplierName={supplierDetails.supplierDisplayName}
                matchTier={hit.matchTier}
                supplierLogoUrl={supplierDetails.supplierLogoUrl}
                supplierKeywords={hit.supplierKeywords}
                highlightResult={supplierDetails.HighlightResult}
                tags={supplierTagElements}
                ctas={generateCTAs({
                  docid: supplierDetails.docid,
                  supplierId: supplierDetails.supplierId,
                  supplierHandle: supplierDetails.supplierHandle,
                  semanticScore: supplierDetails.RankingInfo.semanticScore,
                  displayTag: supplierDisplayTag,
                  displayTagCopy: supplierDisplayTagCopy,
                })}
                loginWallTrigger={
                  LoginWallTriggers.CONTRACT_SEARCH_SUPPLIER_CLICK
                }
                loginWallTriggerId={supplierDetails.docid}
                productHits={products}
              />
            );
          })}
      </BorderedCardSection>
      {!hasRedesignedSerpCards && (
        <SupplierCount numSuppliers={hit.numSuppliers || 0} />
      )}
      <FlexibleCardMatch
        hit={hit}
        showingProducts={hit.relevantSuppliers[0]?.productHits?.length > 0}
      />
    </BaseCard>
  );
}

function getTitleText(titleMatches: MatchResult[]) {
  const hasRedesignedSerpCards = isFeatureEnabled("redesignedSERPCards");
  if (titleMatches.length === 0) {
    return null;
  }

  const maxWords = 1000; // don't limit, as this'll truncate the title
  const maxCharacters = 1000; // don't limit, as this'll truncate the title

  const scopeElements = titleMatches.slice(0, 2).map((match) => {
    let styledValueText = <span key={match.value}>{match.value}</span>;
    if (match.matchLevel !== "semantic") {
      const value = truncateAroundTargetWord(
        match.value,
        "<em>",
        maxWords,
        maxCharacters
      );
      styledValueText = styleSearchResponseText(value, {
        highlightClassName: hasRedesignedSerpCards
          ? "font-semibold"
          : undefined,
      });
    }
    return styledValueText;
  });

  // join the scopeElements React nodes with a semicolon between them
  return scopeElements.reduce((acc, el, ix) => (
    // biome-ignore lint/suspicious/noArrayIndexKey: These elements are static so key is ok.
    <Fragment key={ix}>
      {acc}
      {acc && <span>; </span>}
      {el}
    </Fragment>
  ));
}
