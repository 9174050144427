import { useAtomValue } from "jotai";
import findLastIndex from "lodash/findLastIndex";
import { useMemo } from "react";
import { SearchCard } from "../../../components/ContractSearch/SearchCard";
import SearchCardSupplierResult from "../../../components/ContractSearch/SearchCardSupplierResult";
import type { TrackContractCardClickFnData } from "../../../components/ContractSearch/types";
import type { ContractResult } from "../../../generated";
import useTrackContractClick from "../../../hooks/useTrackContractClick";
import useTrackSerpClick from "../../../hooks/useTrackSerpClick";
import { topSupplierContractCardState } from "../../../jotai/search";
import { matchedSearchResultCountState } from "../../../jotai/searchFilters";
import { pageNavigationSourceTypes } from "../../../utils/enums";
import { isCompetitorMatch, isContractHit } from "../utils";
import CompetitorContainer from "./CompetitorContainer";

interface SearchResultBase {
  diversityPreferences: (string | null)[];
  query: string;
  requestID: Maybe<string>;
}

interface SearchResultProps extends SearchResultBase {
  result: ContractResult;
  visibleRank: number;
  primary: boolean;
}

export function SearchResult({
  result,
  visibleRank,
  diversityPreferences,
  query,
  requestID,
  primary,
}: SearchResultProps) {
  const trackContractClick = useTrackContractClick();
  const trackSerpClick = useTrackSerpClick();

  if (!isContractHit(result)) {
    return (
      <SearchCardSupplierResult
        hit={result}
        diversityPreferences={diversityPreferences}
        query={query}
        pageNavigationSource={pageNavigationSourceTypes.SEARCH}
        visibleRank={visibleRank}
        primary={primary}
        requestID={requestID}
      />
    );
  }

  const getTrackingData = (data: TrackContractCardClickFnData) => ({
    // Properties specific to the contract / supplier combination
    contractId: data.docid,
    supplierId: data.supplierId || result.supplierId,
    supplierHandle: data.supplierHandle || result.supplierHandle,
    semanticScore: data.semanticScore,
    // Properties from the grouped contract result
    absoluteDepth: result.rank,
    relativeDepth: visibleRank,
    matchTier: result.matchTier,
    buyerLeadAgencyId: result.buyerLeadAgencyId,
    buyerLeadAgency: result.buyerLeadAgency,
    cooperativeAffiliation: result.cooperativeAffiliation,
    filterScore: result.RankingInfo.filters,
    groupingType: result.groupingType,
    requestID,
    searchQuery: query,
    displayTag: data.displayTag,
    displayTagCopy: data.displayTagCopy,
    ctaType: data.ctaType,
  });

  return (
    <SearchCard
      hit={result}
      cardAnalyticsClass="analytics-serp-contract-card"
      ctaAnalyticsClass="analytics-serp-view-contract"
      query={query}
      trackSerpClick={(data) => trackSerpClick(getTrackingData(data))}
      trackContractClick={(data) => trackContractClick(getTrackingData(data))}
      diversityPreferences={diversityPreferences}
      pageNavigationSource={pageNavigationSourceTypes.SEARCH}
      requestID={requestID}
      bgColor="neutral.subtlest.enabled"
    />
  );
}

function usePartitionedResults(results: ContractResult[]) {
  return useMemo(() => {
    const firstCompetitorMatch = results.findIndex(isCompetitorMatch);
    const lastCompetitorMatch = findLastIndex(results, isCompetitorMatch);

    if (firstCompetitorMatch === -1) {
      return { first: results, competitors: [], last: [] };
    }

    return {
      first: results.slice(0, firstCompetitorMatch),
      competitors: results.slice(firstCompetitorMatch, lastCompetitorMatch + 1),
      last: results.slice(lastCompetitorMatch + 1),
    };
  }, [results]);
}

function useGetStartingRank(primary: boolean) {
  const matchedSearchResultCount = useAtomValue(matchedSearchResultCountState);
  const topSupplierContractCard = useAtomValue(topSupplierContractCardState);
  const rankOfTopResult = topSupplierContractCard ? 2 : 1;
  if (!primary) {
    return rankOfTopResult + matchedSearchResultCount;
  }
  return rankOfTopResult;
}

interface SearchResultListProps extends SearchResultBase {
  results: ContractResult[];
  numAllResults: number;
  primary: boolean;
  [x: string]: unknown;
}

export default function SearchResultList({
  results,
  query,
  numAllResults,
  primary,
  ...rest
}: SearchResultListProps) {
  const startingRank = useGetStartingRank(primary);
  const { first, competitors, last } = usePartitionedResults(results);
  const competitorCards = competitors.map((result, index) => (
    <SearchResult
      key={isContractHit(result) ? result.docid : result.supplierId}
      result={result}
      query={query}
      visibleRank={startingRank + index + first.length}
      primary={primary}
      {...rest}
    />
  ));

  return (
    <div className="flex flex-col gap-6">
      {first.map((result, index) => (
        <SearchResult
          key={isContractHit(result) ? result.docid : result.supplierId}
          result={result}
          query={query}
          visibleRank={startingRank + index}
          primary={primary}
          {...rest}
        />
      ))}
      {!!competitors.length &&
        (primary ? (
          <CompetitorContainer>{competitorCards}</CompetitorContainer>
        ) : (
          competitorCards
        ))}
      {last.map((result, index) => (
        <SearchResult
          key={isContractHit(result) ? result.docid : result.supplierId}
          result={result}
          query={query}
          visibleRank={startingRank + index + first.length + competitors.length}
          primary={primary}
          {...rest}
        />
      ))}
    </div>
  );
}
