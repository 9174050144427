import { useMemo } from "react";

import type { ContractHit, MatchResult } from "../../../generated";
import Typography from "../../../library/Typography";
import { truncateAroundTargetWord } from "../../../utils/format";
import { matchesForContractHit, styleSearchResponseText } from "../utils";
// TODO(Kwasi): Deprecate this file if proSerpPromotion A/B test does not pass.

const maxWords = 8;
const maxCharacters = 200;

interface MatchesFoundProps {
  hit: ContractHit;
}

export function MatchesFound({ hit }: MatchesFoundProps) {
  const { documentMatches, scopeMatches, semanticMatches } = useMemo(() => {
    // Group matches by whether they are OCR or auto-extracted from the docs,
    // or whether they are manually entered.
    // TODO: Until the proSerpPromotion A/B test ends, `matchesForContractHit` needs to
    //  support contract number matches.
    const filteredMatchTypes = matchesForContractHit(hit).filter(
      (match) => match.matchKind !== "contract_number"
    );

    return filteredMatchTypes.reduce(
      (acc, match) => {
        if (match.isOCR || match.isAutoExtracted)
          acc.documentMatches.push(match);
        else if (match.matchLevel === "semantic")
          acc.semanticMatches.push(match);
        else acc.scopeMatches.push(match);
        return acc;
      },
      { documentMatches: [], scopeMatches: [], semanticMatches: [] } as {
        documentMatches: MatchResult[];
        scopeMatches: MatchResult[];
        semanticMatches: MatchResult[];
      }
    );
  }, [hit]);

  // Early break for when we have no match information.
  if (
    !scopeMatches.length &&
    !documentMatches.length &&
    !semanticMatches.length
  ) {
    return null;
  }

  function getMatchCopy(matches: MatchResult[]) {
    return matches.map((match, ix) => {
      const value = truncateAroundTargetWord(
        match.value,
        "<em>",
        maxWords,
        maxCharacters
      );
      const styledValueText = styleSearchResponseText(value, {
        className: "font-normal",
        highlightClassName: "font-semibold bg-cp-limeade-250",
      });

      return (
        <Typography
          component="span"
          size="sm"
          // biome-ignore lint/suspicious/noArrayIndexKey: Matches are static so use the index.
          key={ix}
          color="accent.neutral.enabled"
        >
          {" "}
          {styledValueText}
          {ix < scopeMatches.length - 1 ? "," : ""}
        </Typography>
      );
    });
  }

  return (
    <div className="flex flex-col gap-y-1">
      {!!scopeMatches.length && (
        <Typography
          size="sm"
          color="accent.neutral.enabled"
          className="line-clamp-1"
        >
          Confirmed in contract scope: {getMatchCopy(scopeMatches)}
        </Typography>
      )}

      {!!documentMatches.length && (
        <Typography
          size="sm"
          color="accent.neutral.enabled"
          className="line-clamp-1"
        >
          Confirmed in contract docs: {getMatchCopy(documentMatches)}
        </Typography>
      )}

      {!documentMatches.length &&
        !scopeMatches.length &&
        !!semanticMatches.length && (
          <Typography
            size="sm"
            color="accent.neutral.enabled"
            className="line-clamp-1"
          >
            Identified by AI in contract:{" "}
            {semanticMatches.map(({ value }, ix) => (
              <Typography
                component="span"
                size="sm"
                // biome-ignore lint/suspicious/noArrayIndexKey: Matches are static so use the index.
                key={ix}
                color="accent.neutral.enabled"
              >
                {value}
              </Typography>
            ))}
          </Typography>
        )}
    </div>
  );
}
