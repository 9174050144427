import {
  ContractFileTypeEnum,
  type SupplierEditRequestSummary,
} from "../../../generated";
import type { BasicContractFile } from "../../../shared/types";

export function getEditableContractFiles(
  existingFiles: BasicContractFile[],
  supplierEditRequests: SupplierEditRequestSummary
): BasicContractFile[] {
  const existingNonDeletedFiles = existingFiles.filter(
    (f) =>
      // Filter out files requested for deletion
      !supplierEditRequests.contractFileDeletes.some(
        (deletedFile) =>
          deletedFile.name === f.name && deletedFile.contractFileType === f.type
      )
  );
  const editRequestFiles: BasicContractFile[] =
    supplierEditRequests.contractFileUploads
      .map(({ name, contractFileType }) => ({ name, type: contractFileType }))
      .filter(
        (editRequestFile) =>
          // Filter out files uploaded via edit requests that have already been accepted
          !existingNonDeletedFiles.some(
            (existingFile) =>
              existingFile.name === editRequestFile.name &&
              existingFile.type === editRequestFile.type
          )
      );

  return existingNonDeletedFiles.concat(editRequestFiles);
}

export const CONTRACT_FILE_EXAMPLE_URLS: Record<
  ContractFileTypeEnum,
  Maybe<string>
> = {
  [ContractFileTypeEnum.CONTRACT]: `https://pavilion-public-files-${process.env.ENVIRONMENT}.s3.amazonaws.com/Pavilion-contract-award-examples.pdf`,
  [ContractFileTypeEnum.BID_SOLICITATION]: `https://pavilion-public-files-${process.env.ENVIRONMENT}.s3.amazonaws.com/Pavilion-solicitation-examples.pdf`,
  [ContractFileTypeEnum.BID_TABULATION]: `https://pavilion-public-files-${process.env.ENVIRONMENT}.s3.amazonaws.com/Pavilion-bid-tabulation-examples.pdf`,
  [ContractFileTypeEnum.RENEWAL]: `https://pavilion-public-files-${process.env.ENVIRONMENT}.s3.amazonaws.com/Pavilion-renewal-examples.pdf`,
  [ContractFileTypeEnum.AMENDMENT]: null,
  [ContractFileTypeEnum.PRICING]: null,
  [ContractFileTypeEnum.AWARD_SUMMARY]: null,
  [ContractFileTypeEnum.INSURANCE]: null,
  [ContractFileTypeEnum.SUPPLIER_RESPONSE]: null,
  [ContractFileTypeEnum.SUPPLIER]: null,
  [ContractFileTypeEnum.OTHER]: null,
};
