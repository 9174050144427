import { type Getter, type Setter, atom, useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback, useEffect } from "react";
import { ApiService, type ProductServiceSearchResponse } from "../generated";
import { handleError } from "../utils/generatedApi";
import { isAuthenticatedState } from "./user";

const productInitializedState = atom(false);

const productLoadingState = atom(false);

export const productLastSearchQueryState = atom("");

const DEFAULT_PRODUCT_RESPONSE = {
  hits: [],
  count: 0,
  totalCount: 0,
  pageSize: 6,
  page: 0,
};

const initialProductResponseState = atom<ProductServiceSearchResponse>(
  DEFAULT_PRODUCT_RESPONSE
);

const productResponseState = atom<ProductServiceSearchResponse>(
  DEFAULT_PRODUCT_RESPONSE
);

export const productState = atom(
  (get) => {
    return {
      isInitialized: get(productInitializedState),
      isLoading: get(productLoadingState),
      initialProducts: get(initialProductResponseState),
      products: get(productResponseState),
      lastSearchQuery: get(productLastSearchQueryState),
    };
  },
  (
    _get,
    set,
    value: {
      isInitialized: boolean;
      isLoading: boolean;
      products: ProductServiceSearchResponse;
      lastSearchQuery: string;
    }
  ) => {
    set(productInitializedState, value.isInitialized);
    set(productLoadingState, value.isLoading);
    set(productResponseState, value.products);
    set(productLastSearchQueryState, value.lastSearchQuery);
  }
);

export function matchesSupplier(
  query: string,
  supplierHandle: string,
  supplierName: string
) {
  const lowerCaseQuery = query.toLowerCase();
  return (
    lowerCaseQuery.includes(supplierHandle.toLowerCase()) ||
    lowerCaseQuery.includes(supplierName.toLowerCase())
  );
}

export function useSearchProductServicesCallback() {
  const searchProductServices = useCallback(
    async (
      _get: Getter,
      set: Setter,
      supplierHandle: string,
      page: number,
      query: string
    ) => {
      try {
        set(productLoadingState, true);
        const response = await ApiService.apiV1ProductServicesRetrieve(
          supplierHandle,
          page,
          query
        );
        set(productResponseState, response);
        set(productLastSearchQueryState, query);
        set(productLoadingState, false);
        return response;
      } catch (error) {
        handleError(error);
        set(productResponseState, { ...DEFAULT_PRODUCT_RESPONSE });
        set(productLoadingState, false);
      }
    },
    []
  );
  return useAtomCallback(searchProductServices);
}

function useInitializeProductServicesCallback() {
  const search = useSearchProductServicesCallback();

  const initialize = useCallback(
    (get: Getter, set: Setter) =>
      async (supplierHandle: string, query: string) => {
        const product = get(productState);
        if (product.isInitialized) return;

        const response = await search(supplierHandle, 0, query);
        if (!response?.count) {
          await search(supplierHandle, 0, "");
        } else {
          set(initialProductResponseState, response);
        }
        set(productInitializedState, true);
      },
    [search]
  );
  return useAtomCallback(initialize);
}

export default function useInitializeProductServices({
  hasProducts,
  handle,
  name,
  query,
}: {
  hasProducts: boolean;
  handle: string;
  name: string;
  query: string;
}) {
  const initializeCallback = useInitializeProductServicesCallback();
  const isAuthenticated = useAtomValue(isAuthenticatedState);

  useEffect(() => {
    if (!isAuthenticated || !hasProducts) return;

    const includesSupplierName = matchesSupplier(query, handle, name);
    const initialize = initializeCallback();
    initialize(handle, includesSupplierName ? "" : query);
  }, [isAuthenticated, hasProducts, handle, name, query, initializeCallback]);
}
