import useShowModal from "../../hooks/useShowModal";
import { Button, ButtonSizes, ButtonThemes, Typography } from "../../library";
import type { UploadContractDetails } from "../../modals/UploadDocumentsModal";
import SupportEmailLink from "../../shared/SupportEmailLink";
import { modals } from "../../utils/enums";

interface UploadDocsCTAContainerProps {
  title: string;
  subtitle: string;
  missingDocs: string[];
  supplierId: number;
  contractDetails: UploadContractDetails;
}

export default function UploadDocsCTAContainer({
  title,
  subtitle,
  missingDocs,
  supplierId,
  contractDetails,
}: UploadDocsCTAContainerProps) {
  const showUploadDocumentsModal = useShowModal(modals.UPLOAD_DOCUMENTS);

  function handleClick() {
    showUploadDocumentsModal({
      supplierId,
      contractDetails,
      title: "Upload additional contract documents",
    });
  }

  return (
    <div className="rounded-3 grid shadow-1 bg-cp-violet-100 p-8 gap-8 min-w-96">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <Typography variant="headline" size="sm" emphasis>
            {title}
          </Typography>
          <div className="flex flex-col gap-1">
            <Typography>{subtitle}</Typography>
            <ul className="flex flex-col list-disc ml-6">
              {missingDocs.map((doc, index) => (
                // biome-ignore lint/suspicious/noArrayIndexKey: This should be updated to use a document id.
                <li key={index}>
                  <Typography>{doc}</Typography>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Button
          theme={ButtonThemes.SECONDARY_DARK}
          onClick={handleClick}
          size={ButtonSizes.SMALL}
          className="min-w-80"
        >
          Upload documents
        </Button>
      </div>
      <Typography>
        <Typography component="span" emphasis>
          Need help?
        </Typography>{" "}
        We’re always happy to help! Just send us a note at{" "}
        <SupportEmailLink underline />.
      </Typography>
    </div>
  );
}
