import _get from "lodash/get";
import { useMemo } from "react";
import {
  matchesForContractHit,
  styleSearchResponseText,
} from "../components/ContractSearch/utils";
import type { ContractHit, RelevantContract } from "../generated";
import type { TypographyColor } from "../library/Typography/types";
import { textColorClass } from "../utils/colors";
import { truncateAroundTargetWord } from "../utils/format";
import { isFeatureEnabled } from "../utils/split";

const FLEXIBLE_MATCH_COPY_BY_KEY = {
  // Match copy for the flexible card system.
  contractBrands: "Brand on contract: ",
  contractNumber: "Contract number: ",
  contractOfferings: "Confirmed in contract scope: ",
  autoExtractedOfferingsList: "Confirmed in contract document: ",
  semanticOffering: "Identified by AI in contract: ",
  psBrand: "Brand in contract scope: ",
  psItemName: "Product on contract: ",
  psSummary: "Product on contract: ",
  psIdsFromSource: "Product on contract: ",
  isOCR: "Found in contract documents: ",
  supplierKeywords: "Supplier offers: ",
} as const;

const maxWords = 8;
const maxCharacters = 200;

export function useScopeMatchElements(
  hit: ContractHit | RelevantContract,
  limit: number,
  color: TypographyColor = "neutral.bolder.enabled"
) {
  const hasRedesignedSerpCards = isFeatureEnabled("redesignedSERPCards");
  const hasStandardizedOfferings = isFeatureEnabled(
    "standardizedOfferingsSearch"
  );
  const allMatches = useMemo(() => {
    // Filter out match kinds not present in our FLEXIBLE_MATCH_COPY_BY_KEY
    const matches = matchesForContractHit(hit).filter(
      (match) =>
        (match.matchKind && match.matchKind in FLEXIBLE_MATCH_COPY_BY_KEY) ||
        match.isOCR
    );
    if (matches.length > 1)
      return matches.filter(({ matchLevel }) => matchLevel !== "semantic");
    return matches;
  }, [hit]);

  // Early break for when we have no match information.
  if (!allMatches.length) {
    return null;
  }

  const scopeElements = allMatches.slice(0, limit).map((match) => {
    if (match.matchLevel === "semantic") {
      return <span key={match.value}>{match.value}</span>;
    }
    const value = truncateAroundTargetWord(
      match.value,
      "<em>",
      maxWords,
      maxCharacters
    );

    const colorClass = _get(textColorClass, color);
    return styleSearchResponseText(value, {
      highlightClassName: `${colorClass} font-semibold ${!hasRedesignedSerpCards && "bg-cp-persimmon-100"}`,
      sentenceCase: hasStandardizedOfferings,
    });
  });

  return scopeElements;
}
