import Brightness1OutlinedIcon from "@mui/icons-material/Brightness1Outlined";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import type { ElementType } from "react";
import Chip from "../../../library/Chip/Chip";
import type { TypographyColor } from "../../../library/Typography/types";
import type { BgColor, IconColor } from "../../../utils/colors";
import { StatusType } from "./types";

const STATUS_VARIANTS: Record<
  StatusType,
  {
    label: string;
    bgColor: BgColor;
    textColor: TypographyColor;
    iconColor: IconColor;
    Icon: ElementType;
  }
> = {
  [StatusType.LOCKED]: {
    label: "Locked",
    bgColor: "accent.neutral.enabled",
    textColor: "accent.neutral.enabled",
    iconColor: "neutral.bold.enabled",
    Icon: LockRoundedIcon,
  },
  [StatusType.INCOMPLETE]: {
    label: "Incomplete",
    bgColor: "accent.persimmon.enabled",
    textColor: "accent.persimmon.enabled",
    iconColor: "accent.persimmon.enabled",
    Icon: Brightness1OutlinedIcon,
  },
  [StatusType.COMPLETE]: {
    label: "Complete",
    bgColor: "feedback.bold.success",
    textColor: "neutral.subtlest.enabled",
    iconColor: "neutral.subtlest.enabled",
    Icon: CheckCircleRoundedIcon,
  },
};

export default function StatusChip({
  status,
}: {
  status: StatusType;
}) {
  const { label, bgColor, textColor, iconColor, Icon } =
    STATUS_VARIANTS[status];

  return (
    <Chip
      keyword={label}
      backgroundColor={bgColor}
      iconColor={iconColor}
      Icon={Icon}
      color={textColor}
      variant="meta"
      size="sm"
      emphasis
    />
  );
}
