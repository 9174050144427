import type { FormikHelpers, FormikValues } from "formik";
import { useAtom } from "jotai";
import { useState } from "react";

import { isFreshSignupState } from "../../jotai/history";
import { getPasswordErrorMessage } from "../../library/PasswordInput";
import {
  handleError,
  patchUserState,
  postSignupSupplier,
} from "../../utils/api";
import {
  LoginType,
  SignupOrigin,
  loginSignupAccountTypes,
  loginSignupSteps,
} from "../../utils/enums";
import {
  changeHeapEventLoginStatus,
  trackSignupFlowFailure,
  trackSignupSuccess,
} from "../../utils/tracking";

import { useSetAtom } from "jotai";
import { userStateState } from "../../jotai/user";
import { appendInitialCompleteAccountValues } from "./helpers";
import type { WindowType } from "./types";

export default function useSubmitSupplierInfo({
  email,
  isSupplierInvite,
  onComplete,
  parentWindow,
}: {
  email?: Maybe<string>;
  isSupplierInvite?: boolean;
  onComplete: (redirectUrl: string) => void;
  parentWindow: WindowType;
}): [
  boolean,
  string,
  (values: FormikValues, helpers: FormikHelpers<FormikValues>) => void,
] {
  const setIsFreshSignup = useSetAtom(isFreshSignupState);
  const [userState, setUserState] = useAtom(userStateState);
  const [loading, setLoading] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState("");

  async function submitSupplierInfo(
    values: FormikValues,
    { setFieldError }: FormikHelpers<FormikValues>
  ) {
    setLoading(true);
    let form = new FormData();
    form = appendInitialCompleteAccountValues(form, values, email || "");
    form.append("supplierHandle", values.supplier?.supplier.handle || "");
    form.append("supplierName", values.supplier?.supplier.displayName || "");
    form.append("role", values.supplierRole);

    const response = await postSignupSupplier(form);
    setLoading(false);

    if (
      handleError(response, {
        logToSentry: true,
        log400ErrorsToSentry: false,
      })
    ) {
      if (response.status === 400) {
        const error = await response.json();
        // When signing up, we use a form that specifically validates password1
        // https://django-allauth.readthedocs.io/en/latest/forms.html
        if (error?.password1) {
          const passwordErrorMessage = getPasswordErrorMessage(
            error?.password1
          );
          setFieldError("password", passwordErrorMessage);
          trackSignupFlowFailure({
            emailEntered: email,
            loginType: LoginType.PAVILION,
            signupStep: loginSignupSteps.SIGNUP,
            error: passwordErrorMessage,
            accountType: loginSignupAccountTypes.SUPPLIER,
            loginExperience: parentWindow,
          });
        } else if (error?.email) {
          setFormErrorMessage(error.email[0].message);
        } else if (error?.phoneNumber) {
          setFieldError("phone", error.phoneNumber[0].message);
        }
      }
      return;
    }

    changeHeapEventLoginStatus(true);
    trackSignupSuccess({
      accountType: loginSignupAccountTypes.SUPPLIER,
      loginType: LoginType.PAVILION,
      emailEntered: email,
      entitySelected: values.supplier?.displayName,
      entityAutofilled: !!isSupplierInvite,
      signupOrigin: isSupplierInvite ? SignupOrigin.SUPPLIER_INVITE : "",
    });

    setIsFreshSignup(true);
    // Our signup form says, "By clicking 'Create account,' you agree to the policy"
    const updatedState = { ...userState, hasAcceptedPrivacyPolicy: true };
    patchUserState(updatedState);
    setUserState(updatedState);
    onComplete(response.url);
  }

  return [loading, formErrorMessage, submitSupplierInfo];
}
