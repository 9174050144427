import { useEffect } from "react";

import { expirationFilterState } from "../../../jotai/searchFilters";
import { PopoverMenu, RadioButtonGroup, Typography } from "../../../library";
import {
  ExpirationDurations,
  type ExpirationFilterType,
  ExpirationStatuses,
  searchFilters,
} from "../../../utils/enums";
import type { FilterObject } from "../../../utils/filters";

import { useAtom, useAtomValue } from "jotai";
import type { OnFilterChangeFn, OnToggleFilterFn } from "./types";

interface ExpirationFilterProps {
  onChange: OnFilterChangeFn;
  filters: string[];
  onToggleFilter: OnToggleFilterFn;
  isLandingPage: boolean;
}

export const expirationOptions: FilterObject[][] = [
  [
    {
      key: ExpirationStatuses.ALL_ACTIVE,
      label: "Show all active contracts",
    },
    {
      key: ExpirationStatuses.ACTIVE_AND_EXPIRED,
      label: "Show all active and expired contracts",
    },
  ],
  [
    { key: ExpirationDurations.MONTHS_6, label: "6+ months" },
    { key: ExpirationDurations.YEARS_1, label: "1+ year" },
    { key: ExpirationDurations.YEARS_2, label: "2+ years" },
  ],
  [{ key: ExpirationDurations.LESS_THAN_6_MONTHS, label: "<6 months" }],
];

const ENABLED_OPTIONS = expirationOptions[1].map(({ key }) => key);

const EXPIRED_KEY = ExpirationStatuses.ACTIVE_AND_EXPIRED;

export default function ExpirationFilter({
  filters,
  onChange,
  onToggleFilter,
  isLandingPage,
}: ExpirationFilterProps) {
  const [expirationFilter, setExpirationFilter] = useAtom(
    expirationFilterState
  );

  const onSelectFilter = (selected: string) => {
    const showExpired = filters.includes(searchFilters.INCLUDE_EXPIRED);

    if (!showExpired && selected === EXPIRED_KEY) {
      onToggleFilter(true, searchFilters.INCLUDE_EXPIRED);
    } else if (showExpired && selected !== EXPIRED_KEY) {
      onToggleFilter(false, searchFilters.INCLUDE_EXPIRED);
    } else {
      onChange({
        type: "expirationDate",
        value: selected,
      });
    }
    setExpirationFilter(selected as ExpirationFilterType);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run only if filters change on the fly.
  useEffect(() => {
    if (
      filters.includes(searchFilters.INCLUDE_EXPIRED) &&
      expirationFilter !== EXPIRED_KEY
    ) {
      setExpirationFilter(EXPIRED_KEY);
    }
  }, [filters]);

  const timedBasedExpirationOptions = isLandingPage
    ? expirationOptions[2].concat(expirationOptions[1])
    : expirationOptions[1];

  return (
    <>
      <RadioButtonGroup
        value={expirationFilter}
        options={expirationOptions[0]}
        className="expiration-filter-show-all"
        onChange={onSelectFilter}
      />
      <Typography variant="meta" color="neutral.default.secondary.enabled">
        Only show contracts that expire in:
      </Typography>
      <RadioButtonGroup
        value={expirationFilter}
        options={timedBasedExpirationOptions}
        onChange={onSelectFilter}
      />
    </>
  );
}

export function ExpirationFilterPopover(props: ExpirationFilterProps) {
  const expirationFilter = useAtomValue(expirationFilterState);

  return (
    <PopoverMenu
      text="Expiration date"
      border
      enabled={ENABLED_OPTIONS.includes(expirationFilter)}
      noWrap
    >
      <div className="min-w-[452px] flex flex-col gap-4 my-2">
        <ExpirationFilter {...props} />
      </div>
    </PopoverMenu>
  );
}
