import { atomWithStorage } from "jotai/utils";

import { createSessionStorage } from "./util";

const isFreshSignupSessionStorage = createSessionStorage<boolean>();
export const isFreshSignupState = atomWithStorage(
  "isFreshSignup",
  false,
  isFreshSignupSessionStorage,
  { getOnInit: true }
);

const hasSeenProjectPromptSessionStorage = createSessionStorage<boolean>();
export const hasSeenProjectPromptState = atomWithStorage(
  "hasSeenProjectPromptState",
  false,
  hasSeenProjectPromptSessionStorage,
  { getOnInit: true }
);

export const optedInOutreachSuppliersState = atomWithStorage<number[]>(
  "optedInOutreachSuppliers",
  [],
  undefined,
  { getOnInit: true }
);

const hasDismissedPurchasingGuideBannerSessionStorage =
  createSessionStorage<boolean>();
export const hasDismissedPurchasingGuideBannerAtom = atomWithStorage(
  "hasDismissedPurchasingGuideBanner",
  false,
  hasDismissedPurchasingGuideBannerSessionStorage,
  { getOnInit: true }
);
