import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineRounded";
import type { UserSupplierContact } from "../../../generated";
import useShowModal from "../../../hooks/useShowModal";
import { Typography } from "../../../library";
import type { TypographyColor } from "../../../library/Typography/types";
import type { AddContactModalUpsertOptions } from "../../../modals/SupplierModals/AddContactModal";
import { deleteSupplierContact, handleError } from "../../../utils/api";
import { bgColorClass, iconColorClass } from "../../../utils/colors";
import { SupplierContactSourceType, modals } from "../../../utils/enums";
import { formatPhoneNumber } from "../../../utils/format";
import { AvatarCard } from "../AvatarCard";
import { getContactDisplayName } from "../SupplierEditForm/SupplierEditSection/helpers";

interface SupplierContactProps {
  handle: string;
  contact: UserSupplierContact;
  isDefaultContact: boolean;
  supplierName: string;
  supplierEmail: string;
  onContactUpsert: (
    c: UserSupplierContact,
    args: AddContactModalUpsertOptions
  ) => void;
  onContactDelete: (c: UserSupplierContact) => void;
}

export default function SupplierContactContainer({
  handle,
  contact,
  isDefaultContact,
  supplierName,
  supplierEmail,
  onContactUpsert,
  onContactDelete,
}: SupplierContactProps) {
  const showAddContactModal = useShowModal(modals.ADD_CONTACT);

  const isPerson = contact.firstName && contact.lastName;
  const displayName = getContactDisplayName(contact, supplierName);

  let monogram = "A";
  if (contact.firstName && contact.lastName) {
    monogram = `${contact.firstName[0]}${contact.lastName[0]}`;
  } else if (contact.title) {
    monogram = contact.title[0];
  }

  const showEditModal = () => {
    showAddContactModal({
      handle,
      id: contact.id,
      firstName: contact.firstName,
      lastName: contact.lastName,
      phoneNumber: contact.phoneNumber?.phoneNumber,
      extension: contact.phoneNumber?.extension,
      email: contact.email,
      title: contact.title,
      isDefaultContact,
      supplierEmail,
      onUpsert: onContactUpsert,
    });
  };

  const menuItems = [
    {
      onClick: showEditModal,
      text: "Edit contact",
    },
    {
      onClick: async () => {
        const response = await deleteSupplierContact(contact.id, handle);
        if (handleError(response)) {
          return;
        }
        onContactDelete(contact);
      },
      color: "destructive.default.primary.enabled" as TypographyColor,
      text: "Delete contact",
    },
  ];

  return (
    <li
      aria-label={`Contact card for ${displayName || "your info"}${
        isDefaultContact ? " (Default contact)" : ""
      }`}
    >
      <AvatarCard
        name={displayName || "Your name"}
        className="min-h-[160px]"
        subsection={
          isPerson ? (
            contact.title ? (
              contact.title
            ) : (
              <Typography
                component="button"
                color="brand.bold.enabled"
                variant="meta"
                underline
                onClick={showEditModal}
              >
                Add title
              </Typography>
            )
          ) : (
            "Team contact"
          )
        }
        monogram={monogram}
        avatarBgColor="brand.subtlest.hovered"
        avatarTextColor="brand.boldest.enabled"
        avatarTextVariant="meta"
        menuItems={menuItems}
        badgeProps={
          isDefaultContact
            ? {
                Icon: StarOutlineOutlinedIcon,
                iconClass: iconColorClass.accent.limeade.enabled,
                size: "sm-md",
                className: bgColorClass.accent.limeade.enabled,
                label: (
                  <Typography
                    variant="meta"
                    size="sm"
                    color="accent.limeade.enabled"
                    emphasis
                  >
                    Default contact
                  </Typography>
                ),
              }
            : {}
        }
      >
        <div className="flex flex-row gap-1">
          {contact.phoneNumber ? (
            <>
              <Typography color="neutral.bolder.enabled">
                {contact.phoneNumber
                  ? formatPhoneNumber(contact.phoneNumber)
                  : "—"}
              </Typography>
              {contact.source === SupplierContactSourceType.MANUAL_ENTRY && (
                <Typography color="neutral.default.secondary.enabled">
                  (Verified)
                </Typography>
              )}
            </>
          ) : (
            <Typography
              component="button"
              color="brand.bold.enabled"
              variant="meta"
              underline
              onClick={showEditModal}
            >
              Add phone number
            </Typography>
          )}
        </div>
        <Typography color="neutral.bolder.enabled" truncate>
          {contact.email || "—"}
        </Typography>
      </AvatarCard>
    </li>
  );
}
