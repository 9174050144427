import { ContractFileTypeEnum } from "../../../generated";
import type { UploadContractDetails } from "../../../modals/UploadDocumentsModal";
import type { GroupedContractDocuments } from "../types";
import UploadFileTypeSection from "./UploadFileTypeSection";

interface FilesUploadSectionProps {
  files: GroupedContractDocuments;
  supplierId: number;
  uploadContractDetails: UploadContractDetails;
  setCompletedStatus: (completed: boolean) => void;
}

export default function FilesUploadSection({
  files,
  supplierId,
  uploadContractDetails,
  setCompletedStatus,
}: FilesUploadSectionProps) {
  return (
    <div className="flex flex-col gap-6">
      <UploadFileTypeSection
        fileType={ContractFileTypeEnum.CONTRACT}
        files={files.contractDocuments}
        supplierId={supplierId}
        uploadContractDetails={uploadContractDetails}
        setCompletedStatus={setCompletedStatus}
      />
      <UploadFileTypeSection
        fileType={ContractFileTypeEnum.BID_SOLICITATION}
        files={files.bidSolicitationDocuments}
        supplierId={supplierId}
        uploadContractDetails={uploadContractDetails}
        setCompletedStatus={setCompletedStatus}
      />
      <UploadFileTypeSection
        fileType={ContractFileTypeEnum.BID_TABULATION}
        files={files.bidTabulationDocuments}
        supplierId={supplierId}
        uploadContractDetails={uploadContractDetails}
        setCompletedStatus={setCompletedStatus}
      />
      <UploadFileTypeSection
        fileType={ContractFileTypeEnum.PRICING}
        files={files.pricingDocuments}
        supplierId={supplierId}
        uploadContractDetails={uploadContractDetails}
        setCompletedStatus={setCompletedStatus}
      />
      <UploadFileTypeSection
        fileType={ContractFileTypeEnum.RENEWAL}
        files={files.renewalDocuments}
        supplierId={supplierId}
        uploadContractDetails={uploadContractDetails}
        setCompletedStatus={setCompletedStatus}
      />
      <UploadFileTypeSection
        fileType={ContractFileTypeEnum.OTHER}
        files={files.otherDocuments}
        supplierId={supplierId}
        uploadContractDetails={uploadContractDetails}
        setCompletedStatus={setCompletedStatus}
      />
    </div>
  );
}
