import { useAtom, useAtomValue } from "jotai";
import pluralize from "pluralize";
import { useEffect, useMemo } from "react";

import clsx from "clsx";
import { useSetAtom } from "jotai";
import { ItemTypeEnum, QualificationTypeEnum } from "../../../generated";
import useLoginWall from "../../../hooks/useLoginWall";
import usePaginatedQuery from "../../../hooks/usePaginatedQuery";
import useRequestID from "../../../hooks/useRequestID";
import useShowModal from "../../../hooks/useShowModal";
import useShowProQualificationEffect from "../../../hooks/useShowProQualificationEffect";
import useInitializeProductServices from "../../../jotai/products";
import { savedProjectState } from "../../../jotai/projects";
import {
  isAuthenticatedState,
  profileTypeState,
  supplierState,
  userEmailVerifiedState,
  userInitializedState,
  userStateState,
  userTypeSignupState,
} from "../../../jotai/user";
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  PageSection,
  Typography,
} from "../../../library";
import type { SupplierProfileData } from "../../../pages/Supplier";
import AccountVerificationBanner from "../../../shared/AccountVerificationBanner";
import ProductList from "../../../shared/ProductList";
import RecommendationCarousel, {
  RecommendationTypes,
} from "../../../shared/RecommendationCarousel/RecommendationCarousel";
import {
  getParam,
  getParams,
  getRequestID,
  getSentenceCase,
} from "../../../utils";
import {
  getSupplierContracts,
  patchUserState,
  postBuyerQualificationSession,
} from "../../../utils/api";
import { elevationClass } from "../../../utils/designTokens";
import {
  LoginWallTriggers,
  ProfileType,
  accountModals,
  loginSignupAccountTypes,
  pageNavigationSourceTypes,
} from "../../../utils/enums";
import { supplierHasFeature } from "../../../utils/featureManagement";
import { isFeatureEnabled } from "../../../utils/split";
import {
  addLinkShareTrackingParams,
  referredFromSearchEngine,
  trackClickSupplierRecommendation,
  trackContactSupplierFromProfile,
  trackPossibleSharedLinkVisit,
  trackViewSupplierProfile,
  trackViewSupplierRecommendations,
} from "../../../utils/tracking";
import AboutSupplier from "../AboutSupplier";
import SupplierCTAContainer from "../SupplierCTAContainer";
import ClaimSupplierCTAContainer from "./ClaimSupplierCTAContainer";
import SupplierContractList from "./SupplierContractList";
import SupplierHeader from "./SupplierHeader";

interface SupplierProfileProps {
  supplier: SupplierProfileData;
  hasClaimedSupplier: boolean;
  ownsSupplier: boolean;
}

export enum SupplierProfileAnchorLink {
  CONTRACT_LIST = "contractList",
}

/**
 * Make the supplier page to take up the whole page, with a max of 75rem,
 * and be centered by PageSection's m-auto while accounting for "px-6 md:px-8".
 */
const PAGE_WIDTH_CLASS =
  "w-[calc(100vw-3rem)] md:w-[calc(100vw-4rem)] xl:w-[75rem]";

export default function SupplierProfile({
  supplier,
  hasClaimedSupplier,
  ownsSupplier,
}: SupplierProfileProps) {
  const {
    id,
    name,
    claimed,
    contracts,
    contracts_count,
    supplier_contact: { website },
    supplier_phone_contacts,
    has_confirmed_contact,
    handle,
    supplier_compliance,
    service_area_data,
    logo_url,
    has_products,
    pro_qualifications,
    sat_url,
    saved_project_id,
  } = supplier;
  const [userState, setUserState] = useAtom(userStateState);
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const profileType = useAtomValue(profileTypeState);
  const isInitialized = useAtomValue(userInitializedState);
  const isEmailVerified = useAtomValue(userEmailVerifiedState);
  const isUnverified = hasClaimedSupplier && !isEmailVerified;
  const isPending = hasClaimedSupplier && isEmailVerified && !ownsSupplier;
  const userSupplier = useAtomValue(supplierState);
  const requireLogin = useLoginWall();
  const query = getParam("query");
  const showProductListAndCta =
    userSupplier.handle === handle || profileType !== ProfileType.SUPPLIER;
  const setSavedProject = useSetAtom(savedProjectState);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run only on the first render.
  useEffect(() => {
    if (!saved_project_id) return;
    setSavedProject({ id: saved_project_id, type: ItemTypeEnum.SUPPLIER });
  }, []);

  // show claim section only if user is not logged in, the supplier (company)
  // has not been claimed by any other user and the user has not claimed any
  // supplier (company)
  const showClaimSection = !isAuthenticated && !claimed && !hasClaimedSupplier;

  useShowProQualificationEffect({
    proQualifications: pro_qualifications,
    supplierId: id,
    supplierName: name,
  });
  useInitializeProductServices({
    hasProducts: has_products,
    handle,
    name,
    query: query || "",
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run only on first view.
  useEffect(() => {
    const params = getParams();
    let pageNavigationSource =
      params.pageNavigationSource || pageNavigationSourceTypes.DIRECT;
    if (params.tuid || params.tsid) {
      trackPossibleSharedLinkVisit({
        originatingUserId: params.tuid as string,
        originatingSessionId: params.tsid as string,
        supplierHandle: handle,
      });
      pageNavigationSource =
        params.pageNavigationSource || pageNavigationSourceTypes.REFERRED;
    } else {
      addLinkShareTrackingParams();
    }

    if (
      supplierHasFeature(supplier.active_agreements, "proQualifications") &&
      params.supplierShare
    ) {
      postBuyerQualificationSession({
        supplierName: name,
        supplierId: id,
        solicitationId: null,
        minOrderSize: null,
        qualifiedProducts: null,
        qualifiedBrands: null,
        meetsCriteria: false,
        qualificationType: QualificationTypeEnum.SUPPLIER_SHARE,
        searchLogId: getRequestID(),
        daysActive: 30,
      });

      if (!isAuthenticated) {
        requireLogin({
          triggerId: handle,
          triggerType: LoginWallTriggers.SUPPLIER_SHARE_LINK_VISIT,
          title: `${name} has invited you to view their shareable contracts on Pavilion`,
          subtitle: "Full access only available for public entities",
          userType: loginSignupAccountTypes.BUYER,
          isBlocking: true,
          supplierLogoUrl: logo_url,
        });
      }
    }

    trackViewSupplierProfile({
      supplierId: id,
      supplierName: name,
      supplierHandle: handle,
      supplierUrl: website,
      pageNavigationSource: pageNavigationSource as string,
      requestID: params.requestID as string,
    });
  }, []);

  const { list, fetchMore, isLoading, hasNext } = usePaginatedQuery({
    initialList: contracts,
    initialPage: 1,
    total: contracts_count,
    fetchList: ({ page }: { page: number }) =>
      getSupplierContracts({
        page,
        pageSize: 10,
        supplierId: supplier.id,
        sortByLocation: true,
      }),
    onResponse: ({ contracts, count }) => ({
      list: contracts,
      count,
    }),
  });

  const requestID = useRequestID();

  function renderSupplierCTA() {
    return (
      <div className="flex flex-col gap-6">
        <SupplierCTAContainer
          id={id}
          name={name}
          handle={handle}
          activeAgreements={supplier.active_agreements}
          supplierContacts={supplier_phone_contacts}
          hasConfirmedContact={has_confirmed_contact}
          trackClick={() => {
            trackContactSupplierFromProfile({
              supplierId: id,
              supplierHandle: handle,
              requestID,
            });
            if (!userState.hasContactedSupplier) {
              patchUserState({ hasContactedSupplier: true });
              setUserState((prev) => ({
                ...prev,
                hasContactedSupplier: true,
              }));
            }
          }}
        >
          {showClaimSection && (
            <ClaimSupplierCTAContainer name={name} handle={handle} />
          )}
        </SupplierCTAContainer>
      </div>
    );
  }
  const hasStandardizedOfferings = isFeatureEnabled(
    "standardizedOfferingsSearch"
  );

  const offeringsList = useMemo(() => {
    const offerings = hasStandardizedOfferings
      ? supplier.supplier_offerings
      : supplier.offerings_list;
    return offerings?.map(getSentenceCase) || [];
  }, [hasStandardizedOfferings, supplier]);

  return (
    <PageSection className="py-16 flex flex-col">
      {isInitialized &&
        (isUnverified || isPending) &&
        !referredFromSearchEngine() && (
          <div className="mb-8 w-full">
            <AccountVerificationBanner />
          </div>
        )}
      <div
        className={clsx(
          "w-full grid grid-cols-9 gap-x-8 gap-y-12 md:my-0 bg-white",
          PAGE_WIDTH_CLASS
        )}
      >
        <SupplierHeader
          title={name}
          supplierId={id}
          supplierName={name}
          supplierActiveAgreements={supplier.active_agreements}
          supplierCompliance={supplier_compliance}
          supplierServiceArea={service_area_data}
          logoUrl={logo_url}
          satUrl={sat_url}
          showSaveToProject={
            isAuthenticated && profileType === ProfileType.BUYER
          }
        />
        {showProductListAndCta && (
          <div className="block col-span-full lg:hidden empty:hidden">
            {renderSupplierCTA()}
          </div>
        )}
        <div className="col-span-full lg:col-span-6 flex flex-col gap-6">
          {!isAuthenticated &&
            referredFromSearchEngine() &&
            isFeatureEnabled("supplierSEOBannerV2") && (
              <SupplierClaimSEOBanner
                supplierHandle={supplier.handle}
                supplierName={supplier.name}
              />
            )}
          <AboutSupplier
            className="grid gap-6"
            id={supplier.id}
            handle={supplier.handle}
            aliases={supplier_compliance.aliases}
            about={supplier.about}
            offeringsList={offeringsList}
            website={supplier.supplier_contact.website}
            catalog={supplier.supplier_contact.catalogUrl}
            addressCity={supplier.supplier_contact.addressCity}
            addressStateCode={supplier.supplier_contact.addressStateCode}
            activeAgreements={supplier.active_agreements}
            activeContractCount={supplier.num_active_contracts}
            diversityCertifications={
              supplier_compliance.diversityCertifications
            }
            publicEntitiesServed={supplier_compliance.publicEntitiesServed}
            showBottomDivider={contracts_count > 0}
          />
          {has_products && showProductListAndCta && (
            <>
              <ProductList
                supplierHandle={supplier.handle}
                supplierName={supplier.name}
                supplierId={supplier.id}
                onSupplierPage
              />
              <hr className="border-cp-neutral-200" />
            </>
          )}
          {contracts_count > 0 && (
            <div
              className="flex flex-col gap-4 scroll-m-24"
              id={SupplierProfileAnchorLink.CONTRACT_LIST}
            >
              <Typography
                variant="headline"
                size="md"
                emphasis
                color="brand.default.secondary.enabled"
              >{`${contracts_count} ${pluralize(
                "Contract",
                contracts_count
              )}`}</Typography>
              <SupplierContractList
                id={id}
                handle={handle}
                contracts={list}
                fetchMore={fetchMore}
                isLoading={isLoading}
                hasNext={hasNext}
              />
            </div>
          )}
        </div>
        {showProductListAndCta && (
          <div className="hidden lg:block col-span-full lg:col-span-3 empty:hidden">
            <div className="sticky top-24">{renderSupplierCTA()}</div>
          </div>
        )}
        <RecommendationCarousel
          className="col-span-full pt-10 border-solid border-cp-white-200 border-t"
          type={RecommendationTypes.supplierToSupplier}
          id={handle}
          onRender={(suppliers) => {
            if (suppliers?.length) {
              trackViewSupplierRecommendations({
                supplierId: supplier.id,
                supplierName: supplier.name,
                supplierHandle: supplier.handle,
                requestId: requestID,
                numRecommendations: suppliers.length,
                supplierRecommendations: suppliers
                  .map((s) => s.supplier.handle)
                  .join(","),
                proSupplierRecommendations: suppliers
                  .filter((s) =>
                    supplierHasFeature(
                      s.supplierAgreement.activeAgreements,
                      "analyticsTrackIsPro"
                    )
                  )
                  .map((s) => s.supplier.handle)
                  .join(","),
              });
            }
          }}
          onClick={({ supplier: recSupplier }) => {
            trackClickSupplierRecommendation({
              supplierId: supplier.id,
              supplierName: supplier.name,
              supplierHandle: supplier.handle,
              requestId: requestID,
              recommendedSupplierId: recSupplier.supplier.id,
              recommendedSupplierHandle: recSupplier.supplier.handle,
              recommendedProSupplier: supplierHasFeature(
                recSupplier.supplierAgreement.activeAgreements,
                "analyticsTrackIsPro"
              ),
            });
          }}
        />
      </div>
    </PageSection>
  );
}

function SupplierClaimSEOBanner({
  supplierHandle,
  supplierName,
}: {
  supplierHandle: string;
  supplierName: string;
}) {
  const showSignUpModal = useShowModal(accountModals.INITIAL_SIGN_UP);
  const setUserType = useSetAtom(userTypeSignupState);

  return (
    <div
      className={`analytics-supplier-claim-seo-banner flex justify-between col-span-full bg-cp-violet-100 rounded-xl pt-7 pr-8 pb-8 pl-8 ${elevationClass["elevation-1"]}`}
    >
      <div>
        <Typography className="pb-1" variant="headline" emphasis>
          Do you work at {supplierName}?
        </Typography>
        <Typography>
          Get more business by adding relevant details to your profile.
        </Typography>
      </div>
      <Button
        theme={ButtonThemes.SECONDARY_DARK}
        size={ButtonSizes.LARGE}
        onClick={() => {
          setUserType(loginSignupAccountTypes.SUPPLIER);
          showSignUpModal({
            supplierName,
            supplierHandle,
          });
        }}
      >
        Edit Profile
      </Button>
    </div>
  );
}
