import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import { useAtomValue } from "jotai";
import _uniq from "lodash/uniq";
import { useMemo } from "react";

import useDiversityCertificationsValue from "../../../hooks/useDiversityCertificationsValue";
import {
  diversityCertificationsFilterState,
  singleAwardOnlyFilterState,
} from "../../../jotai/searchFilters";
import { isAuthenticatedState } from "../../../jotai/user";
import { Button, Checkbox, PopoverMenu, Typography } from "../../../library";
import { searchFilters } from "../../../utils/enums";

import { useAtom } from "jotai";
import ManagePreferencesLink from "./ManagePreferencesLink";
import type { OnFilterChangeFn, OnToggleFilterFn } from "./types";
import useResetFiltersAndSearch from "./useResetFiltersAndSearch";

interface OtherFiltersProps {
  onChange: OnFilterChangeFn;
  filters: string[];
  onToggleFilter: OnToggleFilterFn;
}

export function OtherFilters({
  filters,
  onToggleFilter,
  onChange,
}: OtherFiltersProps) {
  const isAuthenticated = useAtomValue(isAuthenticatedState);

  const diversityCertifications = useDiversityCertificationsValue();
  const [diversityCertificationFilter, setDiversityCertificationFilter] =
    useAtom(diversityCertificationsFilterState);
  const [singleAwardOnlyFilter, setSingleAwardOnlyFilter] = useAtom(
    singleAwardOnlyFilterState
  );

  const showOnlyCoop = !filters.includes(searchFilters.INCLUDE_NON_COOP);
  const ignoreAgencyLocation = filters.includes(
    searchFilters.IS_NOT_LOCATION_RELEVANT
  );
  const excludeUnusableContracts = !filters.includes(
    searchFilters.INCLUDE_UNUSABLE
  );

  const diversityShortText = useMemo(() => {
    const groups = _uniq(
      Object.values(diversityCertifications).map(({ group }) => group)
    );
    return groups.join(", ");
  }, [diversityCertifications]);

  return (
    <div className="flex flex-col gap-4">
      <ManagePreferencesLink />
      <Checkbox
        name="showOnlyCoop"
        label="Only show contracts with cooperative language in search results"
        checked={showOnlyCoop}
        onChange={(e) =>
          onToggleFilter(!e.target.checked, searchFilters.INCLUDE_NON_COOP)
        }
      />
      <Checkbox
        name="singleAwardOnlyFilter"
        label="Only show single-award contracts"
        checked={singleAwardOnlyFilter}
        onChange={(e) => {
          setSingleAwardOnlyFilter(e.target.checked);
        }}
      />
      <div>
        <Checkbox
          name="diversityCertificationFilter"
          label="Only show suppliers that meet my diversity criteria"
          checked={isAuthenticated && diversityCertificationFilter}
          disabled={!isAuthenticated}
          onChange={(e) => {
            setDiversityCertificationFilter(e.target.checked);
            onChange({
              type: "diversityCertification",
              value: e.target.value,
            });
          }}
        />
        {diversityCertificationFilter && diversityShortText && (
          <Typography
            className="ml-6 mt-2"
            variant="meta"
            color="subtler"
            emphasis
          >
            {diversityShortText} preferences applied
          </Typography>
        )}
      </div>
      <Checkbox
        name="ignoreAgencyLocation"
        label="Ignore my agency's location in search results"
        checked={ignoreAgencyLocation}
        onChange={(e) =>
          onToggleFilter(
            e.target.checked,
            searchFilters.IS_NOT_LOCATION_RELEVANT
          )
        }
      />
      <Checkbox
        name="excludeUnsupportedContracts"
        label="Only show contracts available for use"
        checked={excludeUnusableContracts}
        onChange={(e) =>
          onToggleFilter(!e.target.checked, searchFilters.INCLUDE_UNUSABLE)
        }
      />
    </div>
  );
}

export default function OtherFiltersPopover({
  filters,
  onToggleFilter,
  onChange,
}: OtherFiltersProps) {
  const resetFilters = useResetFiltersAndSearch();
  return (
    <PopoverMenu
      text="More filters"
      variant="primary"
      Icon={TuneRoundedIcon}
      reverse={false}
      noWrap
    >
      {({ close }) => (
        <div className="min-w-[452px] flex flex-col gap-10 my-2">
          <Typography
            variant="headline"
            size="sm"
            color="brand.default.secondary.enabled"
            emphasis
          >
            More filters
          </Typography>
          <OtherFilters
            filters={filters}
            onToggleFilter={onToggleFilter}
            onChange={onChange}
          />
          <div className="flex flex-row gap-3">
            <Button onClick={close}>Show results</Button>
            <Typography
              component="button"
              emphasis
              color="brand.default.primary.enabled"
              className="cursor-pointer items-center"
              onClick={resetFilters}
            >
              Reset Filters
            </Typography>
          </div>
        </div>
      )}
    </PopoverMenu>
  );
}
