import _omitBy from "lodash/omitBy";

import { useAtomValue } from "jotai";
import type { QuoteRequestRequest } from "../../generated/models/QuoteRequestRequest";
import { ApiService } from "../../generated/services/ApiService";
import { pageTypeState } from "../../jotai/page";
import { requestIDState } from "../../jotai/search";
import { PageSection, Typography } from "../../library";
import { useSubmitAndSave } from "../../modals/constants";
import { getParams, goToURL } from "../../utils";
import { handleError } from "../../utils/generatedApi";
import { trackNewProject, trackQuoteRequestSubmit } from "../../utils/tracking";
import { ProjectCreationSource } from "../Project/types";
import { HowItWorksCard } from "./HowItWorksCard";
import { QuoteRequestForm } from "./QuoteRequestForm";

export default function QuoteRequestPage() {
  const params = getParams();
  const requestID = useAtomValue(requestIDState);
  const pageType = useAtomValue(pageTypeState);

  const [handleSubmit, loading] = useSubmitAndSave(
    () => {},
    async ({ description, ...values }: QuoteRequestRequest) => {
      try {
        const queryString = params.query?.toString();
        const projectName = queryString
          ? `Quote request for "${queryString}"`
          : "My new project";
        const [project] = await Promise.all([
          ApiService.apiV1ProjectsCreate({
            name: projectName,
          }),
          ApiService.apiV1QuoteRequestsCreate({
            description,
            ..._omitBy(values, (v) => !v),
          }),
        ]);

        trackNewProject({ source: ProjectCreationSource.QUOTE_REQUEST });
        trackQuoteRequestSubmit({
          searchQuery: queryString || "",
          requestID,
          pageType,
        });

        goToURL(
          "/quote-request/success",
          { ...params, newProjectId: project.id },
          false
        );
      } catch (err) {
        handleError(err);
        return;
      }
    }
  );

  return (
    <PageSection className="pt-12 pb-48">
      <Typography
        variant="display"
        size="sm"
        color="brand.default.secondary.enabled"
        className="mb-12"
      >
        Get a quote
      </Typography>
      <div className="lg:grid lg:grid-cols-12 lg:gap-6 flex flex-col-reverse flex-col gap-12 ">
        <div className="lg:col-span-4">
          <HowItWorksCard />
        </div>
        <div className="lg:col-span-8">
          <QuoteRequestForm handleSubmit={handleSubmit} isLoading={loading} />
        </div>
      </div>
    </PageSection>
  );
}
