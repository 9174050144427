import clsx from "clsx";
import { useEffect, useState } from "react";

import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import usePaginatedQuery from "../../../../../hooks/usePaginatedQuery";
import { Link, Typography } from "../../../../../library";
import { LoadingCards } from "../../../../../shared/Loading/LoadingCards";
import NoContractsInfo from "../../../../../shared/NoContractsInfo";
import { getParam } from "../../../../../utils";
import { borderColorClass, iconColorClass } from "../../../../../utils/colors";
import { MANAGE_CONTRACTS_FILTER_OPTIONS } from "../../../../../utils/constants";

import { ApiService } from "../../../../../generated";
import { handleError } from "../../../../../utils/generatedApi";
import SupplierManageContractList from "./SupplierManageContractsList";

const CONTAINER_STYLE = "flex flex-col gap-6";

const SECTION_STYLE = clsx(
  "flex flex-col gap-8 border-b last:border-b-0 border-solid pb-8 mb-2",
  borderColorClass.neutral.subtle.enabled
);
interface ProSupplierContractsProps {
  id: number;
  handle: string;
  isVerifiedUser?: boolean;
}
export default function SupplierManageContractsSelfServe({
  id,
  handle,
  isVerifiedUser,
}: ProSupplierContractsProps) {
  const filterBy =
    getParam("filterBy") || (MANAGE_CONTRACTS_FILTER_OPTIONS.ALL as string);
  const [hasLoadedInitial, setHasLoadedInitial] = useState(0);

  const [otherContractsExpanded, setOtherContractsExpanded] = useState(false);
  const [expiredContractsExpanded, setExpiredContractsExpanded] =
    useState(false);

  const {
    list: priorityContracts,
    fetchMore: fetchMorePriorityContracts,
    isLoading: priorityContractsLoading,
    hasNext: hasMorePriorityContracts,
    count: priorityContractsCount,
    refreshList: refreshPriorityContracts,
  } = usePaginatedQuery({
    initialList: [],
    initialPage: 0,
    total: 0,
    fetchList: ({ page }: { page: number }) =>
      ApiService.apiV1SuppliersActivePriorityContractsRetrieve(
        id,
        filterBy,
        page,
        5
      ).catch((err) => handleError(err)),
    onResponse: ({ contracts, count }) => {
      setHasLoadedInitial((prev) => prev + 1);
      return {
        list: contracts,
        count,
      };
    },
  });

  const {
    list: otherContracts,
    fetchMore: fetchMoreOtherContracts,
    isLoading: otherContractsLoading,
    hasNext: hasMoreOtherContracts,
    count: otherContractsCount,
    refreshList: refreshOtherContracts,
  } = usePaginatedQuery({
    initialList: [],
    initialPage: 0,
    total: 0,
    fetchList: ({ page }: { page: number }) =>
      ApiService.apiV1SuppliersActiveNonpriorityContractsRetrieve(
        id,
        filterBy,
        page,
        5
      ).catch((err) => handleError(err)),
    onResponse: ({ contracts, count }) => {
      setHasLoadedInitial((prev) => prev + 1);
      return {
        list: contracts,
        count,
      };
    },
  });

  const {
    list: expiredContracts,
    fetchMore: fetchMoreExpiredContracts,
    isLoading: expiredContractsLoading,
    hasNext: hasMoreExpiredContracts,
    count: expiredContractsCount,
    refreshList: refreshExpiredContracts,
  } = usePaginatedQuery({
    initialList: [],
    initialPage: 0,
    total: 0,
    fetchList: ({ page }: { page: number }) =>
      ApiService.apiV1SuppliersExpiredContractsRetrieve(
        id,
        filterBy,
        page,
        5
      ).catch((err) => handleError(err)),
    onResponse: ({ contracts, count }) => {
      setHasLoadedInitial((prev) => prev + 1);
      return {
        list: contracts,
        count,
      };
    },
  });

  const refreshAllContracts = () => {
    refreshPriorityContracts();
    refreshOtherContracts();
    refreshExpiredContracts();
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: Fetch when the filter clause changes.
  useEffect(() => {
    refreshAllContracts();
  }, [filterBy]);

  const noContractsAvailable =
    !priorityContractsCount && !otherContractsCount && !expiredContractsCount;

  if (
    noContractsAvailable &&
    !priorityContractsLoading &&
    !otherContractsLoading &&
    !expiredContractsLoading &&
    hasLoadedInitial >= 3
  ) {
    return <NoContractsInfo />;
  }

  return (
    <div className="flex flex-col gap-6">
      {(!!priorityContractsCount || priorityContractsLoading) && (
        <div className={SECTION_STYLE}>
          <div className="flex flex-col gap-2">
            <Typography
              emphasis
              size="sm"
              variant="headline"
              color="brand.default.secondary.enabled"
            >
              Priority contracts{" "}
              {priorityContractsCount ? `(${priorityContractsCount})` : ""}
            </Typography>
            <Typography size="md" variant="body" className="max-w-[49.5rem]">
              Complete the tasks below to increase search visibility. Your
              Customer Success Manager selected these contracts based on how
              likely they are to perform well. Email{" "}
              <Link
                href="mailto:csm@withpavilion.com"
                underline={false}
                newWindow={false}
                emphasis={false}
              >
                csm@withpavilion.com
              </Link>{" "}
              with questions.
            </Typography>
          </div>
          {!!priorityContractsCount && (
            <SupplierManageContractList
              id={id}
              handle={handle}
              contracts={priorityContracts}
              fetchMore={fetchMorePriorityContracts}
              isLoading={priorityContractsLoading}
              hasNext={hasMorePriorityContracts}
              refreshAllContracts={refreshAllContracts}
              actionFilter={filterBy}
              isVerifiedUser={isVerifiedUser}
            />
          )}
          {!priorityContractsCount && priorityContractsLoading && (
            <LoadingCards className={CONTAINER_STYLE} />
          )}
        </div>
      )}
      {(!!otherContractsCount || otherContractsLoading) && (
        <div className={SECTION_STYLE}>
          <div className="flex flex-col gap-2">
            <div
              className="flex flex-row gap-2 cursor-pointer"
              onClick={() => setOtherContractsExpanded(!otherContractsExpanded)}
            >
              <Typography
                emphasis
                size="sm"
                variant="headline"
                color="brand.default.secondary.enabled"
              >
                Other contracts{" "}
                {otherContractsCount ? `(${otherContractsCount})` : ""}
              </Typography>
              {otherContractsExpanded ? (
                <ExpandLessRoundedIcon
                  className={iconColorClass.brand.bold.enabled}
                />
              ) : (
                <ExpandMoreRoundedIcon
                  className={iconColorClass.brand.bold.enabled}
                />
              )}
            </div>
            {otherContractsExpanded && (
              <Typography size="md" variant="body" className="max-w-[49.5rem]">
                See your active contracts below. Email{" "}
                <Link
                  href="mailto:csm@withpavilion.com"
                  underline={false}
                  newWindow={false}
                  emphasis={false}
                >
                  csm@withpavilion.com
                </Link>{" "}
                to prioritize contracts to edit.
              </Typography>
            )}
            {!otherContractsCount && otherContractsLoading && (
              <LoadingCards className={CONTAINER_STYLE} />
            )}
          </div>
          {!!otherContractsCount && otherContractsExpanded && (
            <SupplierManageContractList
              id={id}
              handle={handle}
              contracts={otherContracts}
              fetchMore={fetchMoreOtherContracts}
              isLoading={otherContractsLoading}
              hasNext={hasMoreOtherContracts}
              refreshAllContracts={refreshAllContracts}
              isVerifiedUser={isVerifiedUser}
              showActions={true}
            />
          )}
        </div>
      )}
      {(!!expiredContractsCount || expiredContractsLoading) && (
        <div className={SECTION_STYLE}>
          <div className="flex flex-col gap-2">
            <div
              className="flex flex-row gap-2 cursor-pointer"
              onClick={() =>
                setExpiredContractsExpanded(!expiredContractsExpanded)
              }
            >
              <Typography
                emphasis
                size="sm"
                variant="headline"
                color="brand.default.secondary.enabled"
              >
                Expired contracts{" "}
                {expiredContractsCount ? `(${expiredContractsCount})` : ""}
              </Typography>
              {expiredContractsExpanded ? (
                <ExpandLessRoundedIcon
                  className={iconColorClass.brand.bold.enabled}
                />
              ) : (
                <ExpandMoreRoundedIcon
                  className={iconColorClass.brand.bold.enabled}
                />
              )}
            </div>
            {expiredContractsExpanded && (
              <Typography size="md" variant="body" className="max-w-[49.5rem]">
                Expired contracts don't appear in search results. Upload a
                renewal letter to activate.
              </Typography>
            )}
          </div>
          {!expiredContractsCount && expiredContractsLoading && (
            <LoadingCards className={CONTAINER_STYLE} />
          )}
          {!!expiredContractsCount && expiredContractsExpanded && (
            <SupplierManageContractList
              id={id}
              handle={handle}
              contracts={expiredContracts}
              fetchMore={fetchMoreExpiredContracts}
              isLoading={expiredContractsLoading}
              hasNext={hasMoreExpiredContracts}
              refreshAllContracts={refreshAllContracts}
              isVerifiedUser={isVerifiedUser}
              showActions={true}
            />
          )}
        </div>
      )}
    </div>
  );
}
