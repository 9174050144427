import { useEffect, useState } from "react";

import clsx from "clsx";
import { useAtom } from "jotai";
import {
  type ApiError,
  ApiService,
  type BuyerLeadAgency,
  type PublicAgenciesCompletionStatus,
  type StateCodeEnum,
  SupplierEditRequestTypeEnum,
} from "../../../../generated";
import useShowModal from "../../../../hooks/useShowModal";
import { supplierState } from "../../../../jotai/user";
import { ActionMenu, Table, Typography } from "../../../../library";
import { ErrorPopup } from "../../../../popups/AnimatedPopup";
import ClarityCallout from "../../../../shared/ClarityCallout";
import { modals } from "../../../../utils/enums";
import { handleError } from "../../../../utils/generatedApi";
import {
  trackSupplierAddBLARelationship,
  trackSupplierRemoveBLARelationship,
} from "../../../../utils/tracking";
import BLAAutocompleteInput from "../../../BLAAutocompleteInput";
import StatusChip from "../StatusChip";
import SupplierLockedField from "../SupplierLockedField";
import { type PublicEntity, StatusType } from "../types";

export default function SupplierPublicAgencies({
  supplierId,
  handle,
  publicEntitiesServed,
  disabled = true,
  completed,
  setCompletionStatus,
}: {
  supplierId: number;
  handle: string;
  publicEntitiesServed: PublicEntity[];
  disabled: boolean;
  completed: boolean;
  setCompletionStatus: (status: PublicAgenciesCompletionStatus) => void;
}) {
  const [supplier, setSupplier] = useAtom(supplierState);
  const [entityRelationships, setEntityRelationships] = useState<
    {
      id: number | null;
      agencyName: string;
      agencyState: Maybe<StateCodeEnum>;
      agencyTypeLabel: string;
      fromContract: boolean;
    }[]
  >([]);
  const [error, setError] = useState<string | null>(null);
  const status = disabled
    ? StatusType.LOCKED
    : completed
      ? StatusType.COMPLETE
      : StatusType.INCOMPLETE;

  const showModal = useShowModal(modals.CREATE_UNVERIFIED_BLA);

  useEffect(() => {
    if (disabled) return;

    const relationships = supplier.manualAgencyRelationships?.map(
      ({ id, buyerLeadAgency }) => {
        return {
          id,
          agencyName: buyerLeadAgency?.displayName || "",
          agencyState: buyerLeadAgency?.stateCode,
          agencyTypeLabel: buyerLeadAgency?.agencyTypeLabel || "",
          fromContract: false,
        };
      }
    );
    const agenciesFromContract = publicEntitiesServed.map(
      ({ agencyName, agencyState, agencyTypeLabel }) => {
        return {
          id: null,
          agencyName,
          agencyState,
          agencyTypeLabel,
          fromContract: true,
        };
      }
    );
    setEntityRelationships([...(relationships || []), ...agenciesFromContract]);
  }, [disabled, supplier, publicEntitiesServed]);

  const createRelationship = async (
    agency: BuyerLeadAgency
  ): Promise<boolean> => {
    const trackData = {
      supplierHandle: handle,
      agencyId: agency.id,
    };

    try {
      const response =
        await ApiService.apiV1SupplierEditBuyerLeadAgenciesCreate(handle, {
          buyerLeadAgencyId: agency.id,
        });
      setSupplier((prevState) => ({
        ...prevState,
        manualAgencyRelationships: [
          response,
          ...(prevState.manualAgencyRelationships || []),
        ],
      }));
      trackSupplierAddBLARelationship(trackData);
      return true;
    } catch (err) {
      handleError(err);
      setError("Unable to add public entity. Please try again.");
      trackSupplierAddBLARelationship({
        ...trackData,
        error: (err as ApiError).message,
      });
      return false;
    }
  };

  const onChange = async (
    name: string | null,
    agency: BuyerLeadAgency | null,
    { clear, selected }: { clear: () => void; selected: boolean }
  ) => {
    if (agency) {
      const success = await createRelationship(agency);
      if (success) {
        try {
          const newCompletionStatus =
            await ApiService.apiV1SuppliersPublicAgenciesCompletionStatusRetrieve(
              supplierId
            );
          setCompletionStatus(newCompletionStatus);
        } catch (err) {
          handleError(err);
        }
        clear();
      }
      return;
    }

    if (!name || !selected) return;

    showModal({
      initialName: name,
      // The modal will handle creating the relationship if a BLA is created successfully.
      onAgencyCreated: async (agency: Maybe<BuyerLeadAgency>) => {
        if (!agency) {
          setError("Unable to add public entity. Please try again.");
          return;
        }

        const success = await createRelationship(agency);
        if (success) {
          const displayName =
            agency.name + (agency.stateCode ? `, ${agency.stateCode}` : "");
          await ApiService.apiV1SuppliersEditRequestCreate(supplierId, {
            supplierEditRequestType:
              SupplierEditRequestTypeEnum.UNVERIFIED_AGENCY_ADD,
            data: [displayName],
          }).catch(handleError);
          clear();
        }
      },
    });
  };

  const onDelete = async (
    id: number,
    agencyName: string,
    agencyState: Maybe<StateCodeEnum>,
    agencyTypeLabel: string
  ) => {
    const trackData = {
      supplierHandle: handle,
      relationshipId: id,
    };

    try {
      await ApiService.apiV1SupplierEditBuyerLeadAgenciesDestroy(
        id,
        handle
      ).catch(handleError);
      if (agencyTypeLabel === "Pending Review") {
        const displayName =
          agencyName + (agencyState ? `, ${agencyState}` : "");
        await ApiService.apiV1SuppliersEditRequestCreate(supplierId, {
          supplierEditRequestType:
            SupplierEditRequestTypeEnum.UNVERIFIED_AGENCY_REMOVE,
          data: [displayName],
        }).catch(handleError);
      }
      setSupplier((prevState) => ({
        ...prevState,
        manualAgencyRelationships: [
          ...(prevState.manualAgencyRelationships || []).filter(
            (entity) => entity.id !== id
          ),
        ],
      }));
      trackSupplierRemoveBLARelationship(trackData);
      const newCompletionStatus =
        await ApiService.apiV1SuppliersPublicAgenciesCompletionStatusRetrieve(
          supplierId
        );
      setCompletionStatus(newCompletionStatus);
    } catch (err) {
      handleError(err);
      setError("Unable to remove public entity. Please try again.");
      trackSupplierRemoveBLARelationship({
        ...trackData,
        error: (err as ApiError).message,
      });
    }
  };

  return (
    <div className="grid gap-6">
      <ErrorPopup show={!!error} onClose={() => setError(null)}>
        {error}
      </ErrorPopup>
      <div className="grid gap-2">
        <div className="flex gap-2">
          <Typography
            variant="headline"
            size="sm"
            emphasis
            color="brand.boldest.enabled"
          >
            Public sector business
          </Typography>
          <StatusChip status={status} />
        </div>
        <Typography color="neutral.bolder.enabled">
          Based on your contracts, we have created a preliminary list of public
          sector customers you've served. Please add additional public sector
          customers, past or present.
        </Typography>
        <Typography color="neutral.bolder.enabled">
          Tips: We recommend adding a mix of entities across different states
          and entity types that you are hoping to sell to. We recommend several
          entities per entity type (cities, counties, K-12, higher ed, transit)
          spread across different locations.
        </Typography>
      </div>
      <SupplierLockedField disabled={disabled}>
        <ClarityCallout
          callouts={[
            {
              title: "What value does past public sector business add?",
              description:
                "Seeing your experience with relevant customers helps public sector buyers trust you. Unverified buyers and other suppliers are unable to see this list.",
            },
          ]}
        />
        <div className="relative">
          <BLAAutocompleteInput onChange={onChange} label="Add public entity" />
        </div>
      </SupplierLockedField>
      {!!entityRelationships.length && (
        <Table
          columns={[
            {
              key: "agencyName",
              label: "Entity",
              isSortable: true,
              render: (v, { fromContract }) => (
                <div
                  className={clsx("flex flex-col justify-center gap-1", {
                    "min-h-[32px]": !fromContract,
                  })}
                >
                  <Typography color="neutral.bolder.enabled">{v}</Typography>
                  {fromContract && (
                    <Typography variant="meta" color="neutral.bold.enabled">
                      Added from contract
                    </Typography>
                  )}
                </div>
              ),
            },
            {
              key: "agencyState",
              label: "State",
              isSortable: true,
              render: (v, { fromContract }) => (
                <div
                  className={clsx("flex flex-col justify-center gap-1", {
                    "min-h-[32px]": !fromContract,
                  })}
                >
                  <Typography color="neutral.bolder.enabled">
                    {v || "Unknown"}
                  </Typography>
                </div>
              ),
            },
            {
              key: "agencyTypeLabel",
              label: "Entity type",
              isSortable: true,
              render: (v, { fromContract }) => {
                if (!v) return null;
                const initialLabel = v.toString();
                const label =
                  initialLabel.charAt(0).toUpperCase() +
                  initialLabel.slice(1).toLowerCase();
                return (
                  <Typography
                    className={clsx("flex items-center", {
                      "min-h-[32px]": !fromContract,
                    })}
                    color="neutral.bolder.enabled"
                  >
                    {label}
                  </Typography>
                );
              },
            },
            {
              key: "fromContract",
              label: null,
              isSortable: true,
              render: (
                v,
                { id, agencyName, agencyState, agencyTypeLabel, fromContract }
              ) =>
                id &&
                !fromContract &&
                !disabled && (
                  <ActionMenu
                    align="right"
                    buttonClassName="analytics-public-agency-item-menu"
                    ariaLabel={`Menu for ${v}`}
                    items={[
                      {
                        analyticsClassName:
                          "analytics-public-agency-item-delete",
                        text: "Remove agency",
                        color: "destructive.default.primary.enabled",
                        onClick: () => {
                          onDelete(
                            id,
                            agencyName,
                            agencyState,
                            agencyTypeLabel
                          );
                        },
                      },
                    ]}
                  />
                ),
            },
          ]}
          data={entityRelationships}
          defaultSort={{ key: "fromContract", descending: false }}
        />
      )}
    </div>
  );
}
