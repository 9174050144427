import { Fragment, type ReactNode, useEffect } from "react";

import { Link, PageSection, Typography } from "../../library";
import { BLACard } from "../../shared/BLACard";
import { SUPPORT_EMAIL_ADDRESS } from "../../utils/constants";
import { generateHrefForEmail } from "../../utils/format";
import { trackHeapEvent } from "../../utils/tracking";

import type { ContractSearchAgency } from "../../generated";
import useTrackSerpClick from "../../hooks/useTrackSerpClick";
import { ViewContractRankCTA } from "../../utils/enums";

interface GovAgencyMatchHeaderTitleProps {
  agency: ContractSearchAgency;
}

function GovAgencyMatchHeaderTitle({ agency }: GovAgencyMatchHeaderTitleProps) {
  let title: ReactNode;
  let subtitle: ReactNode;
  if (agency.numContracts === 0) {
    title = agency.name;
    subtitle = (
      <div>
        We don’t currently have active usable contracts from the {agency.name}.
        Contact{" "}
        <Link
          href={generateHrefForEmail(SUPPORT_EMAIL_ADDRESS)}
          underline={false}
          newWindow={false}
          emphasis={false}
        >
          {SUPPORT_EMAIL_ADDRESS}
        </Link>{" "}
        to request contracts from this source or others.
      </div>
    );
  } else {
    title = `Active ${agency.name} contracts available`;
    subtitle = `Find and use active shareable contracts from ${agency.name} today.`;
  }

  return (
    <>
      <Typography
        variant="headline"
        size="md"
        color="brand.bold"
        className="mb-2"
      >
        {title}
      </Typography>
      <Typography variant="body" color="subtle" className="mt-4 mb-8">
        {subtitle}
      </Typography>
    </>
  );
}

interface GovAgencyMatchContentProps {
  agency: ContractSearchAgency;
  relatedAgencies: ContractSearchAgency[];
  requestID?: string;
}

function GovAgencyMatchContent({
  agency,
  relatedAgencies,
  requestID,
}: GovAgencyMatchContentProps) {
  const trackSerpClick = useTrackSerpClick();
  const handleClick = () => {
    trackSerpClick({
      contractId: null,
      requestID,
      buyerLeadAgencyId: agency.id,
      buyerLeadAgency: agency.name,
      ctaType: ViewContractRankCTA.VIEW_BLA,
    });
  };

  if (agency.isCoop) {
    return (
      <div className="col-span-full">
        <Typography variant="headline" color="brand.bold" className="mb-2">
          Active {agency.name} contracts available
        </Typography>
        <Typography variant="body" color="subtle" className="mt-4 mb-8">
          Search across thousands of shareable contracts from {agency.name} and
          many other national, state, and regional cooperatives all in one
          place.
        </Typography>
        <BLACard
          agency={{
            id: agency.id,
            name: agency.name,
            entityType: agency.entityType,
            url: agency.url,
            numContracts: agency.numContracts,
            isCoop: agency.isCoop,
            isAgency: agency.isAgency,
            membershipType: agency.membershipType,
          }}
          key={agency.name}
          showButton={!!agency.url}
          requestID={requestID}
          trackSerpClick={handleClick}
        />
      </div>
    );
  }

  return (
    <Fragment>
      <div className="col-start-1 col-span-1">
        <GovAgencyMatchHeaderTitle agency={agency} />
        <BLACard
          agency={{
            id: agency.id,
            name: agency.name,
            entityType: agency.entityType,
            url: agency.url,
            numContracts: agency.numContracts,
            isCoop: agency.isCoop,
            isAgency: agency.isAgency,
          }}
          key={agency.name}
          showButton={!!agency.url}
          requestID={requestID}
        />
      </div>
      <div className="mt-6 lg:mt-0 lg:col-start-2 col-span-1">
        {relatedAgencies.length > 0 && (
          <div className="float-right w-full lg:w-[400px]">
            <Typography
              variant="headline"
              size="sm"
              color="brand.bold"
              className="mb-4"
            >
              Nearby public entities
            </Typography>
            {relatedAgencies.map((related_agency) => {
              return (
                <BLACard
                  agency={{
                    id: related_agency.id,
                    name: related_agency.name,
                    entityType: related_agency.entityType,
                    url: related_agency.url,
                    numContracts: related_agency.numContracts,
                    isCoop: related_agency.isCoop,
                    isAgency: related_agency.isAgency,
                  }}
                  key={related_agency.name}
                  showButton={false}
                  showType={false}
                  className="mb-2"
                  trackSerpClick={handleClick}
                />
              );
            })}
          </div>
        )}
      </div>
    </Fragment>
  );
}

interface GovAgencyMatchProps {
  agency?: ContractSearchAgency;
  relatedAgencies: ContractSearchAgency[];
  requestID?: string;
}

export default function GovAgencyMatch({
  agency,
  relatedAgencies,
  requestID,
}: GovAgencyMatchProps) {
  if (!agency) {
    return null;
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run only once when the header is shown.
  useEffect(() => {
    trackHeapEvent("bla-match-header-shown", {
      agency: agency.name,
    });
  }, []);
  return (
    <PageSection className="bg-cp-violet-100 rounded-2xl mt-6">
      <div className="grid grid-cols-1 lg:grid-cols-2 py-10">
        <GovAgencyMatchContent
          agency={agency}
          relatedAgencies={relatedAgencies}
          requestID={requestID}
        />
      </div>
    </PageSection>
  );
}
