import useIsDebug from "../../../../../hooks/useIsDebug";
import { expirationText, getTags } from "../../../../../shared/ContractBase";
import type {
  Contract,
  ContractWithTags,
} from "../../../../../shared/ContractBase/types";
import ViewMoreButton from "../../../../../shared/ViewMoreButton";
import { getParam, goToURL } from "../../../../../utils";
import { isDateInPast } from "../../../../../utils/date";
import { pageNavigationSourceTypes } from "../../../../../utils/enums";
import { isFeatureEnabled } from "../../../../../utils/split";
import { trackViewContractFromSupplierProfile } from "../../../../../utils/tracking";
import ContractCard from "../../../../ContractSearch/ContractCard";
import ManageContractActions from "../../../SupplierEditForm/SupplierEditSection/SupplierManageContracts/ManageContractActions";
import ContractOptimizationTasks from "./ContractOptimizationTasks";

interface SupplierManageContractListProps {
  id: number;
  handle: string;
  contracts: ContractWithTags[];
  fetchMore: () => void;
  hasNext: boolean;
  isLoading: boolean;
  showActions?: boolean;
  refreshAllContracts: () => void;
  actionFilter?: string;
  isVerifiedUser?: boolean;
}

export default function SupplierManageContractList({
  id,
  handle,
  contracts,
  fetchMore,
  hasNext,
  isLoading,
  showActions = true,
  actionFilter,
  refreshAllContracts,
  isVerifiedUser,
}: SupplierManageContractListProps) {
  function contractOnClick(
    { url, docid, numDocs }: Contract,
    rank: number,
    query: Maybe<string>
  ) {
    goToURL(url, {
      pageNavigationSource: pageNavigationSourceTypes.SUPPLIER_EDIT_PAGE,
      query,
    });
    trackViewContractFromSupplierProfile({
      rank,
      contractId: docid,
      supplierId: id,
      supplierHandle: handle,
      numDocs: numDocs || 0,
    });
  }

  const isDebug = useIsDebug();

  return (
    <div className="flex flex-col gap-6 w-auto">
      {contracts.map((contract, ix) => {
        const {
          docid,
          title,
          buyerLeadAgency,
          expirationDate,
          expirationTimestamp,
          contractNumber,
          cooperativeLanguage,
          cooperativeAffiliation,
          contractDocumentData,
          contractEditRequestData,
        } = contract.contract;

        const { contractTagElements: contractTags } = getTags({
          isCooperative: cooperativeLanguage,
          expiration_ts: expirationTimestamp,
          expiration_date: expirationDate,
          contractDocumentData,
          isDebug,
          contractTagData: contract.contractTags,
        });

        const isExpired = isDateInPast(expirationDate);
        const query = getParam("query");

        return (
          <div
            key={docid}
            className="flex flex-col gap-x-6 gap-y-6 lg:flex-row mb-12 lg:mb-0"
          >
            <ContractCard
              className="w-full"
              onClick={() => contractOnClick(contract.contract, ix + 1, query)}
              title={title}
              contractTags={contractTags}
              contractNumber={contractNumber || "No contract #"}
              expiration={expirationText(expirationDate, {
                short: true,
              })}
              isExpired={isExpired}
              numPendingDocs={contractEditRequestData?.numPendingDocs}
              buyerLeadAgency={buyerLeadAgency}
              cooperative={cooperativeAffiliation}
              showEditContractCta={
                isVerifiedUser && isFeatureEnabled("proSelfServeOnboardingM2")
              }
            />
            {showActions &&
            contractEditRequestData &&
            contractDocumentData &&
            isFeatureEnabled("proSelfServeOnboardingM2") ? (
              <ContractOptimizationTasks
                optimizationStatus={contract.optimizationStatus}
              />
            ) : (
              showActions &&
              contractEditRequestData &&
              contractDocumentData && (
                <ManageContractActions
                  contract={contract.contract}
                  contractDocumentData={contractDocumentData}
                  contractEditRequestData={contractEditRequestData}
                  supplierId={id}
                  refreshAllContracts={refreshAllContracts}
                  actionFilter={actionFilter}
                />
              )
            )}
          </div>
        );
      })}
      {hasNext && (
        <ViewMoreButton
          onClick={fetchMore}
          isLoading={isLoading}
          className="mx-auto"
        />
      )}
    </div>
  );
}
