import { useAtom, useAtomValue } from "jotai";
import {
  type ChangeEvent,
  type KeyboardEvent,
  type MouseEvent,
  useEffect,
  useState,
} from "react";

import useCookie from "../../hooks/useCookie";
import {
  userBonfireDetailsState,
  userDetailsState,
  userInitializedState,
  userIonwaveDetailsState,
  userTypeSignupState,
} from "../../jotai/user";
import {
  LabeledInput,
  LabeledInputVariants,
  Link,
  Typography,
} from "../../library";
import { ToggleTabs } from "../../library/ToggleTabs";
import { getParams, validateEmail } from "../../utils";
import {
  EmailErrorMessages,
  SIGNUP_ORIGIN_COOKIE_KEY,
} from "../../utils/constants";
import {
  LoginType,
  SignupOrigin,
  accountModals,
  loginSignupAccountTypes,
  loginSignupSteps,
  modalTriggerURLParams,
} from "../../utils/enums";
import { SocialProvider } from "../../utils/social";
import {
  trackInitialSignup,
  trackSignupFlowFailure,
  trackUserTypeToggle,
} from "../../utils/tracking";

import {
  AccountsService,
  type CheckUserRequest,
  type ExistingUser,
} from "../../generated";
import { handleError } from "../../utils/generatedApi";
import SocialLoginButton from "./SocialLoginButton";
import SubmitButton from "./SubmitButton";
import { type UserTypeTab, userTypeTabData } from "./constants";
import type { WindowType } from "./types";

interface InitialSignupFormProps {
  goToLoginPageOrModal: () => void;
  onComplete: (redirectUrl: string) => void;
  checkUserAndSetNextModalOrPage: (data: ExistingUser) => void;
  parentWindow: WindowType;
  origin?: SignupOrigin;
  hideUserTypeToggle?: boolean;
}

export default function InitialSignupForm({
  onComplete,
  goToLoginPageOrModal,
  checkUserAndSetNextModalOrPage,
  parentWindow,
  origin,
  hideUserTypeToggle = false,
}: InitialSignupFormProps) {
  const [errorMessage, setErrorMessage] = useState("");
  const [signupOrigin, setSignupOrigin] = useCookie<SignupOrigin>(
    SIGNUP_ORIGIN_COOKIE_KEY,
    SignupOrigin.UNKNOWN
  );
  const [isCTALoading, setIsCTALoading] = useState(false);
  const [{ email, ...userDetails }, setUserDetails] = useAtom(userDetailsState);
  const userInitialized = useAtomValue(userInitializedState);
  const [userType, setUserType] = useAtom(userTypeSignupState);
  const [userTypeTab, setUserTypeTab] = useState<UserTypeTab>(
    userTypeTabData[userType]
  );
  const [isSupplier, setIsSupplier] = useState(
    userTypeTab.label === userTypeTabData.supplier.label
  );
  const bonfireDetails = useAtomValue(userBonfireDetailsState);
  const ionwaveDetails = useAtomValue(userIonwaveDetailsState);

  useEffect(() => {
    setIsSupplier(userTypeTab.label === userTypeTabData.supplier.label);
  }, [userTypeTab]);

  useEffect(() => {
    setUserTypeTab(userTypeTabData[userType]);
  }, [userType]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:  Run once on mount
  useEffect(() => {
    const urlParams = getParams();

    const supplierSignUpParam =
      urlParams[modalTriggerURLParams.SUPPLIER_LOGIN] === "true";
    // set initial user type via url params
    if (supplierSignUpParam) {
      setUserType(userTypeTabData.supplier.type);
    }
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies:  Run once when the user is initialized.
  useEffect(() => {
    // Prefill the email if it is in the params, only after we have initialized the user
    // Otherwise, initializeUserCallback will fill the email again with an undefined value
    const urlParams = getParams();
    const invite = urlParams[modalTriggerURLParams.INVITE] === "true";
    const eunaEmail = bonfireDetails.email || ionwaveDetails.email;
    const prefilledEmail = (urlParams.email || eunaEmail) as string;
    if (userInitialized && (invite || prefilledEmail)) {
      setUserDetails({
        ...userDetails,
        email: prefilledEmail,
      });
    }

    if (invite) {
      setSignupOrigin(SignupOrigin.PAVILION_INVITE_LINK);
    } else if (urlParams.teamInvite === "True") {
      setSignupOrigin(SignupOrigin.REFERRAL_INVITE_YOUR_TEAM);
    } else if (
      Object.values(SignupOrigin).includes(urlParams.origin as SignupOrigin)
    ) {
      setSignupOrigin(urlParams.origin as SignupOrigin);
    } else if (origin) {
      setSignupOrigin(origin);
    }
  }, [userInitialized]);
  function onSelectTab(index?: number) {
    if (index === userTypeTabData.supplier.index) {
      setUserTypeTab(userTypeTabData.supplier);
      setUserType(userTypeTabData.supplier.type);
    } else if (index === userTypeTabData.buyer.index) {
      setUserTypeTab(userTypeTabData.buyer);
      setUserType(userTypeTabData.buyer.type);
    }
    trackUserTypeToggle({
      modalOrPageShown: accountModals.INITIAL_SIGN_UP,
      toggle: userType,
      loginExperience: parentWindow,
    });
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setUserDetails({ ...userDetails, email: e.target.value });
    setErrorMessage("");
  }

  const handleSubmit = async (e?: KeyboardEvent | MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    if (!email || !validateEmail(email, true)) {
      trackSignupFlowFailure({
        emailEntered: email,
        loginType: LoginType.PAVILION,
        signupStep: loginSignupSteps.SIGNUP,
        error: "Invalid email",
        accountType: isSupplier
          ? loginSignupAccountTypes.SUPPLIER
          : loginSignupAccountTypes.BUYER,
        loginExperience: parentWindow,
      });
      setErrorMessage(EmailErrorMessages.INVALID);
      return;
    }
    setIsCTALoading(true);
    const emailData: CheckUserRequest = { email };
    try {
      const response =
        await AccountsService.accountsCheckExistingUserCreate(emailData);
      setIsCTALoading(false);
      trackInitialSignup({
        emailEntered: email,
        accountType: userType,
        loginType: LoginType.PAVILION,
        pushToLogin: response.existingUser,
        loginExperience: parentWindow,
        signupOrigin,
      });
      checkUserAndSetNextModalOrPage(response);
    } catch (err) {
      handleError(err);
      setIsCTALoading(false);
      trackSignupFlowFailure({
        emailEntered: email,
        accountType: userType,
        signupStep: loginSignupSteps.SIGNUP,
        loginType: LoginType.PAVILION,
        pushToLogin: false,
        error: "Check existing user error",
        loginExperience: parentWindow,
      });
    }
  };
  return (
    // Ensure spacing between modal header, tabs and email input is the same
    <div className="flex flex-col gap-8">
      {!hideUserTypeToggle && (
        <ToggleTabs
          onSelectTab={onSelectTab}
          defaultTabIndex={userTypeTab.index}
          className="w-full min-w-[275px]"
        >
          <div label={userTypeTabData.buyer.label} />
          <div label={userTypeTabData.supplier.label} />
        </ToggleTabs>
      )}
      <div className="w-full flex flex-col gap-4">
        <LabeledInput
          type="email"
          name="email"
          label="Work email address"
          placeholder={userTypeTab.placeholder}
          required
          value={email || ""}
          onChange={handleChange}
          size="md"
          autoComplete="email"
          className="analytics-email-signup-modal-email-input w-full"
          dataTestId="email-input"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit(e);
            }
          }}
          initialVariant={
            errorMessage
              ? LabeledInputVariants.ERROR
              : LabeledInputVariants.DEFAULT
          }
          message={errorMessage}
          autoFocus
        />
        <div>
          <SubmitButton
            isCTALoading={isCTALoading}
            handleSubmit={handleSubmit}
            ctaText="Sign up"
            btnClassName="analytics-email-signup-modal-cta"
            dataTestId="email-signup-button"
          />
        </div>
        <div className="flex flex-col-reverse gap-4">
          <SocialLoginButton
            className="analytics-email-signup-modal-microsoft button-exists peer"
            provider={SocialProvider.microsoft}
            onComplete={onComplete}
            ctaTextPrefix="Sign up with"
            parentWindow={parentWindow}
          />
          <SocialLoginButton
            className="analytics-email-signup-modal-google button-exists peer"
            provider={SocialProvider.google}
            onComplete={onComplete}
            ctaTextPrefix="Sign up with"
            parentWindow={parentWindow}
          />
          {/* Conditionally render the --or-- section, only if at least one of the social buttons exists */}
          <div className="hidden peer-[.button-exists]:flex text-center items-center w-full before:bg-cp-lapis-100 before:flex-1 before:h-px after:bg-cp-lapis-100 after:flex-1 after:h-px">
            <Typography className="mx-4" color="subtle">
              or
            </Typography>
          </div>
        </div>
      </div>
      <Typography size="sm" variant="body">
        Already have an account?{" "}
        <Link
          size="sm"
          variant="body"
          emphasis={false}
          onClick={goToLoginPageOrModal}
          newWindow={false}
          data-testid="select-login"
        >
          Log in
        </Link>
      </Typography>
    </div>
  );
}
