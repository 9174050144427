import type { FormikHelpers } from "formik";
import type { ElementType, ReactNode } from "react";

import type {
  StateCodeEnum,
  SupplierAlias,
  SupplierBuyerLeadAgency,
  UserSupplierContact,
} from "../../../generated";
import type {
  GoogleAutoCompleteAddress,
  Validate,
} from "../../../library/form/types";
import type { Callout } from "../../../shared/ClarityCallout";
import type { SupplierBusinessTypes } from "../../../utils/enums";
export enum StatusType {
  LOCKED = "locked",
  INCOMPLETE = "incomplete",
  COMPLETE = "complete",
}

export interface Supplier {
  id: number;
  handle: string;
  displayName: string;
  website?: string;
  about?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressCity?: string;
  addressStateCode?: string;
  addressCountryCode?: string;
  addressZip?: string;
  additionalLocations?: SupplierLocation[];
  supplierEmail?: string;
  manualServiceAreaNational?: boolean;
  manualServiceAreaState?: string[];
  manualAgencyRelationships: SupplierBuyerLeadAgency[];
  contacts?: UserSupplierContact[];
  aliases?: SupplierAlias[];
  updatedAt?: string;
  hasVerifiedSupplierUser: boolean;
  activeAgreements: string[];
  isApproved: boolean;
  einNumber?: string;
  businessTypes?: SupplierBusinessTypes[];
  publicEntitiesServed?: PublicEntity[];
  logoUrl?: string;
  defaultContactId?: number | null;
}

export interface PublicEntity {
  agencyName: string;
  agencyState: Maybe<StateCodeEnum>;
  agencyTypeLabel: string;
}

export interface CustomFieldProps<T extends object> {
  name: keyof T;
  label?: string;
  size?: "xl" | "lg" | "sm";
  sublabel?: string | ReactNode;
  placeholder?: string;
  validate?: Validate;
  component: ElementType;
  className?: string;
  dataTestId?: string;
  schema?: Record<keyof T, Validate>;
  validationIcons?: boolean;
}

export interface SupplierEditForm<T extends object> {
  initialValues: T;
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => Promise<boolean>;
  fields: CustomFieldProps<T>[];
  disabled?: boolean;
  savedFields?: string[];
  callouts?: Callout[];
  locked?: boolean;
  status?: StatusType;
}

export interface SupplierAddressValues {
  address: GoogleAutoCompleteAddress;
}

export interface SupplierNameValues {
  displayName: Maybe<string>;
}

export interface SupplierEinValues {
  einNumber: Maybe<string>;
}

export interface SupplierWebsiteValues {
  website: Maybe<string>;
}

export interface SupplierBusinessCategoryValues {
  businessTypes: Maybe<SupplierBusinessTypes[]>;
}

export interface SupplierAboutValues {
  about: Maybe<string>;
}
export interface SupplierCompetitorValues {
  competitors: Maybe<string>;
}

export interface SupplierContractSummaryValues {
  summary: Maybe<string>;
}

export interface SupplierContractBrandValues {
  brands: Maybe<string>;
}

export interface SupplierProductListValues {
  productListNames: string[];
  isUpload: boolean;
}

export interface SupplierConfirmationValues {
  confirmedAllContracts: Maybe<boolean>;
}

export interface SupplierExclusionConfirmationValues {
  confirmedNoExclusions: Maybe<boolean>;
}

export interface SupplierServiceAreaValues {
  serviceArea: {
    manualServiceAreaNational: boolean;
    manualServiceAreaState: string[];
  };
}

export interface SupplierLocationValues extends SupplierAddressValues {
  locationName?: string;
}

export interface SupplierLocation {
  id: number;
  address: {
    addressLine1: string;
    addressLine2: string;
    addressCity: string;
    addressStateCode: string;
    addressZip: string;
  };
  locationName?: string;
}

export interface SupplierDefaultContactValue {
  defaultContactId: number | null;
}

export type SupplierValues =
  | SupplierAddressValues
  | SupplierNameValues
  | SupplierWebsiteValues
  | SupplierAboutValues
  | SupplierCompetitorValues
  | SupplierProductListValues
  | SupplierServiceAreaValues
  | SupplierEinValues
  | SupplierBusinessCategoryValues
  | SupplierLocationValues
  | SupplierConfirmationValues
  | SupplierExclusionConfirmationValues
  | SupplierDefaultContactValue;
