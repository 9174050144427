import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import { type ComponentType, useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import LabeledInput, {
  LabeledInputVariants,
  type InputSizes,
} from "../Input/LabeledInput";
import Typography from "../Typography";

export interface AutocompleteOption<T extends object> {
  id: string | null;
  label: string;
  Component?: ComponentType<T>;
  disabled?: boolean;
  data: T | null;
}

export type AutocompleteInputOnSelect<T> = (
  value: string,
  selected: T | null,
  callbacks: { clear: () => void; selected: boolean }
) => void;

interface AutocompleteInputProps<T extends object> {
  analyticsClass?: string;
  initialValue?: Maybe<string>;
  initialSelectedId: string | null;
  options: AutocompleteOption<T>[];
  onChange: (value: string) => void;
  onSelect: AutocompleteInputOnSelect<T>;
  name: string;
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  dataTestId?: string;
  // Allow the input to contain arbitrary text values.
  freeSolo?: boolean;
  freeSoloLabel?: string;
  sublabel?: string;
  size?: InputSizes;
}

export default function AutocompleteInput<T extends object>({
  initialValue,
  initialSelectedId,
  options,
  analyticsClass,
  onSelect,
  onChange,
  label,
  sublabel,
  name,
  placeholder,
  errorMessage,
  dataTestId,
  freeSolo = true,
  freeSoloLabel,
  size = "md",
}: AutocompleteInputProps<T>) {
  const [value, setValue] = useState(initialValue || "");
  const [selectedId, setSelectedId] = useState<string | null>(
    initialSelectedId
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Change if the value or selected is modified.
  useEffect(() => {
    const selected = options.find(({ id }) => id === selectedId);
    onSelect(value, selected?.data || null, {
      selected: !!selectedId,
      clear: () => {
        setValue("");
        setSelectedId(null);
      },
    });
  }, [value, selectedId]);

  // Only allow custom values when there is no exact prefix match to avoid
  // confusion when typing out exact values.
  const showCustomValue = useMemo(() => {
    return (
      freeSolo &&
      value &&
      !options.find(({ label }) =>
        label?.toLowerCase().startsWith(value.toLowerCase().trim())
      )
    );
  }, [freeSolo, value, options]);

  return (
    <Combobox
      value={value}
      onChange={(selectedId) => {
        if (!selectedId) return;

        const selected = options.find(({ id }) => id === selectedId);
        setValue(selected?.label || value);
        setSelectedId(selectedId);
      }}
    >
      <ComboboxInput
        as={LabeledInput}
        label={label}
        name={name}
        type="text"
        placeholder={placeholder}
        size={size}
        autoComplete="off"
        className={analyticsClass}
        dataTestId={dataTestId}
        initialVariant={
          errorMessage
            ? LabeledInputVariants.ERROR
            : LabeledInputVariants.DEFAULT
        }
        message={errorMessage || sublabel}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
          setSelectedId(null);
        }}
      />
      {(!!options.length || showCustomValue) && (
        <ComboboxOptions
          className={clsx(
            { "top-[3rem]": !!sublabel },
            "left-0 right-0 absolute z-1 bg-white rounded-xl max-h-[250px] overflow-y-scroll min-w-[300px] border border-solid border-cp-neutral-20 shadow-md"
          )}
          as="ul"
        >
          {options.map(({ id, label, data, disabled, Component }) => (
            <ComboboxOption
              className={clsx("px-6 py-3 ui-active:bg-cp-white-200", {
                "cursor-default pointer-events-none": disabled,
                "cursor-pointer": !disabled,
              })}
              key={id}
              value={id}
              as="li"
            >
              {Component ? (
                <Component {...(data as T)} />
              ) : (
                <Typography>{label}</Typography>
              )}
            </ComboboxOption>
          ))}
          {showCustomValue && (
            <>
              <Typography size="sm" emphasis className="px-6 py-4 uppercase">
                {freeSoloLabel}
              </Typography>
              <ComboboxOption
                className="px-6 py-3 cursor-pointer text-cp-black-100 ui-active:bg-cp-white-200"
                value={"custom"}
                as="li"
              >
                {value}
              </ComboboxOption>
            </>
          )}
        </ComboboxOptions>
      )}
    </Combobox>
  );
}
