import clsx from "clsx";

import fallbackImage from "../../../img/icons/image.svg";
import type { ProductHitDict, ProductService } from "../../generated";
import { Typography } from "../../library";
import ImageWithFallback from "../../library/ImageWithFallback";
import {
  bgColorClass,
  borderColorClass,
  textColorClass,
} from "../../utils/colors";
import { isFeatureEnabled } from "../../utils/split";
import { styleSearchResponseText } from "./utils";

interface ProductSectionProps {
  products: ProductHitDict[];
}

export default function ProductSection({ products }: ProductSectionProps) {
  if (!products.length) {
    return null;
  }

  const hasRedesignedSerpCards = isFeatureEnabled("redesignedSERPCards");

  if (hasRedesignedSerpCards) {
    return <RedesignedSerpCardProductSection products={products} />;
  }

  return (
    <div
      className={clsx("grid gap-4 empty:hidden max-h-[32px] overflow-hidden", {
        "grid-cols-4": products.length >= 4,
        "grid-cols-3": products.length === 3,
        "grid-cols-2": products.length === 2,
        "grid-cols-1": products.length === 1,
      })}
    >
      {products.map((product) => {
        const imageUrl = product.photos?.[0]?.url;
        const itemName = product.productService.itemName;
        let itemText = itemName;
        if (product.highlightResult?.itemName?.length) {
          itemText = product.highlightResult.itemName[0].value;
        }
        return (
          <div
            key={product.productService.id}
            className="flex flex-row gap-1 items-center"
          >
            {imageUrl && (
              <ImageWithFallback
                className="shrink-0 h-8 w-8"
                src={imageUrl}
                srcFallback={fallbackImage}
                alt={itemName}
                title={itemName}
              />
            )}
            <Typography
              variant="meta"
              size="sm"
              className="text-ellipsis whitespace-nowrap overflow-hidden"
              title={itemName}
            >
              {styleSearchResponseText(itemText)}
            </Typography>
          </div>
        );
      })}
    </div>
  );
}

interface RenderableProduct {
  id: string;
  imageUrl: Maybe<string>;
  itemName: string;
  itemText: string;
}

interface RenderableProductWithImage extends RenderableProduct {
  imageUrl: string;
}

function isProductHit(
  product: ProductHitDict | ProductService
): product is ProductHitDict {
  return "productService" in product;
}

function getProductValues(
  product: ProductHitDict | ProductService
): RenderableProduct {
  if (isProductHit(product)) {
    const itemName = product.productService.itemName;
    let itemText = itemName;
    if (product.highlightResult?.itemName?.length) {
      itemText = product.highlightResult.itemName[0].value;
    }

    return {
      id: product.productService.id,
      imageUrl: product.photos?.[0]?.url,
      itemName,
      itemText,
    };
  }
  const itemName = product.itemName;
  let itemText = itemName;
  if (product.highlightResult?.itemName?.length) {
    itemText = product.highlightResult.itemName[0].value;
  }

  return {
    id: product.id,
    imageUrl: product.variants[0].photos?.[0]?.url,
    itemName,
    itemText,
  };
}

export function RedesignedSerpCardProductSection({
  products,
  onClick,
}: {
  products: (ProductHitDict | ProductService)[];
  onClick?: () => void;
}) {
  if (!products.length) {
    return null;
  }
  const productValues = products.map(getProductValues);
  const productValuesWithImages = productValues.filter(
    ({ imageUrl }) => imageUrl
  ) as RenderableProductWithImage[];

  return (
    <div
      className={clsx(
        "flex items-center justify-between gap-3 max-h-16 empty:hidden",
        {
          "cursor-pointer": !!onClick,
        }
      )}
      onClick={onClick}
    >
      <Typography
        size="sm"
        className="text-ellipsis whitespace-wrap"
        color="neutral.bold.enabled"
        title={productValues[0].itemName}
      >
        Confirmed products:{" "}
        {styleSearchResponseText(productValues[0].itemText, {
          className: `${textColorClass.neutral.bold.enabled} font-normal`,
          highlightClassName: `${textColorClass.neutral.bold.enabled} font-semibold`,
        })}{" "}
        + more
      </Typography>
      {productValuesWithImages.length > 0 && (
        <div
          className={clsx("grid gap-1 shrink-0", {
            "grid-cols-3": productValuesWithImages.length >= 3,
            "grid-cols-2": productValuesWithImages.length === 2,
            "grid-cols-1": productValuesWithImages.length === 1,
          })}
        >
          {productValuesWithImages
            .slice(0, 3)
            .map(({ id, imageUrl, itemName }) => (
              <ImageWithFallback
                key={id}
                className={clsx(
                  "shrink-0 h-16 w-[5rem] bg-cover border border-solid rounded-2",
                  borderColorClass.neutral.subtle.enabled
                )}
                src={imageUrl}
                srcFallback={fallbackImage}
                alt={itemName}
                title={itemName}
                border={false}
              />
            ))}
        </div>
      )}
    </div>
  );
}

export function SlimProductSection({
  products,
  onClick,
}: { products: (ProductHitDict | ProductService)[]; onClick?: () => void }) {
  if (!products.length) {
    return null;
  }

  return (
    <div
      className={clsx(
        "flex items-center justify-between gap-4 flex-1 empty:hidden max-h-[72px] p-4 rounded-4",
        bgColorClass.neutral.subtlest.hovered,
        { "cursor-pointer": !!onClick }
      )}
      onClick={onClick}
    >
      <div
        className={clsx("grid", {
          "grid-cols-3": products.length >= 3,
          "grid-cols-2": products.length === 2,
          "grid-cols-1": products.length === 1,
        })}
      >
        {products.slice(0, 3).map((product) => {
          const values = getProductValues(product);
          return (
            <Product
              className={clsx(
                "px-4 border-r only:p-0 first:pl-0 last:pr-0 last:border-0",
                borderColorClass.neutral.subtle.enabled
              )}
              key={values.id}
              product={values}
            />
          );
        })}
      </div>
    </div>
  );
}

function Product({
  product,
  className,
}: { product: RenderableProduct; className: string }) {
  return (
    <div
      key={product.id}
      className={clsx("flex flex-row items-center gap-2 h-10", className)}
    >
      {product.imageUrl && (
        <ImageWithFallback
          className="shrink-0 h-10 w-10"
          src={product.imageUrl}
          srcFallback={fallbackImage}
          alt={product.itemName}
          title={product.itemName}
          border={false}
        />
      )}
      <Typography
        size="sm"
        className="text-ellipsis whitespace-nowrap overflow-hidden"
        color="neutral.boldest.enabled"
        title={product.itemName}
      >
        {styleSearchResponseText(product.itemText)}
      </Typography>
    </div>
  );
}
