import clsx from "clsx";
import type { FormikHelpers, FormikProps, FormikValues } from "formik";
import { useAtomValue } from "jotai";
import { useRef, useState } from "react";

import useShowModal from "../../hooks/useShowModal";
import { userDetailsState } from "../../jotai/user";
import { InlineFormWrapper } from "../../library/form";
import PrivacyPolicyNotice from "../../shared/PrivacyPolicyNotice";
import { goToURL, setParamNoHistory } from "../../utils";
import {
  LoginType,
  accountModals,
  loginSignupAccountTypes,
  loginSignupSteps,
} from "../../utils/enums";
import {
  trackSignupFlowFailure,
  trackSignupSupplierConfirmation,
} from "../../utils/tracking";

import SupplierConfirmationForm from "./SupplierConfirmationForm";
import { BUYER_FIELDS, BUYER_SIGNUP_CONFIRMATION_KEYWORD } from "./constants";
import { type InferredBLA, WindowType } from "./types";
import useSubmitBuyerInfo from "./useSubmitBuyerInfo";

interface CompleteBuyerAccountFormProps {
  hideModal: () => void;
  onComplete: (redirectUrl: string) => void;
  onChangeConfirmation?: (showConfirmation: boolean) => void;
  inferredBLA?: Maybe<InferredBLA>;
  parentWindow: WindowType;
}

export default function CompleteBuyerAccountForm({
  onComplete,
  inferredBLA = null,
  parentWindow,
  onChangeConfirmation,
}: CompleteBuyerAccountFormProps) {
  const {
    firstName,
    lastName,
    email: initialEmail,
  } = useAtomValue(userDetailsState);
  const showSupplierModal = useShowModal(accountModals.SUPPLIER_SIGN_IN);
  const ref = useRef<FormikProps<FormikValues> | null>(null);
  const [confirmationProps, setConfirmationProps] = useState<null | {
    onConfirm: () => void;
    onCancel: () => void;
  }>(null);
  const [hasConfirmed, setHasConfirmed] = useState(false);

  // Store the email before initializeUserCallback fills the initial email with
  // an undefined value
  const [email] = useState(initialEmail);
  const prefilledBLAId = inferredBLA?.id || null;
  const prefilledBLA = inferredBLA?.display_name || "";
  const [loading, submitBuyerInfo] = useSubmitBuyerInfo({
    email,
    onComplete,
    prefilledBLA,
    prefilledBLAId,
    parentWindow,
  });

  const initialBuyerValues = {
    firstName,
    lastName,
    password: "",
    buyerProfile: {
      id: prefilledBLAId,
      governmentAffiliationDisplayName: prefilledBLA,
    },
  };

  function trackInvalidForm(error: Record<string, string>) {
    trackSignupFlowFailure({
      emailEntered: email,
      accountType: loginSignupAccountTypes.BUYER,
      loginType: LoginType.PAVILION,
      signupStep: loginSignupSteps.SIGNUP,
      error: JSON.stringify(error),
      loginExperience: parentWindow,
    });
  }

  async function handleSubmit(
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) {
    // Require the user to confirm whether they are a buyer or supplier
    // if their email ends with .com and their agency name does not include
    // a keyword we've identified being highly correlated to suppliers.
    const shouldConfirm =
      !hasConfirmed &&
      email?.endsWith(".com") &&
      !BUYER_SIGNUP_CONFIRMATION_KEYWORD.some((keyword) =>
        values.buyerProfile.governmentAffiliationDisplayName.includes(keyword)
      );
    if (!shouldConfirm) return submitBuyerInfo(values, formikHelpers);

    onChangeConfirmation?.(true);
    trackSignupSupplierConfirmation({
      emailEntered: email,
      entitySelected: values.buyerProfile.governmentAffiliationDisplayName,
    });
    setConfirmationProps({
      onConfirm: () => {
        if (parentWindow === WindowType.Modal) {
          setParamNoHistory("email", email || "");
          setParamNoHistory("firstName", values.firstName);
          setParamNoHistory("lastName", values.lastName);
          setParamNoHistory(
            "supplierName",
            values.buyerProfile.governmentAffiliationDisplayName
          );
          // Use the raw onComplete function since this modal signs
          // the user up as a supplier. They should not see the buyer
          // onboarding reason.
          showSupplierModal({ onComplete });
          return;
        }

        goToURL(
          "/accounts/signup-supplier",
          {
            email,
            firstName: values.firstName,
            lastName: values.lastName,
            supplierName: values.buyerProfile.governmentAffiliationDisplayName,
          },
          false
        );
      },
      onCancel: () => {
        setConfirmationProps(null);
        setHasConfirmed(true);
        onChangeConfirmation?.(false);
        ref.current?.submitForm();
      },
    });
  }

  return (
    <>
      {confirmationProps && <SupplierConfirmationForm {...confirmationProps} />}
      <div className={clsx({ hidden: !!confirmationProps })}>
        <InlineFormWrapper
          ref={ref}
          fields={BUYER_FIELDS}
          initialValues={initialBuyerValues}
          submitCta="Create your account"
          submitClassName="analytics-buyer-signup-modal-cta"
          onSubmit={handleSubmit}
          disabled={loading}
          trackInvalidForm={trackInvalidForm}
        />
        <PrivacyPolicyNotice className="mt-6" />
      </div>
    </>
  );
}
