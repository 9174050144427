import _keyBy from "lodash/keyBy";
import { useState } from "react";

import { RadioButtonGroup, Typography } from "../../library";
import Modal from "../../shared/Modal/Modal";

import type { SupplierListResponse } from "../../generated";
import { SupplierOption } from "./SupplierSearchInput";

interface SupplierNameConfirmationModalProps {
  hideModal: () => void;
  onComplete: (displayName: string, handle: Maybe<string>) => void;
  suppliers: SupplierListResponse[];
  proposedSupplierName: string;
}

export default function SupplierNameConfirmationModal({
  hideModal,
  suppliers,
  proposedSupplierName,
  onComplete,
}: SupplierNameConfirmationModalProps) {
  const [selectedSupplier, setSelectedSupplier] = useState(
    suppliers[0].supplier.handle || ""
  );

  const handleToSupplier = _keyBy(suppliers, "handle");

  const onSubmit = async () => {
    const supplierName = selectedSupplier
      ? handleToSupplier[selectedSupplier].supplier.displayName
      : proposedSupplierName;
    const supplierHandle = selectedSupplier || null;
    onComplete(supplierName, supplierHandle);
  };

  return (
    <Modal
      hideModal={hideModal}
      isBlocking={false}
      className="analytics-signup-supplier-confirmation-modal"
      title="Verify your business name"
      subtitle="We found a similar supplier name on Pavilion. Please confirm your business to ensure access to accurate data."
      ctaText="Continue"
      ctaClass="analytics-signup-supplier-confirm-name-modal-submit"
      onCtaClick={onSubmit}
      showLogoHeader
    >
      <div className="flex flex-col gap-4 w-3/4 m-auto">
        <RadioButtonGroup
          value={selectedSupplier}
          onChange={(option) => {
            setSelectedSupplier(option);
          }}
          options={[
            ...suppliers.map((supplier) => {
              return {
                key: supplier.supplier.handle || "",
                label: <SupplierOption {...supplier} />,
              };
            }),
          ]}
          labelClassName="mt-0"
        />
        <div className="relative flex py-2 items-center">
          <div className="flex-grow border-t border-solid border-cp-lapis-100" />
          <Typography className="flex-shrink mx-4" size="sm" color="subtler">
            Or create new supplier
          </Typography>
          <div className="flex-grow border-t border-solid border-cp-lapis-100" />
        </div>
        <RadioButtonGroup
          value={selectedSupplier}
          onChange={(option) => {
            setSelectedSupplier(option);
          }}
          options={[
            {
              key: "",
              label: <Typography>{proposedSupplierName}</Typography>,
            },
          ]}
          labelClassName="mt-0"
        />
      </div>
    </Modal>
  );
}
