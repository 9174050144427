import { useAtomValue } from "jotai";

import { useSetAtom } from "jotai";
import {
  approvedSourcesOnlyFilterState,
  contractDocumentsFilterState,
  contractSourceFilterState,
  diversityCertificationsFilterState,
  expirationFilterState,
  singleAwardOnlyFilterState,
  supplierLocationFilterState,
} from "../jotai/searchFilters";
import { isAuthenticatedState, userStateState } from "../jotai/user";
import {
  ContractDocumentsFilterOptions,
  ExpirationStatuses,
  SupplierLocationFilterOptions,
  searchFilters,
} from "../utils/enums";

// The returned callback function does the following:
// Resets all filters stored in Jotai, then returns the list of URL param filters to apply
export default function useResetAndGetFilters(
  ignoreUserDefaults = false
): () => string[] {
  const {
    showDiversityCertifications,
    showExpired,
    showGsa,
    showOnlyApprovedSources,
    showOnlyCoop,
    ignoreAgencyLocation,
    showOnlySingleAward,
    excludeUnusable,
  } = useAtomValue(userStateState) || {};
  const setExpirationFilter = useSetAtom(expirationFilterState);
  const setContractSourceFilter = useSetAtom(contractSourceFilterState);
  const setApprovedSourcesOnlyFilter = useSetAtom(
    approvedSourcesOnlyFilterState
  );
  const setDiversityCertificationFilter = useSetAtom(
    diversityCertificationsFilterState
  );
  const setSingleAwardOnlyFilter = useSetAtom(singleAwardOnlyFilterState);
  const setSupplierLocationFilter = useSetAtom(supplierLocationFilterState);

  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const setContractDocsFilter = useSetAtom(contractDocumentsFilterState);

  if (!isAuthenticated || ignoreUserDefaults) {
    return () => {
      setExpirationFilter(ExpirationStatuses.ALL_ACTIVE);
      setDiversityCertificationFilter(false);
      setSingleAwardOnlyFilter(false);
      setContractSourceFilter([]);
      setSupplierLocationFilter(SupplierLocationFilterOptions.ALL_SUPPLIERS);
      setContractDocsFilter(ContractDocumentsFilterOptions.ALL_CONTRACTS);
      return [];
    };
  }

  const newFilters: string[] = [];
  if (ignoreAgencyLocation) {
    newFilters.push(searchFilters.IS_NOT_LOCATION_RELEVANT);
  }
  if (showExpired) {
    newFilters.push(searchFilters.INCLUDE_EXPIRED);
  }
  if (!showGsa) {
    newFilters.push(searchFilters.EXCLUDE_GSA);
  }
  if (!showOnlyCoop) {
    newFilters.push(searchFilters.INCLUDE_NON_COOP);
  }
  if (!excludeUnusable) {
    newFilters.push(searchFilters.INCLUDE_UNUSABLE);
  }

  return () => {
    setExpirationFilter(
      showExpired
        ? ExpirationStatuses.ACTIVE_AND_EXPIRED
        : ExpirationStatuses.ALL_ACTIVE
    );
    setDiversityCertificationFilter(
      showDiversityCertifications === undefined || showDiversityCertifications
    );
    setSingleAwardOnlyFilter(
      showOnlySingleAward === undefined || showOnlySingleAward
    );
    setApprovedSourcesOnlyFilter(!!showOnlyApprovedSources);
    setContractSourceFilter([]);
    setSupplierLocationFilter(SupplierLocationFilterOptions.ALL_SUPPLIERS);
    setContractDocsFilter(ContractDocumentsFilterOptions.ALL_CONTRACTS);
    return newFilters;
  };
}
